import type { Component, PropType, VNode } from "vue";
import { ArrowDown, CircleClose } from "@element-plus/icons-vue";
import type { ISelectProps } from "element-plus";
import type { GSelectOptionStates } from "./type";

// import { SelectProps } from "element-plus/es/components/select/src/select";
export const SelectProps = {
	/**
	 * @description the name attribute of select input
	 */
	name: String,
	/**
	 * @description native input id
	 */
	id: String,
	/**
	 * @description binding value
	 */
	modelValue: {
		type: [Array, String, Number, Boolean, Object],
		default: undefined,
	},
	/**
	 * @description the autocomplete attribute of select input
	 */
	autocomplete: {
		type: String,
		default: "off",
	},
	/**
	 * @description for non-filterable Select, this prop decides if the option menu pops up when the input is focused
	 */
	automaticDropdown: Boolean,
	/**
	 * @description size of Input
	 */
	size: {
		type: String as PropType<ISelectProps["size"]>,
	},
	/**
	 * @description tooltip theme, built-in theme: `dark` / `light`
	 */
	effect: {
		type: String as PropType<ISelectProps["effect"]>,
		default: "light",
	},
	/**
	 * @description whether Select is disabled
	 */
	disabled: Boolean,
	/**
	 * @description whether select can be cleared
	 */
	clearable: Boolean,
	/**
	 * @description whether Select is filterable
	 */
	filterable: Boolean,
	/**
	 * @description whether creating new items is allowed. To use this, `filterable` must be true
	 */
	allowCreate: Boolean,
	/**
	 * @description whether Select is loading data from server
	 */
	loading: Boolean,
	/**
	 * @description custom class name for Select's dropdown
	 */
	popperClass: {
		type: String,
		default: "",
	},
	/**
	 * @description [popper.js](https://popper.js.org/docs/v2/) parameters
	 */
	popperOptions: {
		type: Object as PropType<ISelectProps["popperOptions"]>,
		default: {},
	},
	/**
	 * @description whether options are loaded from server
	 */
	remote: Boolean,
	/**
	 * @description displayed text while loading data from server, default is 'Loading'
	 */
	loadingText: String,
	/**
	 * @description displayed text when no data matches the filtering query, you can also use slot `empty`, default is 'No matching data'
	 */
	noMatchText: String,
	/**
	 * @description displayed text when there is no options, you can also use slot `empty`, default is 'No data'
	 */
	noDataText: String,
	/**
	 * @description custom remote search method
	 */
	remoteMethod: Function,
	/**
	 * @description custom filter method
	 */
	filterMethod: Function,
	/**
	 * @description whether multiple-select is activated
	 */
	multiple: Boolean,
	/**
	 * @description maximum number of options user can select when `multiple` is `true`. No limit when set to 0
	 */
	multipleLimit: {
		type: Number,
		default: 0,
	},
	/**
	 * @description placeholder, default is 'Select'
	 */
	placeholder: {
		type: String,
	},
	/**
	 * @description select first matching option on enter key. Use with `filterable` or `remote`
	 */
	defaultFirstOption: Boolean,
	/**
	 * @description when `multiple` and `filter` is true, whether to reserve current keyword after selecting an option
	 */
	reserveKeyword: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description unique identity key name for value, required when value is an object
	 */
	valueKey: {
		type: String,
		default: "value",
	},
	/**
	 * @description whether to collapse tags to a text when multiple selecting
	 */
	collapseTags: Boolean,
	/**
	 * @description whether show all selected tags when mouse hover text of collapse-tags. To use this, `collapse-tags` must be true
	 */
	collapseTagsTooltip: Boolean,
	/**
	 * @description the max tags number to be shown. To use this, `collapse-tags` must be true
	 */
	maxCollapseTags: {
		type: Number,
		default: 1,
	},
	/**
	 * @description whether select dropdown is teleported to the body
	 */
	teleported: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description when select dropdown is inactive and `persistent` is `false`, select dropdown will be destroyed
	 */
	persistent: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description custom clear icon component
	 */
	clearIcon: {
		type: [String, Object] as PropType<string | Component>,
		default: CircleClose,
	},
	/**
	 * @description whether the width of the dropdown is the same as the input
	 */
	fitInputWidth: Boolean,
	/**
	 * @description custom suffix icon component
	 */
	suffixIcon: {
		type: [String, Object] as PropType<string | Component>,
		default: ArrowDown,
	},
	/**
	 * @description tag type
	 */
	// eslint-disable-next-line vue/require-prop-types
	tagType: {
		type: String as PropType<ISelectProps["tagType"]>,
		default: "info",
	},
	/**
	 * @description whether to trigger form validation
	 */
	validateEvent: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description in remote search method show suffix icon
	 */
	remoteShowSuffix: Boolean,
	/**
	 * @description position of dropdown
	 */
	placement: {
		type: String,
		default: "bottom-start",
	},
	/**
	 * @description list of possible positions for dropdown
	 */
	fallbackPlacements: {
		type: Array<string>,
		default: ["bottom-start", "top-start", "right", "left"],
	},
	/**
	 * @description empty values supported by the component
	 */
	emptyValues: Array,
	/**
	 * @description return value when cleared, if you want to set `undefined`, use `() => undefined`
	 */
	valueOnClear: {
		type: [String, Number, Boolean, Function],
		default: undefined,
	},
	/**
	 * @description native `aria-label` attribute
	 */
	ariaLabel: String,
};

export const gSelectProps = {
	...SelectProps,
	/**
	 * v-model绑定值
	 */
	modelValue: [Number, String, Boolean, Object, Array] as PropType<number | string | boolean | object | any[]>,
	valueKey: {
		type: String,
		default: "value",
	},
	/**
	 * 下拉框数据
	 */
	data: {
		type: Array as PropType<ElSelectorOutput[] | any[]>,
		default: [],
	},
	/**
	 * 请求下拉框数据的api
	 */
	requestApi: {
		type: Function as PropType<(params?: any) => ApiPromise<ElSelectorOutput[] | any[]>>,
	},
	/**
	 * 懒加载远程数据，默认 true
	 * 当下拉框第一次显示的时候才会加载远程数据
	 */
	lazy: {
		type: Boolean,
		default: true,
	},
	/**
	 * v-model:label 绑定值
	 * 选项显示的值
	 */
	label: String,
	/**
	 * Label Key，默认 label
	 */
	labelKey: {
		type: String,
		default: "label",
	},
	/**
	 * disabled Key，默认 disabled
	 */
	disabledKey: {
		type: String,
		default: "disabled",
	},
	/**
	 * children Key，默认 children
	 */
	childrenKey: {
		type: String,
		default: "children",
	},
	/**
	 * 宽度
	 */
	width: {
		type: String,
		default: "100%",
	},
	/**
	 * 默认选中
	 * 不能和懒加载一起使用
	 */
	defaultSelect: Boolean,
	/**
	 * 默认参数
	 */
	initParam: {
		type: Object as PropType<anyObj>,
		default: {},
	},
	/**
	 * 更多细节，只有自定义slot的时候有用
	 */
	moreDetail: Boolean,
};

export const gSelectEmits = {
	/**
	 * v-model 回调
	 */
	"update:modelValue": (value: number | string | boolean | object | any[]): boolean => true,
	/**
	 * v-model:label 回调
	 */
	"update:label": (value: string | number): boolean => true,
	/**
	 * 选中改变事件
	 */
	change: (value: ElSelectorOutput | any): boolean => true,
	/**
	 * 下拉框出现/隐藏事件
	 */
	visibleChange: (visible: boolean): boolean => true,
	/**
	 * 选择器数据改变回调事件
	 */
	dataChangeCallBack: (data: ElSelectorOutput[] | any[]): boolean => true,
};

export const gSelectOptionProps = {
	/**
	 * 选项数据
	 */
	data: {
		type: Object as PropType<ElSelectorOutput | any>,
	},
	/**
	 * Label Key，默认 label
	 */
	labelKey: {
		type: String,
		default: "label",
	},
	/**
	 * Value Key，默认 label
	 */
	valueKey: {
		type: String,
		default: "value",
	},
	/**
	 * disabled Key，默认 disabled
	 */
	disabledKey: {
		type: String,
		default: "disabled",
	},
	/**
	 * children Key，默认 children
	 */
	childrenKey: {
		type: String,
		default: "children",
	},
	/**
	 * 更多细节，只有自定义slot的时候有用
	 */
	moreDetail: Boolean,
};

export const gSelectOptionSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GSelectOptionStates & { data: ElSelectorOutput | any }): VNode[] => [],
};
