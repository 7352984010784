import type { SlotsType, VNode } from "vue";
import { computed, defineComponent, h, inject, ref, resolveComponent, watch } from "vue";
import { CopyDocument, View as ElIconView } from "@element-plus/icons-vue";
import type { TableColumnCtx } from "element-plus";
import { ElButton, ElIcon, ElMessage, ElTableColumn, ElTag, dayjs } from "element-plus";
import { gTableColumnEmits, gTableColumnProps, tableColumnProps } from "./define";
import { GImage } from "@gejia-element-plus/components/image";
import type { GTableColumnCtx, GTableStates } from "@gejia-element-plus/components/table";
import { errorHandler, stringUtil, tableUtil, typeUtil, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GTableColumn 组件
 */
export default defineComponent({
	name: "GTableColumn",
	components: {
		ElTableColumn,
		ElTag,
		ElButton,
		GImage,
		ElIcon,
		ElIconView,
		CopyDocument,
	},
	props: gTableColumnProps,
	emits: gTableColumnEmits,
	slots: Object as SlotsType<anyObj<{ row?: any; column: GTableColumnCtx; $index: number } & Partial<GTableStates>>>,
	setup(props, { slots, emit, expose }) {
		const tableStates = inject<GTableStates>("tableStates");
		const enumMap = inject("enumMap", ref(new Map()));

		/**
		 * 渲染表格数据
		 */
		const renderCellData = (item: GTableColumnCtx, { row }: { row: any }): any => {
			let enumKey = item.prop;
			if (typeUtil.isString(item.enum)) {
				enumKey = item.enum;
			}
			const enumData = enumMap.value.get(enumKey);
			if (enumData) {
				return tableUtil.filterEnum(
					tableUtil.handleRowAccordingToProp(row, item.prop),
					enumData,
					item.fieldNames ?? { label: "label", value: "value" }
				);
			} else {
				return tableUtil.formatValue(tableUtil.handleRowAccordingToProp(row, item.prop));
			}
		};

		/**
		 * 获取 tag 类型
		 */
		const getTagType = (item: GTableColumnCtx, { row }: { row: any }): any => {
			let enumKey = item.prop;
			if (typeUtil.isString(item.enum)) {
				enumKey = item.enum;
			}
			const enumData = enumMap.value.get(enumKey);
			return tableUtil.filterEnum(
				tableUtil.handleRowAccordingToProp(row, item.prop),
				enumData,
				item.fieldNames ?? { label: "label", value: "value" },
				"tag"
			) as any;
		};

		const getWidth = (defAttr: string): string | number => {
			if (props.column?.autoWidth) {
				return computed(() => {
					const findInfo = tableStates.autoColumnWidth.find((f) => f.prop === props.column.prop);
					if (findInfo) {
						return `${findInfo.width}px`;
					}
					return "auto";
				}).value;
			}
			if (props.tableSize === "small") {
				return props.column?.smallWidth ?? props.column?.width ?? props.column?.minWidth ?? defAttr ?? "auto";
			}
			return props.column?.width ?? props.column?.minWidth ?? defAttr ?? "auto";
		};

		const autoWidthHeaderRender = (el: VNode | VNode[]): VNode | VNode[] => {
			if (props.column?.autoWidth) {
				return (
					<div class={["g-table__auto-width-column__cell-header", `__g-table__auto-width-column__cell-header__${props.column?.prop}`]}>
						{el}
					</div>
				);
			} else {
				return el;
			}
		};

		const headerRender = ({ column, $index }: { column: GTableColumnCtx; $index: number }): VNode | VNode[] => {
			if (props.column?.headerRender) {
				return autoWidthHeaderRender(<component is={props.column.headerRender} column={column} $index={$index} />);
			} else if (props.column?.headerSlot) {
				return autoWidthHeaderRender(slots[props.column.headerSlot] && slots[props.column.headerSlot]({ ...tableStates, column, $index }));
			} else {
				return autoWidthHeaderRender(<span>{column.label}</span>);
			}
		};

		const autoWidthRender = (el: VNode | VNode[]): VNode | VNode[] => {
			if (props.column?.autoWidth) {
				return <div class={["g-table__auto-width-column__cell", `__g-table__auto-width-column__cell__${props.column?.prop}`]}>{el}</div>;
			} else {
				return el;
			}
		};

		const handleImagePreview = (url: string): void => {
			emit("imagePreview", url);
		};

		const handleCopyClick = async (value): Promise<void> => {
			try {
				await stringUtil.copy(String(value));
				ElMessage({
					type: "success",
					message: "复制成功",
				});
			} catch (error) {
				ElMessage({
					type: "error",
					message: "复制失败",
				});
				errorHandler(error);
			}
		};

		const copyRender = (value): VNode | VNode[] => {
			return props.column.copy ? (
				value ? (
					<ElIcon class="g-copy-icon" title="复制" onClick={() => handleCopyClick(value)}>
						<CopyDocument />
					</ElIcon>
				) : null
			) : null;
		};

		const formatterRender = (row: any, column: GTableColumnCtx, cellValue: any, index: number): any => {
			if (column.formatter) {
				return column.formatter(row, column as unknown as TableColumnCtx<any>, cellValue, index);
			} else {
				return cellValue;
			}
		};

		const defaultRender = ({ row, column, $index }: { row: any; column: GTableColumnCtx; $index: number }): VNode | VNode[] => {
			if (props.column?.tag) {
				const renderValue = formatterRender(row, column, renderCellData(props.column, { row }), $index);
				return autoWidthRender(
					<>
						{copyRender(renderValue)}
						{renderValue ? <ElTag type={getTagType(props.column, { row })}>{renderValue}</ElTag> : null}
					</>
				);
			} else if (props.column?.type == "date" || props.column?.type == "time" || props.column?.type == "dateTime") {
				let dateFormat;
				switch (props.column?.type) {
					case "date":
						dateFormat = "YYYY-MM-DD";
						break;
					case "time":
						dateFormat = "HH:mm:ss";
						break;
					case "dateTime":
						dateFormat = "YYYY-MM-DD HH:mm:ss";
						break;
				}
				const renderValue = formatterRender(row, column, dayjs(row[props.column.prop]).format(props.column.dateFormat ?? dateFormat), $index);
				return autoWidthRender(
					row[props.column.prop] ? (
						<>
							{copyRender(renderValue)}
							{renderValue}
							{props.column.dateFix ? (
								renderValue ? (
									<>
										&nbsp;&nbsp;
										<ElTag type="info" round effect="light">
											{tableUtil.dateTimeFix(row[props.column.prop])}
										</ElTag>
									</>
								) : null
							) : null}
						</>
					) : null
				);
			} else if (props.column?.link) {
				const renderValue = formatterRender(row, column, row[props.column.prop], $index);
				return autoWidthRender(
					<>
						{copyRender(renderValue)}
						<ElButton
							link
							type="primary"
							onClick={() => {
								if (props.column.clickEmit) {
									emit("customCellClick", props.column.clickEmit, { row, $index });
								} else {
									props.column.click({ row, $index });
								}
							}}
						>
							{renderValue}
						</ElButton>
					</>
				);
			} else if (props.column?.render) {
				return autoWidthRender(<component is={props.column.render} row={row} column={column} $index={$index} />);
			} else if (props.column?.slot) {
				return autoWidthRender(slots[props.column.slot] && slots[props.column.slot]({ ...tableStates, row, column, $index }));
			} else {
				const renderValue = formatterRender(row, column, row[props.column.prop], $index);
				return autoWidthRender(
					<>
						{copyRender(renderValue)}
						{renderValue}
					</>
				);
			}
		};

		/**
		 * 这里由于监听不到 props.column 的值的变化，导致列表没有更新，所以选择手动监听
		 */
		let bindProps = useProps(props.column, tableColumnProps, ["type", "width", "minWidth", "align", "sortable", "sortOrders", "resizable"], {
			width: "auto",
			headerAlign: "left",
			align: "left",
		});

		watch(
			() => props.column,
			() => {
				bindProps = useProps(props.column, tableColumnProps, ["type", "width", "minWidth", "align", "sortable", "sortOrders", "resizable"], {
					width: "auto",
					headerAlign: "left",
					align: "left",
				});
			}
		);

		useRender(() => (
			<>
				{
					// 如果有配置多级表头的数据，则递归该组件
					props.column?._children?.length ? (
						<ElTableColumn
							{...bindProps.value}
							minWidth={getWidth("auto")}
							sortable={props.column.sortable ?? "custom"}
							sortOrders={props.column.sortOrders ?? ["descending", "ascending", null]}
							resizable={props.resizable}
						>
							{{
								header: ({ column, $index }: { column: GTableColumnCtx; $index: number }) => headerRender({ column, $index }),
								default: () =>
									props.column._children.map((col: GTableColumnCtx) =>
										h(resolveComponent("GTableColumn"), {
											column: col,
											vSlots: slots,
										})
									),
							}}
						</ElTableColumn>
					) : props.column?.type === "image" ? (
						<ElTableColumn {...bindProps.value} minWidth="50px" align="center" className="g-table__image-column" resizable={false}>
							{{
								header: ({ column, $index }: { column: GTableColumnCtx; $index: number }) => headerRender({ column, $index }),
								default: ({ row }: { row: any; column: GTableColumnCtx; $index: number }) =>
									row[props.column.prop] ? (
										props.hideImage ? (
											<ElButton
												class="g-table__image-column__button"
												type="info"
												link
												icon={ElIconView}
												onClick={() => handleImagePreview(row[props.column.prop])}
											>
												查看
											</ElButton>
										) : (
											<GImage lazy src={row[props.column.prop]} fit="cover" />
										)
									) : null,
							}}
						</ElTableColumn>
					) : (
						// 其他正常的列
						<ElTableColumn
							{...bindProps.value}
							minWidth={getWidth("auto")}
							sortable={props.column.sortable ?? "custom"}
							sortOrders={props.column.sortOrders ?? ["descending", "ascending", null]}
							resizable={props.resizable}
						>
							{{
								header: ({ column, $index }: { column: GTableColumnCtx; $index: number }) => headerRender({ column, $index }),
								default: ({ row, column, $index }: { row: any; column: GTableColumnCtx; $index: number }) =>
									defaultRender({ row, column, $index }),
							}}
						</ElTableColumn>
					)
				}
			</>
		));

		expose({});

		return {
			bindProps,
		};
	},
});
