import type { SlotsType, VNode } from "vue";
import { defineComponent, reactive, ref, watch, withModifiers } from "vue";
import { Eleme } from "@element-plus/icons-vue";
import type { ButtonInstance } from "element-plus";
import { ElButton, ElIcon, buttonProps } from "element-plus";
import type { gButtonSlots } from "./define";
import { gButtonEmits, gButtonProps } from "./define";
import type { GButtonStates } from "./type";
import { userButton } from "./userButton";
import { useOverlay } from "@gejia-element-plus/hooks";
import { useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GButton 组件
 */
export default defineComponent({
	name: "GButton",
	components: {
		ElButton,
		ElIcon,
		Eleme,
	},
	props: gButtonProps,
	emits: gButtonEmits,
	slots: Object as SlotsType<typeof gButtonSlots>,
	setup(props, { attrs, slots, emit, expose }) {
		const states: GButtonStates = reactive({
			loading: false,
		});

		const buttonRef = ref<ButtonInstance>();

		const handleClick = (event: MouseEvent): void => {
			if (props.disabledLoading) {
				// 回调点击事件
				emit("click", event);
			} else {
				states.loading = true;
				// 这里默认透明
				useOverlay.show(0);
				// 回调点击事件
				emit("click", event, () => {
					states.loading = false;
					useOverlay.hide();
				});
			}
		};

		/**
		 * 监听外部 loading 的值
		 */
		watch(
			() => props.loading,
			(newValue) => {
				if (newValue) {
					states.loading = true;
					// 这里默认透明
					useOverlay.show(0);
				} else {
					states.loading = false;
					useOverlay.hide();
				}
			},
			{
				immediate: true,
			}
		);

		const bindProps = useProps(props, buttonProps, ["loading"]);

		useRender(() => (
			<ElButton
				{...attrs}
				{...bindProps.value}
				ref={buttonRef}
				class="g-button"
				loading={states.loading}
				onClick={withModifiers((event: Event) => handleClick(event as MouseEvent), ["prevent"])}
			>
				{{
					default: () => slots.default && slots.default(states),
					...(slots.loading && { loading: (): VNode[] => slots.loading(states) }),
					...(slots.icon && { icon: (): VNode[] => slots.icon(states) }),
				}}
			</ElButton>
		));

		expose({
			states,
			...userButton(buttonRef),
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			...userButton(buttonRef),
		};
	},
});
