import { computed, defineComponent, reactive } from "vue";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { ElInput, ElPopover, ElScrollbar } from "element-plus";
import { gIconSelectorEmits, gIconSelectorProps } from "./define";
import type { GIconSelectorStates } from "./type";
import { GIcon } from "@gejia-element-plus/components/icon";
import { GejiaElementPlusIconsVue } from "@gejia-element-plus/icons-vue";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GIconSelector 组件
 */
export default defineComponent({
	name: "GIconSelector",
	components: { ElPopover, ElInput, GIcon },
	props: gIconSelectorProps,
	emits: gIconSelectorEmits,
	setup(props, { attrs, slots, emit, expose }) {
		const states: GIconSelectorStates = reactive({
			value: undefined,
			searchValue: undefined,
			iconType: "ele",
			popoverVisible: false,
			iconNames: Object.keys(ElementPlusIconsVue),
			renderIconNames: computed(() => {
				if (!states.searchValue) return states.iconNames;

				return states.iconNames.filter((f) => f.toLowerCase().indexOf(states.searchValue) !== -1);
			}),
		});

		const handleTabClick = (iconType: "ele" | "gejiaEle" | "local"): void => {
			states.iconType = iconType;
			states.iconNames = [];
			switch (iconType) {
				case "ele":
					states.iconNames = Object.keys(ElementPlusIconsVue).map((m) => `el-icon-${m}`);
					break;
				case "gejiaEle":
					states.iconNames = GejiaElementPlusIconsVue.map((m) => `g-icon-${m.name}`);
					break;
				case "local":
					states.iconNames = props.customIcons;
					break;
			}
		};

		const handleIconClick = (value: string): void => {
			states.popoverVisible = false;
			states.value = value;
			states.searchValue = "";
			emit("update:modelValue", value);
			emit("change", value);
		};

		const handleRefresh = (): void => {
			states.value = null;
			states.searchValue = null;
			emit("update:modelValue", null);
			emit("change", null);
		};

		useRender(() => (
			<ElPopover
				popperClass="g-icon-selector-popover"
				visible={states.popoverVisible}
				width="auto"
				trigger="click"
				showArrow={false}
				showAfter={0}
				hideAfter={0}
			>
				{{
					reference: () => (
						<ElInput vModel={states.searchValue} placeholder="搜索图标">
							{{
								prepend: () => <GIcon name={states.value ?? "el-icon-ChromeFilled"} />,
								append: () => <GIcon name={states.value ?? "el-icon-RefreshRight"} onClick={handleRefresh} />,
							}}
						</ElInput>
					),
					default: () => (
						<div class="g-icon-selector-popover__box">
							<div class="g-icon-selector-popover__box-header">
								<div class="g-icon-selector-popover__box-header__title">请选择图标</div>
								<div class="g-icon-selector-popover__box-header__tab">
									<span
										class={[states.iconType === "ele" ? "g-icon-selector-popover__box-header__tab-is-active" : ""]}
										title="Element Plus 图标"
										onClick={() => handleTabClick("ele")}
									>
										ele
									</span>
									<span
										class={[states.iconType === "gejiaEle" ? "g-icon-selector-popover__box-header__tab-is-active" : ""]}
										title="Gejia Element Plus 图标"
										onClick={() => handleTabClick("gejiaEle")}
									>
										gejiaEle
									</span>
									<span
										class={[states.iconType === "local" ? "g-icon-selector-popover__box-header__tab-is-active" : ""]}
										title="本地图标"
										onClick={() => handleTabClick("local")}
									>
										local
									</span>
								</div>
							</div>
							<div class="g-icon-selector-popover__box-body">
								<ElScrollbar>
									{states.renderIconNames.map((m) => (
										<div class="g-icon-selector-popover__box-body__item" title={m} onClick={() => handleIconClick(m)}>
											<GIcon name={m} />
										</div>
									))}
								</ElScrollbar>
							</div>
						</div>
					),
				}}
			</ElPopover>
		));

		expose({
			states,
		});

		return {
			attrs,
			props,
			slots,
			states,
		};
	},
});
