import type { PropType } from "vue";

export const gIconProps = {
	/**
	 * 名称
	 * @description el-icon- 使用 El-icon 的图标；g-icon 使用 Gejia 图标组件库；
	 * @requires 必填
	 */
	name: {
		type: String,
		required: true,
	},
	/**
	 * 大小
	 */
	size: [Number, String] as PropType<number | string>,
	/**
	 * 颜色
	 */
	color: String,
};
