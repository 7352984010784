/**
 * 类型工具类
 */
export const typeUtil = {
	/**
	 * 深度克隆对象或数组
	 */
	cloneDeep<T>(value: T): T {
		if (Array.isArray(value)) {
			return value.map((item) => typeUtil.cloneDeep(item)) as unknown as T;
		}
		if (typeUtil.isObject(value)) {
			const result: any = {};
			for (const key in value) {
				if (Object.prototype.hasOwnProperty.call(value, key)) {
					result[key] = typeUtil.cloneDeep(value[key]);
				}
			}
			return result as T;
		}
		return value;
	},
	/**
	 * 检查是否为空
	 */
	isEmpty(value: any): boolean {
		if (typeUtil.isObject(value)) return Object.keys(value).length === 0;
		if (Array.isArray(value)) return value.length === 0;
		return !value;
	},
	/**
	 * 检查两个值是否相等
	 */
	isEqual(value: any, other: any): boolean {
		if (value === other) return true;
		if (typeUtil.isObject(value) && typeUtil.isObject(other)) {
			const valueKeys = Object.keys(value);
			const otherKeys = Object.keys(other);
			if (valueKeys.length !== otherKeys.length) return false;
			for (const key of valueKeys) {
				if (!typeUtil.isEqual(value[key], other[key])) return false;
			}
			return true;
		}
		if (Array.isArray(value) && Array.isArray(other)) {
			if (value.length !== other.length) return false;
			for (let i = 0; i < value.length; i++) {
				if (!typeUtil.isEqual(value[i], other[i])) return false;
			}
			return true;
		}
		return false;
	},
	/**
	 * 检查是否为布尔值
	 */
	isBoolean(value: any): value is boolean {
		return typeof value === "boolean";
	},
	/**
	 * 检查是否为错误对象
	 */
	isError(value: any): value is Error {
		return value instanceof Error;
	},
	/**
	 * 检查是否为函数
	 */
	// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
	isFunction(value: any): value is Function {
		return typeof value === "function";
	},
	/**
	 * 检查是否为数字
	 */
	isNumber(value: any): value is number {
		return typeof value === "number" && !isNaN(value);
	},
	/**
	 * 检查是否为对象
	 */
	isObject(value: any): value is object {
		return value !== null && typeof value === "object" && !Array.isArray(value);
	},
	/**
	 * 检查是否为字符串
	 */
	isString(value: any): value is string {
		return typeof value === "string";
	},
	/**
	 * 从对象中省略给定路径的属性
	 */
	omit<T extends object, K extends keyof T>(object: T, paths: K[]): Omit<T, K> {
		if (!typeUtil.isObject(object)) return object;
		const result = { ...object } as T;
		paths.forEach((path) => {
			delete result[path];
		});
		return result;
	},
	/**
	 * 从对象中挑选给定路径的属性
	 */
	pick<T extends object, K extends keyof T>(object: T, paths: K[]): Pick<T, K> {
		if (!typeUtil.isObject(object)) return {} as Pick<T, K>;
		const result = {} as Pick<T, K>;
		paths.forEach((path) => {
			if (path in object) {
				result[path] = object[path];
			}
		});
		return result;
	},
	/**
	 * 数组去重
	 */
	uniq<T>(value: T[]): T[] {
		return Array.isArray(value) ? Array.from(new Set(value)) : value;
	},
	/**
	 * 是否存在重复值
	 */
	hasDuplicateProperty<T>(arr: T[], prop: keyof T): boolean {
		const values = arr.map((obj) => obj[prop]);
		const uniqueValues = new Set(values);
		return values.length !== uniqueValues.size;
	},
	/**
	 * 是否存在非重复值
	 */
	hasDifferentProperty<T>(arr: T[], prop: keyof T): boolean {
		const valueSet = new Set<any>();
		for (const obj of arr) {
			valueSet.add(obj[prop]);
			if (valueSet.size > 1) {
				return true;
			}
		}
		return false;
	},
};
