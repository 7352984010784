import type { App } from "vue";
import { ElMessageBox, ElNotification } from "element-plus";
import type { NavigationFailure, RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw, Router } from "vue-router";
import { NavigationFailureType, isNavigationFailure } from "vue-router";
import { pinia, useGejiaApp, useNavTabs } from "@gejia-element-plus/stores";
import { typeUtil } from "@gejia-element-plus/utils";

/**
 * 静态类
 */
class GejiaRouter {
	/**
	 * 路由
	 */
	public static router: Router = undefined;
}

/**
 * 加载路由
 */
export const loadRouter = (app: App, router: Router): void => {
	app.use(router);

	// 存储 router
	GejiaRouter.router = router;

	// 挂载到window中
	window.router = router;
};

export const routerUtil = {
	/**
	 * 路由跳转
	 * @param to 导航位置，同 router.push
	 */
	async routePush(to: RouteLocationRaw): Promise<void> {
		if (!GejiaRouter.router) {
			console.error("[gejia-VueRouter]", new Error("router undefined."));
		}
		const gejiaAppStore = useGejiaApp(pinia);
		const navTabsStore = useNavTabs(pinia);
		let findIdx = -1;
		if (typeUtil.isString(to)) {
			findIdx = navTabsStore.states.navBarTabs.findIndex((f) => f.path === to);
		} else {
			findIdx = navTabsStore.states.navBarTabs.findIndex((f) => f.path === to.path);
		}
		// 判断页面警告数量，为0则不警告
		if (
			findIdx === -1 &&
			gejiaAppStore.states.appConfig.warnPageNum > 0 &&
			navTabsStore.states.navBarTabs.length >= gejiaAppStore.states.appConfig.warnPageNum
		) {
			await ElMessageBox.confirm("您当前打开太多页签，如果加载数据过大，继续打开更多的页签可能会造成程序运行缓慢，无法流畅操作！是否继续？", {
				confirmButtonText: "继续",
				cancelButtonText: "取消",
				type: "warning",
			}).then(async () => {
				await GejiaRouter.router.push(to);
			});
		} else {
			await GejiaRouter.router.push(to);
		}
	},
	/**
	 * 路由跳转，带错误检查
	 * @param to 导航位置，同 router.push
	 */
	async routePushSafe(to: RouteLocationRaw): Promise<void> {
		try {
			if (!GejiaRouter.router) {
				throw new Error("router undefined.");
			}
			const gejiaAppStore = useGejiaApp(pinia);
			const navTabsStore = useNavTabs(pinia);
			let findIdx = -1;
			if (typeUtil.isString(to)) {
				findIdx = navTabsStore.states.navBarTabs.findIndex((f) => f.path === to);
			} else {
				findIdx = navTabsStore.states.navBarTabs.findIndex((f) => f.path === to.path);
			}
			let failure: void | NavigationFailure;
			// 判断页面警告数量，为0则不警告
			if (
				findIdx === -1 &&
				gejiaAppStore.states.appConfig.warnPageNum > 0 &&
				navTabsStore.states.navBarTabs.length >= gejiaAppStore.states.appConfig.warnPageNum
			) {
				await ElMessageBox.confirm(
					"您当前打开太多页签，如果加载数据过大，继续打开更多的页签可能会造成程序运行缓慢，无法流畅操作！是否继续？",
					{
						confirmButtonText: "继续",
						cancelButtonText: "取消",
						type: "warning",
					}
				).then(async () => {
					failure = await GejiaRouter.router.push(to);
				});
			} else {
				failure = await GejiaRouter.router.push(to);
			}
			if (failure) {
				if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
					ElNotification({
						message: "导航失败，导航守卫拦截！",
						type: "error",
					});
				} else if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
					ElNotification({
						message: "导航失败，已在导航目标位置！",
						type: "warning",
					});
				}
			}
		} catch (error) {
			ElNotification({
				message: "导航失败，路由无效！",
				type: "error",
			});
			console.error("[gejia-VueRouter]", error);
		}
	},
	/**
	 * route 部分属性，解决警告
	 */
	pickByRoute(route: Partial<RouteLocationNormalized>): Partial<RouteLocationNormalized> {
		return typeUtil.pick(route, ["name", "path", "query", "fullPath", "meta", "params"]);
	},
	/**
	 * 扁平化路由
	 */
	flattenRoutes(routes: RouteRecordRaw[]): RouteRecordRaw[] {
		const resRoutes: RouteRecordRaw[] = [];

		routes.forEach((item) => {
			if (item?.children?.length > 1) {
				const newItem = { ...item };
				delete newItem?.children;
				resRoutes.push(newItem);
				resRoutes.push(...this.flattenRoutes(item?.children));
			} else {
				resRoutes.push(item);
			}
		});

		return resRoutes;
	},
};
