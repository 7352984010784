import type { ExtractPropTypes } from "vue";
import type { SelectProps, gSelectEmits, gSelectOptionProps, gSelectProps } from "./src/define";
import Select from "./src/select";
import SelectOption from "./src/selectOption";
import { withInstall, withNoopInstall } from "@gejia-element-plus/utils";

export const GSelect = withInstall(Select, {
	SelectOption,
});
export default GSelect;

export const GSelectOption = withNoopInstall(SelectOption);

export * from "./src/define";
export * from "./src/type";

export type GSelectInstance = InstanceType<typeof Select>;

export type ISelectProps = ExtractPropTypes<typeof SelectProps>;
export type GSelectProps = ExtractPropTypes<typeof gSelectProps>;

export type GSelectEmits = typeof gSelectEmits;

export type GSelectOptionInstance = InstanceType<typeof SelectOption>;

export type GSelectOptionProps = ExtractPropTypes<typeof gSelectOptionProps>;
