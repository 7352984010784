import type { App } from "vue";
import { inject, provide } from "vue";
import { installElementPlus } from "./element-plus";
import { installGejiaElementPlus } from "./gejia-element-plus";
import { gejiaElementPlusVersion } from "./version";
import { CacheKey, INSTALLED_KEY, RegExps } from "@gejia-element-plus/constants";
import { Local, emitter, errorHandler, stringUtil } from "@gejia-element-plus/utils";

export const makeIdentity = (deviceID?: string): string => {
	if (deviceID && RegExps.UUID.test(deviceID)) {
		Local.set(CacheKey.DEVICE_ID, deviceID);
		window.uuid = deviceID;
		return deviceID;
	}
	// 生成浏览器唯一 UUID
	deviceID = Local.get(CacheKey.DEVICE_ID);
	if (deviceID && RegExps.UUID.test(deviceID) && RegExps.UUID.test(deviceID)) {
		window.uuid = deviceID;
		return deviceID;
	}
	deviceID = stringUtil.generateUUID();
	Local.set(CacheKey.DEVICE_ID, deviceID);
	window.uuid = deviceID;
	return deviceID;
};

export const makeInstaller = (): {
	version: string;
	install: (app: App) => void;
} => {
	const install = (app: App): void => {
		if (app[INSTALLED_KEY]) return;

		app[INSTALLED_KEY] = true;

		// 全局异常捕获
		app.config.errorHandler = errorHandler;

		makeIdentity();

		window.gejia = {
			vue: {
				provide,
				inject,
			},
			mitt: emitter,
		};

		installElementPlus(app);
		installGejiaElementPlus(app);
	};

	return {
		version: gejiaElementPlusVersion,
		install,
	};
};
