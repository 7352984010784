import { makeInstaller } from "@gejia-element-plus/build";

export * from "./export";

export * from "@gejia-element-plus/components";
export * from "@gejia-element-plus/constants";
export * from "@gejia-element-plus/directives";
export * from "@gejia-element-plus/hooks";
export * from "@gejia-element-plus/layouts";
export * from "@gejia-element-plus/stores";
export * from "@gejia-element-plus/utils";

const installer = makeInstaller();

export const install = installer.install;
export const version = installer.version;
export default installer;
