import type { ExtractPropTypes } from "vue";
import type { gImageProps } from "./src/define";
import Image from "./src/image";
import { withInstall } from "@gejia-element-plus/utils";

export const GImage = withInstall(Image);
export default GImage;

export * from "./src/define";
export * from "./src/type";

export type GImageInstance = InstanceType<typeof Image>;

export type GImageProps = ExtractPropTypes<typeof gImageProps>;
