import type { Ref } from "vue";
import type { GTableColumnCtx, GTableEnumColumnCtx, GTableEnumColumnType } from "@gejia-element-plus/components/table";
import { pinia, useGejiaApp } from "@gejia-element-plus/stores";
import { typeUtil } from "@gejia-element-plus/utils";

export const tableUtil = {
	/**
	 * @description 处理无数据情况
	 * @param {String} callValue 需要处理的值
	 */
	formatValue(callValue: any): any {
		// 如果当前值为数组,使用 / 拼接（根据需求自定义）
		// if (Array.isArray(callValue)) return callValue.length ? callValue.join(" / ") : "--";
		if (Array.isArray(callValue)) return callValue.length ? callValue.join(` , `) : null;
		// return callValue ?? "--";
		return callValue;
	},
	/**
	 * @description 处理 prop 为多级嵌套的情况(列如: prop:user.name)
	 * @param {Object} row 当前行数据
	 * @param {String} prop 当前 prop
	 */
	handleRowAccordingToProp(row: any, prop: string): any {
		// if (!prop.includes(".")) return row[prop] ?? "--";
		if (!prop.includes(".")) return row[prop] ?? null;
		// prop.split(".").forEach((item) => (row = row[item] ?? "--"));
		prop.split(".").forEach((item) => (row = row[item]));
		return row;
	},
	/**
	 * @description 处理 prop，当 prop 为多级嵌套时 ==> 返回最后一级 prop
	 * @param {String} prop 当前 prop
	 */
	handleProp(prop: string): string {
		const propArr = prop.split(".");
		if (propArr.length == 1) return prop;
		return propArr[propArr.length - 1];
	},
	/**
	 * @description 根据枚举列表查询当需要的数据（如果指定了 label 和 value 的 key值，会自动识别格式化）
	 * @param {String} callValue 当前单元格值
	 * @param {Array} enumData 字典列表
	 * @param {Array} fieldNames 指定 label && value 的 key 值
	 * @param {String} type 过滤类型（目前只有 tag）
	 */
	filterEnum(callValue: any, enumData: GTableEnumColumnCtx[], fieldNames?: { label: string; value: string }, type?: "tag"): string {
		const value = fieldNames?.value ?? "value";
		const label = fieldNames?.label ?? "label";
		let filterData: anyObj = {};
		if (Array.isArray(enumData)) {
			filterData = enumData.find((item: any) => item[value] == callValue);
		}
		if (type == "tag") {
			return filterData?.type ?? "primary";
		}
		// return filterData ? filterData[label] : "--";
		return filterData ? filterData[label] : null;
	},
	/**
	 * 时间处理翻译
	 */
	dateTimeFix(date: string | Date | null | undefined): string {
		if (date !== null && date !== undefined && date) {
			if (typeof date === "string") {
				date = new Date(date);
			}

			// 获取时间戳
			let timestamp = date.getTime();
			if (timestamp.toString().length < 13) {
				const arrTimestamp = timestamp.toString().split("");
				for (let start = 0; start < 13; start++) {
					if (!arrTimestamp[start]) {
						arrTimestamp[start] = "0";
					}
				}
				timestamp = parseInt(arrTimestamp.join(""));
			}
			const minute = 1000 * 60;
			const hour = minute * 60;
			const day = hour * 24;
			const month = day * 30;
			// 获取当前时间
			const curTime = new Date().getTime();
			// 比较
			const diffValue = curTime - timestamp;

			// 计算差异时间的量级
			const monthC = diffValue / month;
			const weekC = diffValue / (7 * day);
			const dayC = diffValue / day;
			const hourC = diffValue / hour;
			const minC = diffValue / minute;

			// 如果本地时间反而小于变量时间
			if (diffValue < 0) {
				const monthC1 = Math.abs(monthC);
				const weekC1 = Math.abs(weekC);
				const dayC1 = Math.abs(dayC);
				const hourC1 = Math.abs(hourC);
				const minC1 = Math.abs(minC);

				if (monthC1 > 12) {
					// 超过1年，直接显示 几 年前
					return `${parseInt(`${monthC1 / 12}`)}年后`;
				} else if (monthC1 >= 6) {
					return "半年后";
				} else if (monthC1 >= 1) {
					return `${parseInt(`${monthC1}`)}月后`;
				} else if (weekC1 > 2) {
					return "半月后";
				} else if (weekC1 >= 1) {
					return `${parseInt(`${weekC1}`)}周后`;
				} else if (dayC1 >= 1) {
					return `${parseInt(`${dayC1}`)}天后`;
				} else if (hourC1 >= 1) {
					return `${parseInt(`${hourC1}`)}小时后`;
				} else if (minC1 >= 1) {
					return `${parseInt(`${minC1}`)}分钟后`;
				}
				return "刚刚";
				// return "不久前";
			}

			// 使用
			if (monthC > 12) {
				// 超过1年，直接显示 几 年前
				return `${parseInt(`${monthC / 12}`)}年前`;
			} else if (monthC >= 6) {
				return "半年前";
			} else if (monthC >= 1) {
				return `${parseInt(`${monthC}`)}月前`;
			} else if (weekC > 2) {
				return "半月前";
			} else if (weekC >= 1) {
				return `${parseInt(`${weekC}`)}周前`;
			} else if (dayC >= 1) {
				return `${parseInt(`${dayC}`)}天前`;
			} else if (hourC >= 1) {
				return `${parseInt(`${hourC}`)}小时前`;
			} else if (minC >= 1) {
				return `${parseInt(`${minC}`)}分钟前`;
			}
			return "刚刚";
		} else {
			return "";
		}
	},
	/**
	 * 数组动态排序
	 */
	arrayDynamicSort(sortList: BaseSortInput[]): (a: any, b: any) => number {
		return function (a: any, b: any) {
			if (sortList && sortList.length > 0) {
				for (const condition of sortList) {
					const property = condition.enField;
					const order = condition.mode;

					const aValue = a[property];
					const bValue = b[property];

					if (typeof aValue === "string" && typeof bValue === "string") {
						if (order === "ascending") {
							const comparison = aValue.localeCompare(bValue, "zh-CN");
							if (comparison !== 0) {
								return comparison;
							}
						} else if (order === "descending") {
							const comparison = bValue.localeCompare(aValue, "zh-CN");
							if (comparison !== 0) {
								return comparison;
							}
						}
					} else {
						if (order === "ascending") {
							if (aValue < bValue) return -1;
							if (aValue > bValue) return 1;
						} else if (order === "descending") {
							if (aValue > bValue) return -1;
							if (aValue < bValue) return 1;
						}
					}
				}
			}

			return 0;
		};
	},
	/**
	 * 设置枚举
	 */
	setEnumMap(columnEnum: GTableEnumColumnType, prop: string, enumMap: Ref<Map<string, GTableEnumColumnCtx[]>>): void {
		if (!columnEnum) return;
		if (typeUtil.isFunction(columnEnum)) {
			columnEnum().then((res) => {
				enumMap.value.set(prop, res.data);
			});
		} else if (typeUtil.isString(columnEnum)) {
			const gejiaAppStore = useGejiaApp(pinia);
			enumMap.value.set(prop, gejiaAppStore.getDictionary(prop));
		} else if (Array.isArray(columnEnum)) {
			enumMap.value.set(prop, columnEnum);
		} else {
			columnEnum?.requestApi(columnEnum?.params).then((res) => {
				enumMap.value.set(prop, res.data);
			});
		}
	},
	/**
	 * 扁平化 columns
	 */
	flatColumns(columns: GTableColumnCtx[], enumMap?: Ref<Map<string, GTableEnumColumnCtx[]>>): GTableColumnCtx[] {
		const flatArr: GTableColumnCtx[] = [];
		columns.forEach((col) => {
			if (col._children?.length) {
				flatArr.push(...this.flatColumns(col._children));
			}

			flatArr.push(col);

			// 给每一项 column 添加 show && filterEnum 默认属性
			col.show = col.show ?? true;
			col.filterEnum = col.filterEnum ?? col.tag ?? false;

			let enumKey = col.prop ?? col.search?.key;

			if (col.enum && typeUtil.isString(col.enum)) {
				enumKey = col.enum;
			}

			// 设置 enumMap
			this.setEnumMap(col.enum, enumKey, enumMap);
		});
		return flatArr.filter((item) => !item._children?.length);
	},
	getDefaultTime(): Date[] {
		const end = new Date();
		const start = new Date();
		start.setMonth(start.getMonth() - 1);
		start.setHours(0, 0, 0);
		end.setHours(23, 59, 59);
		return [start, end];
	},
	getSimpleTime(): Date {
		const start = new Date();
		start.setHours(0, 0, 0);
		return start;
	},
	getSimpleShortcuts(): {
		text: string;
		value: () => Date;
	}[] {
		return [
			{
				text: "今天",
				value: (): Date => {
					const date = new Date();
					date.setHours(0, 0, 0);
					return date;
				},
			},
			{
				text: "昨天",
				value: (): Date => {
					const date = new Date();
					date.setDate(date.getDate() - 1);
					date.setHours(0, 0, 0);
					return date;
				},
			},
			{
				text: "一周前",
				value: (): Date => {
					const date = new Date();
					date.setDate(date.getDate() - 7);
					date.setHours(0, 0, 0);
					return date;
				},
			},
			{
				text: "一月前",
				value: (): Date => {
					const date = new Date();
					date.setMonth(date.getMonth() - 1);
					date.setHours(0, 0, 0);
					return date;
				},
			},
			{
				text: "一年前",
				value: (): Date => {
					const date = new Date();
					date.setFullYear(date.getFullYear() - 1);
					date.setHours(0, 0, 0);
					return date;
				},
			},
		];
	},
	getShortcuts(): {
		text: string;
		value: () => Date[];
	}[] {
		return [
			{
				text: "近1天",
				value: (): Date[] => {
					const end = new Date();
					const start = new Date();
					start.setDate(start.getDate() - 1);
					start.setHours(0, 0, 0);
					end.setHours(23, 59, 59);
					return [start, end];
				},
			},
			{
				text: "近3天",
				value: (): Date[] => {
					const end = new Date();
					const start = new Date();
					start.setDate(start.getDate() - 3);
					start.setHours(0, 0, 0);
					end.setHours(23, 59, 59);
					return [start, end];
				},
			},
			{
				text: "近1周",
				value: (): Date[] => {
					const end = new Date();
					const start = new Date();
					start.setDate(start.getDate() - 7);
					start.setHours(0, 0, 0);
					end.setHours(23, 59, 59);
					return [start, end];
				},
			},
			{
				text: "近1月",
				value: (): Date[] => {
					const end = new Date();
					const start = new Date();
					start.setMonth(start.getMonth() - 1);
					start.setHours(0, 0, 0);
					end.setHours(23, 59, 59);
					return [start, end];
				},
			},
			{
				text: "近3月",
				value: (): Date[] => {
					const end = new Date();
					const start = new Date();
					start.setMonth(start.getMonth() - 3);
					start.setHours(0, 0, 0);
					end.setHours(23, 59, 59);
					return [start, end];
				},
			},
			{
				text: "近6月",
				value: (): Date[] => {
					const end = new Date();
					const start = new Date();
					start.setMonth(start.getMonth() - 6);
					start.setHours(0, 0, 0);
					end.setHours(23, 59, 59);
					return [start, end];
				},
			},
			{
				text: "近1年",
				value: (): Date[] => {
					const end = new Date();
					const start = new Date();
					start.setFullYear(start.getFullYear() - 1);
					start.setHours(0, 0, 0);
					end.setHours(23, 59, 59);
					return [start, end];
				},
			},
			{
				text: "近2年",
				value: (): Date[] => {
					const end = new Date();
					const start = new Date();
					start.setFullYear(start.getFullYear() - 2);
					start.setHours(0, 0, 0);
					end.setHours(23, 59, 59);
					return [start, end];
				},
			},
			{
				text: "近3年",
				value: (): Date[] => {
					const end = new Date();
					const start = new Date();
					start.setFullYear(start.getFullYear() - 3);
					start.setHours(0, 0, 0);
					end.setHours(23, 59, 59);
					return [start, end];
				},
			},
		];
	},
	getDisabledDate(time: Date): boolean {
		return time.getTime() > Date.now();
	},
};
