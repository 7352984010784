import { typeUtil } from "@gejia-element-plus/utils";

/**
 * 定义类型数据
 * @description 传入什么就返回什么，用来定义reactive或其他对象的响应式数据
 */
const withDefineType = <T>(data: T): T => {
	return data;
};

/**
 * 尝试转为 Number 类型
 * @param value
 * @returns
 */
const withTryNumber = <T>(value: T): number | T => {
	if (typeUtil.isString(value)) {
		if (/^[0-9]+$/.test(value)) {
			return Number(value);
		}
	}

	return value;
};

export { withDefineType, withTryNumber };
