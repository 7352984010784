import type { AxiosResponse } from "axios";

/**
 * 下载工具类
 */
export const downloadUtil = {
	/**
	 * 下载文件
	 */
	downloadFile(res: AxiosResponse): void {
		const blob = new Blob([res.data], { type: "application/octet-stream;charset=UTF-8" });
		const contentDisposition = res.headers["content-disposition"];
		const pat = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
		const result = pat.exec(contentDisposition);
		const filename = result[1];
		const downloadElement = document.createElement("a");
		const href = window.URL.createObjectURL(blob); // 创建下载的链接
		const reg = /^["](.*)["]$/g;
		downloadElement.style.display = "none";
		downloadElement.href = href;
		downloadElement.download = decodeURI(filename.replace(reg, "$1")); // 下载后文件名
		document.body.appendChild(downloadElement);
		// 点击下载
		downloadElement.click();
		// 下载完成移除元素
		document.body.removeChild(downloadElement);
		window.URL.revokeObjectURL(href);
	},
};
