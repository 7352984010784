import { computed, defineComponent, inject, reactive, ref, watch } from "vue";
import { Back } from "@element-plus/icons-vue";
import type { PopoverInstance } from "element-plus";
import { ElButton, ElInput, ElMessage, ElPopover, formContextKey, formItemContextKey, inputProps } from "element-plus";
import { CarNumberArea, CarNumberDigit, CarNumberLetter } from "./common";
import { gCarNumberEmits, gCarNumberProps } from "./define";
import { type GCarNumberStates } from "./type";
import { RegExps } from "@gejia-element-plus/constants";
import { typeUtil, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GCarNumber 组件
 */
export default defineComponent({
	name: "GCarNumber",
	components: {
		ElInput,
		ElPopover,
		ElButton,
		Back,
	},
	props: gCarNumberProps,
	emits: gCarNumberEmits,
	setup(props, { attrs, emit, expose }) {
		const states: GCarNumberStates = reactive({
			value: undefined,
			switchLetter: computed(() => {
				if (states.value?.length >= 1) {
					return true;
				}
				return false;
			}),
			disabledButton: computed(() => {
				if (states.value?.length >= 8) {
					return true;
				}
				return false;
			}),
		});

		const popoverRef = ref<PopoverInstance>();
		// 获取 el-form 组件上下文
		const formContext = inject(formContextKey, undefined);
		// 获取 el-form-item 组件上下文
		const formItemContext = inject(formItemContextKey, undefined);

		const handleInputFormatter = (value: string): string => {
			if (value.length === 2) {
				return `${value} ● `;
			} else if (value.length > 2) {
				return `${value.slice(0, 2)} ● ${value.slice(2)}`;
			} else {
				return value;
			}
		};

		const handleSelectCarNumber = (value: string): void => {
			states.value ??= "";
			states.value += value;
		};

		const handleBackClick = (): void => {
			if (states.value?.length === 0) return;
			states.value = states.value.substring(0, states.value.length - 1);
		};

		const handleConfirmClick = (): void => {
			let success = false;
			if (states.value.length === 7) {
				success = RegExps.CarNumber.test(states.value);
			} else if (states.value.length === 8) {
				success = RegExps.NewEnergyCarNumber.test(states.value);
			}
			if (success) {
				emit("update:modelValue", states.value);
				emit("change", states.value);
				// 调用 el-form 内部的校验方法（可自动校验）
				formItemContext?.prop && formContext?.validateField([formItemContext.prop as string]);
			} else {
				if (formItemContext?.prop && formContext) {
					emit("update:modelValue", states.value);
					emit("change", states.value);
					// 调用 el-form 内部的校验方法（可自动校验）
					formContext.validateField([formItemContext.prop as string]);
				} else {
					ElMessage.error("车牌号格式不正确");
				}
			}
			popoverRef.value?.hide();
		};

		const handleClearClick = (): void => {
			states.value = null;
			emit("update:modelValue", null);
			emit("change", null);
			// 调用 el-form 内部的校验方法（可自动校验）
			formItemContext?.prop && formContext?.validateField([formItemContext.prop as string]);
		};

		const handlePopoverHide = (): void => {
			if (!typeUtil.isEqual(props.modelValue, states.value)) {
				states.value = props.modelValue;
			}
		};

		watch(
			() => props.modelValue,
			(newValue) => {
				states.value = newValue;
			}
		);

		const bindProps = useProps(props, inputProps, ["modelValue", "readonly"], {
			readonly: true,
		});

		useRender(() => (
			<ElPopover
				ref={popoverRef}
				width="auto"
				popperClass="g-car-number__popover"
				trigger="click"
				showArrow={false}
				showAfter={0}
				hideAfter={0}
				onHide={handlePopoverHide}
			>
				{{
					reference: () => (
						<ElInput {...attrs} {...bindProps.value} class="g-car-number__input" vModel={states.value} formatter={handleInputFormatter} />
					),
					default: () => (
						<>
							<div class={["g-car-number__popover__area", states.switchLetter ? "g-car-number__popover__hide" : ""]}>
								{CarNumberArea.map((area) => (
									<ElButton disabled={states.disabledButton} onClick={() => handleSelectCarNumber(area)}>
										{area}
									</ElButton>
								))}
							</div>
							<div class={["g-car-number__popover__digit-letter", states.switchLetter ? "" : "g-car-number__popover__hide"]}>
								{CarNumberDigit.map((digit) => (
									<ElButton disabled={states.disabledButton} onClick={() => handleSelectCarNumber(digit)}>
										{digit}
									</ElButton>
								))}
								{CarNumberLetter.map((letter) => (
									<ElButton disabled={states.disabledButton} onClick={() => handleSelectCarNumber(letter)}>
										{letter}
									</ElButton>
								))}
							</div>
							<div class="g-car-number__popover__btn">
								<ElButton
									class="g-car-number__popover__btn__clear"
									disabled={states.value == null || states.value.length === 0}
									onClick={handleClearClick}
								>
									清除
								</ElButton>
								<ElButton
									class="g-car-number__popover__btn__back"
									type="danger"
									icon={Back}
									disabled={states.value == null || states.value.length === 0}
									onClick={handleBackClick}
								/>
								<ElButton
									class="g-car-number__popover__btn__confirm"
									type="primary"
									disabled={states.value == null || states.value.length < 7}
									onClick={handleConfirmClick}
								>
									确认
								</ElButton>
							</div>
						</>
					),
				}}
			</ElPopover>
		));

		expose({
			states,
		});

		return {
			attrs,
			bindProps,
			states,
		};
	},
});
