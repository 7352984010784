import type { ExtractPropTypes } from "vue";
import type { gUploadEmits, gUploadProps } from "./src/define";
import Upload from "./src/upload";
import { withInstall } from "@gejia-element-plus/utils";

export const GUpload = withInstall(Upload);
export default GUpload;

export * from "./src/define";
export * from "./src/type";

export type GUploadInstance = InstanceType<typeof Upload>;

export type GUploadProps = ExtractPropTypes<typeof gUploadProps>;

export type GUploadEmits = typeof gUploadEmits;
