import type { SlotsType } from "vue";
import { defineComponent, inject, reactive, ref, watch } from "vue";
import { ArrowDown, ArrowUp, Delete, Eleme, Refresh, Search } from "@element-plus/icons-vue";
import { ElButton, ElIcon } from "element-plus";
import { gTableSearchFormProps } from "./define";
import GTableSearchFormItem from "./tableSearchFormItem";
import type { GTableColumnCtx, GTableSearchFormStates } from "./type";
import type { GDrawerInstance } from "@gejia-element-plus/components/drawer";
import { GDrawer } from "@gejia-element-plus/components/drawer";
import { GLayoutGrid, GLayoutGridItem } from "@gejia-element-plus/components/layoutGrid";
import type { GLayoutGridBreakPoint, GLayoutGridItemResponsive } from "@gejia-element-plus/components/layoutGrid";
import type { GTableSearchColumnCtx, GTableStates } from "@gejia-element-plus/components/table";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GTableSearchForm 组件
 */
export default defineComponent({
	name: "GTableSearchForm",
	components: {
		ElButton,
		ElIcon,
		GTableSearchFormItem,
		GLayoutGrid,
		GLayoutGridItem,
		GDrawer,
		Refresh,
		Delete,
		ArrowDown,
		ArrowUp,
		Search,
		Eleme,
	},
	props: gTableSearchFormProps,
	slots: Object as SlotsType<anyObj<{ column: GTableColumnCtx; search?: () => void } & Partial<GTableStates>>>,
	setup(props, { slots, expose }) {
		const states: GTableSearchFormStates = reactive({
			height: "auto",
			collapsed: true,
			searchColumns: [],
			advancedSearchColumns: [],
			breakPoint: undefined,
		});

		const tableStates = inject<GTableStates>("tableStates");

		const gTableSearchFormRef = ref<HTMLElement>();
		const advancedSearchRef = ref<GDrawerInstance>();

		// 获取响应式设置
		const getResponsive = (
			item: GTableSearchColumnCtx
		): {
			span: number;
			offset: number;
			xs: GLayoutGridItemResponsive;
			sm: GLayoutGridItemResponsive;
			md: GLayoutGridItemResponsive;
			lg: GLayoutGridItemResponsive;
			xl: GLayoutGridItemResponsive;
		} => {
			return {
				span: item?.span,
				offset: item?.offset ?? 0,
				xs: item?.xs,
				sm: item?.sm,
				md: item?.md,
				lg: item?.lg,
				xl: item?.xl,
			};
		};

		// 获取响应式断点
		const gridRef = ref();

		const handleBreakPointChange = ({ breakPoint }: { breakPoint: GLayoutGridBreakPoint }): void => {
			// 这里 -1 是排除固定的
			states.breakPoint = props.size[breakPoint] - 1;
			states.searchColumns = tableStates.searchColumns.filter((f) => f?.show).slice(0, states.breakPoint);
			states.advancedSearchColumns = tableStates.searchColumns.filter((f) => f?.show).slice(states.breakPoint);
		};

		watch(
			() => tableStates.searchColumns,
			() => {
				if (states.breakPoint) {
					states.searchColumns = tableStates.searchColumns.filter((f) => f?.show).slice(0, states.breakPoint);
					states.advancedSearchColumns = tableStates.searchColumns.filter((f) => f?.show).slice(states.breakPoint);
				}
			}
		);

		useRender(() => (
			<>
				{tableStates.searchColumns.length > 0 ? (
					<div
						ref={gTableSearchFormRef}
						class={[
							"el-card g-table__search",
							props.show ? "" : "g-table__search-hidden",
							tableStates.loading ? "g-table__search__disable" : "",
						]}
					>
						<form class="el-form el-form--default el-form--label-right">
							<GLayoutGrid ref={gridRef} collapsed gap={[20, 0]} cols={props.size} onBreakPointChange={handleBreakPointChange}>
								{states.searchColumns.map((item, index) => (
									<GLayoutGridItem key={item?.search?.key ?? item.prop} {...getResponsive(item.search)} index={index}>
										<div class="el-form-item el-form-item--default el-form-item--label-right">
											<label class="el-form-item__label">{item.search.label}</label>
											<div class="el-form-item__content">
												{item.search?.slot ? (
													slots[item.search.slot] &&
													slots[item.search.slot]({ ...tableStates, column: item, search: props.search })
												) : (
													<GTableSearchFormItem column={item} search={props.search} />
												)}
											</div>
										</div>
									</GLayoutGridItem>
								))}
								<GLayoutGridItem suffix>
									<div class="g-table__search-operation">
										<ElButton
											loading={tableStates.loading}
											loadingIcon={Eleme}
											title="刷新"
											type="primary"
											icon={Refresh}
											onClick={props.search}
										/>
										<ElButton
											loading={tableStates.loading}
											loadingIcon={Eleme}
											title="重置"
											icon={Delete}
											onClick={props.reset}
										/>
										{states.advancedSearchColumns.length > 0 ? (
											<ElButton
												loading={tableStates.loading}
												loadingIcon={Eleme}
												title="高级搜索"
												icon={Search}
												onClick={() => {
													advancedSearchRef.value.open();
												}}
											/>
										) : null}
									</div>
								</GLayoutGridItem>
							</GLayoutGrid>
						</form>
						{states.advancedSearchColumns.length > 0 ? (
							<GDrawer
								ref={advancedSearchRef}
								class="g-table__search-advanced"
								size="45%"
								title="高级搜索"
								appendToBody={false}
								showCloseButton={false}
								showConfirmButton={false}
								showFullscreen={false}
								showRefresh={false}
							>
								<form class="el-form el-form--default el-form--label-top">
									<GLayoutGrid gap={[20, 0]} cols={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }}>
										{states.advancedSearchColumns.map((item, index) => (
											<GLayoutGridItem key={item.prop ?? item?.search?.key} {...getResponsive(item.search)} index={index}>
												<div class="el-form-item el-form-item--default el-form-item--label-top">
													<label class="el-form-item__label">{item.search.label}</label>
													<div class="el-form-item__content">
														{item.search.slot ? (
															slots[item.search.slot] &&
															slots[item.search.slot]({ ...tableStates, column: item, search: props.search })
														) : (
															<GTableSearchFormItem column={item} search={props.search} />
														)}
													</div>
												</div>
											</GLayoutGridItem>
										))}
									</GLayoutGrid>
								</form>
							</GDrawer>
						) : null}
					</div>
				) : null}
			</>
		));

		expose({
			states,
		});

		return {
			slots,
			states,
		};
	},
});
