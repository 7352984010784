import { type Ref } from "vue";
import type { UploadFile, UploadInstance, UploadRawFile, UploadStatus } from "element-plus";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useUpload = (uploadRef: Ref<UploadInstance>) => {
	const abort = (file: UploadFile): void => uploadRef?.value?.abort(file);
	const submit = (): void => uploadRef?.value?.submit();
	const clearFiles = (states?: UploadStatus[]): void => uploadRef?.value?.clearFiles(states);
	const handleStart = (rawFile: UploadRawFile): void => uploadRef?.value?.handleStart(rawFile);
	const handleRemove = (file: UploadRawFile | UploadFile, rawFile?: UploadRawFile | undefined): void =>
		uploadRef?.value?.handleRemove(file, rawFile);

	return {
		abort,
		submit,
		clearFiles,
		handleStart,
		handleRemove,
	};
};
