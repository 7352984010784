import { defineComponent, reactive, ref, watch } from "vue";
import { Delete, Search } from "@element-plus/icons-vue";
import { ElButton, ElButtonGroup, ElInput } from "element-plus";
import { gInputDialogPageEmits, gInputDialogPageProps } from "./define";
import type { GInputDialogPageStates } from "./type";
import type { GDialogInstance } from "@gejia-element-plus/components/dialog";
import { GDialog } from "@gejia-element-plus/components/dialog";
import type { GTableInstance } from "@gejia-element-plus/components/table";
import { GTable } from "@gejia-element-plus/components/table";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GInputDialogPage 组件
 */
export default defineComponent({
	name: "GInputDialogPage",
	components: {
		// GDialog,
		// GTable,
		ElInput,
		ElButtonGroup,
		ElButton,
		Delete,
		Search,
	},
	props: gInputDialogPageProps,
	emits: gInputDialogPageEmits,
	setup(props, { emit, expose }) {
		const states: GInputDialogPageStates = reactive({
			value: undefined,
			label: undefined,
			selectedRow: undefined,
		});

		const gDialogRef = ref<GDialogInstance>();
		const gTableRef = ref<GTableInstance>();

		const handleDeleteClick = (): void => {
			states.value = null;
			states.label = null;
			emit("update:modelValue", null);
			emit("update:label", null);
			emit("change", null);
		};

		const handleSearchClick = (): void => {
			gDialogRef.value.open();
		};

		const handleDialogClick = (): void => {
			if (gTableRef.value.states.selected) {
				const selectedData = gTableRef.value.states.selectedList[0];
				emit("update:modelValue", selectedData[props.rowKey]);
				emit("update:label", selectedData[props.labelKey]);
				emit("change", selectedData);
			} else {
				emit("update:modelValue", null);
				emit("update:label", null);
				emit("change", null);
			}
			gDialogRef.value.close();
		};

		const handleTableRowDblclick = (row: any): void => {
			gTableRef.value.clearSelection();
			gTableRef.value.toggleRowSelection(row, true);
			states.selectedRow = row;
			handleDialogClick();
		};

		/**
		 * 打开
		 */
		const open = (): void => {
			gDialogRef.value.open(() => {
				if (states.selectedRow) {
					gTableRef.value.toggleRowSelection(states.selectedRow, true);
				}
				return Promise.resolve();
			});
		};

		/**
		 * 清除
		 */
		const clear = (): void => {
			handleDeleteClick();
		};

		watch(
			() => props.modelValue,
			(newValue) => {
				states.value = newValue;
				states.label = props.label;
			},
			{
				immediate: true,
			}
		);

		useRender(() => (
			<div class="g-input-dialog-page">
				<ElInput vModel={states.label} placeholder={props.placeholder} disabled={props.disabled} readonly>
					{{
						append: () => (
							<ElButtonGroup>
								<ElButton disabled={props.disabled} icon={Delete} onClick={handleDeleteClick} />
								<ElButton disabled={props.disabled} icon={Search} onClick={handleSearchClick} />
							</ElButtonGroup>
						),
					}}
				</ElInput>
				<GDialog
					ref={gDialogRef}
					title={props.title}
					disabledConfirmButton={!gTableRef.value?.states?.selected}
					onConfirmClick={handleDialogClick}
				>
					<GTable
						ref={gTableRef}
						tableKey={props.tableKey}
						rowKey={props.rowKey}
						requestApi={props.requestApi}
						columns={props.columns}
						initParam={props.initParam}
						single
						highlightCurrentRow
						onRowDblclick={handleTableRowDblclick}
						hideSearchTime
					/>
				</GDialog>
			</div>
		));

		expose({
			props,
			emit,
			states,
			open,
			clear,
		});

		return {
			props,
			emit,
			states,
			open,
			clear,
		};
	},
});
