import type { SetupContext } from "vue";
import { defineComponent, nextTick, onMounted, reactive, ref, withModifiers } from "vue";
import { ArrowLeft, ArrowRight, Close } from "@element-plus/icons-vue";
import { ElDropdown, ElDropdownItem, ElDropdownMenu, ElIcon, ElScrollbar } from "element-plus";
import type { RouteLocationNormalized } from "vue-router";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import type { GLayoutNavBarTabStates } from "./type";
import type { GContextMenuInstance } from "@gejia-element-plus/components/contextMenu";
import { GContextMenu } from "@gejia-element-plus/components/contextMenu";
import { GLayoutScreenFull } from "@gejia-element-plus/layouts/components/screenFull";
import type { INavBarTab } from "@gejia-element-plus/stores";
import { pinia, useNavTabs } from "@gejia-element-plus/stores";
import { routerUtil, useRender } from "@gejia-element-plus/utils";

/**
 * GLayoutNavBarTab 组件
 */
export default defineComponent({
	name: "GLayoutNavBarTab",
	components: {
		ElScrollbar,
		ElIcon,
		ElDropdown,
		ElDropdownMenu,
		ElDropdownItem,
		ArrowLeft,
		ArrowRight,
		GContextMenu,
		Close,
		GLayoutScreenFull,
	},
	setup(props, { expose }: SetupContext) {
		const route = useRoute();
		const router = useRouter();
		const navTabsStore = useNavTabs(pinia);

		let curContextmenuTab: INavBarTab = null;

		const states: GLayoutNavBarTabStates = reactive({
			currentScrollLeft: 0,
			currentScrollRight: 0,
			translateDistance: 100,
			arrowIconWidth: 46,
			contextMenuList: [
				{
					name: "refresh",
					label: "重新加载",
					disabled: false,
					icon: "el-refresh",
					click: (): void => {
						if (!curContextmenuTab) return;
						navTabsStore.refreshTab(curContextmenuTab);
					},
				},
				{
					name: "close",
					label: "关闭标签",
					disabled: false,
					icon: "el-close",
					click: (): void => {
						if (!curContextmenuTab) return;
						navTabsStore.closeTab(curContextmenuTab);
					},
				},
				{
					name: "closeOther",
					label: "关闭其他标签",
					disabled: false,
					icon: "el-close",
					click: (): void => {
						if (!curContextmenuTab) return;
						navTabsStore.closeTabs(curContextmenuTab);
					},
				},
				{
					name: "closeAll",
					label: "关闭全部标签",
					disabled: false,
					icon: "el-close",
					click: (): void => {
						if (!curContextmenuTab) return;
						navTabsStore.closeTabs();
					},
				},
			],
		});

		const tagRefs = ref<HTMLElement[]>([]);
		const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>();
		const gContextMenuRef = ref<GContextMenuInstance>();

		/**
		 * 获取可能需要的宽度
		 */
		const getWidth = (): {
			scrollWidth: number;
			clientWidth: number;
			lastDistance: number;
		} => {
			/** 可滚动内容的长度 */
			const scrollWidth = scrollbarRef.value.wrapRef.scrollWidth;
			/** 滚动可视区宽度 */
			const clientWidth = scrollbarRef.value.wrapRef.clientWidth;
			/** 最后剩余可滚动的宽度 */
			const lastDistance = scrollWidth - clientWidth - states.currentScrollLeft;

			return { scrollWidth, clientWidth, lastDistance };
		};

		/**
		 * 左右滚动
		 */
		const handleScrollTo = (direction: "left" | "right", distance: number = states.translateDistance): void => {
			let scrollLeft = 0;
			const { scrollWidth, clientWidth } = getWidth();
			// 没有横向滚动条，直接结束
			if (clientWidth > scrollWidth) return;
			const currentScrollLeft = states.currentScrollLeft;
			if (direction === "left") {
				scrollLeft = Math.max(0, currentScrollLeft - distance);
			} else {
				scrollLeft = Math.min(currentScrollLeft + distance);
			}
			scrollbarRef.value.setScrollLeft(scrollLeft);
		};

		/**
		 * 鼠标滚轮滚动时触发
		 */
		const handleWheelScroll = (event: WheelEvent): void => {
			if (/^-/.test(event.deltaY.toString())) {
				handleScrollTo("left");
			} else {
				handleScrollTo("right");
			}
		};

		/**
		 * 滚动时触发
		 */
		const handleScroll = ({ scrollLeft }: { scrollLeft: number }): void => {
			states.currentScrollLeft = scrollLeft;
		};

		/**
		 * 移动到目标位置
		 */
		const handleMoveTo = (to?: RouteLocationNormalized): void => {
			nextTick(() => {
				const curTo = to ?? route;
				const findTagRef = tagRefs.value.find((f) => f && f["__vnode"]?.key === curTo.path);
				if (findTagRef) {
					const { offsetWidth, offsetLeft } = findTagRef;
					const { clientWidth } = getWidth();
					// 当前 tag 在可视区域左边时
					if (offsetLeft < states.currentScrollLeft) {
						const distance = states.currentScrollLeft - offsetLeft;
						handleScrollTo("left", distance);
						return;
					}

					// 当前 tag 在可视区域右边时
					const width = clientWidth + states.currentScrollLeft - offsetWidth;
					if (offsetLeft > width) {
						const distance = offsetLeft - width;
						handleScrollTo("right", distance);
						return;
					}
				}
			});
		};

		const handleTabClick = (event: MouseEvent, tag: INavBarTab): void => {
			if (tag.path === route.path) return;
			// 左键
			routerUtil.routePushSafe({ path: tag.path, query: tag.query });
		};

		const handleContextmenuClick = (event: MouseEvent, tag: INavBarTab): void => {
			// 禁用重新加载
			states.contextMenuList[0].disabled = tag.path !== route.path;
			// 禁用关闭
			states.contextMenuList[1].disabled = tag?.meta?.affix === true;
			// 禁用关闭其他和关闭全部
			states.contextMenuList[2].disabled = states.contextMenuList[3].disabled = navTabsStore.states.navBarTabs.length === 1 ? true : false;

			curContextmenuTab = tag;

			const { clientX, clientY } = event;
			gContextMenuRef.value.open({ x: clientX, y: clientY });
		};

		const handleCloseClick = (event: MouseEvent, tag: INavBarTab): void => {
			if (tag.meta?.affix === true) {
				return;
			}
			navTabsStore.closeTab(tag);
			gContextMenuRef.value?.close();
		};

		onMounted(() => {
			navTabsStore.initNavBarTabs(router);
			navTabsStore.addTab(router.currentRoute.value);
			navTabsStore.setActiveRoute(router.currentRoute.value);
			handleMoveTo(router.currentRoute.value);
		});

		onBeforeRouteUpdate((to: RouteLocationNormalized) => {
			navTabsStore.addTab(routerUtil.pickByRoute(to));
			navTabsStore.setActiveRoute(to);
			handleMoveTo(to);
		});

		useRender(() => (
			<div class="g-layout-nav-bar-tab">
				{
					<ElIcon
						class="g-layout-nav-bar-tab__icon-arrow g-layout-nav-bar-tab__icon-left g-twinkle"
						title="向左滚动"
						onClick={() => handleScrollTo("left")}
					>
						<ArrowLeft />
					</ElIcon>
				}
				<ElScrollbar
					ref={scrollbarRef}
					class="g-layout-nav-bar-tab__scrollbar"
					viewClass="g-layout-nav-bar-tab__scrollbar-content"
					onWheel={withModifiers((event: Event) => handleWheelScroll(event as WheelEvent), ["prevent"])}
					onScroll={handleScroll}
				>
					{(navTabsStore.states.navBarTabs as INavBarTab[]).map((tag, idx) => (
						<div
							ref={(el) => (tagRefs.value[idx] = el)}
							key={tag.path}
							class={[
								"g-layout-nav-bar-tab__item",
								route.path === tag.path ? "g-layout-nav-bar-tab__item-is-active" : "g-layout-nav-bar-tab__item-not-active",
							]}
							onClick={withModifiers((event: Event) => handleTabClick(event as MouseEvent, tag), ["prevent"])}
							onMousedown={withModifiers((event: Event) => handleCloseClick(event as MouseEvent, tag), ["middle"])}
							onContextmenu={withModifiers((event: Event) => handleContextmenuClick(event as MouseEvent, tag), ["prevent"])}
							title={tag.meta?.title}
						>
							{tag.meta?.title}
							{tag.meta?.affix === true ? null : route.path === tag.path ? (
								<ElIcon
									class="g-layout-nav-bar-tab__icon-close"
									size={12}
									onClick={withModifiers((event: Event) => handleCloseClick(event as MouseEvent, tag), ["prevent", "stop"])}
									title="关闭"
								>
									<Close />
								</ElIcon>
							) : null}
						</div>
					))}
				</ElScrollbar>
				{
					<ElIcon
						class="g-layout-nav-bar-tab__icon-arrow g-layout-nav-bar-tab__icon-right g-twinkle"
						title="向右滚动"
						onClick={() => handleScrollTo("right")}
					>
						<ArrowRight />
					</ElIcon>
				}
				<GLayoutScreenFull class="g-layout-nav-bar-tab__icon-arrow" dropdown />
				<GContextMenu ref={gContextMenuRef} data={states.contextMenuList} />
			</div>
		));

		expose({
			states,
			tagRefs,
			handleScrollTo,
			handleMoveTo,
		});

		return {
			props,
			states,
			tagRefs,
			handleScrollTo,
			handleMoveTo,
		};
	},
});
