import type { SlotsType, VNode } from "vue";
import { computed, defineComponent, inject, reactive, ref, watch } from "vue";
import { UploadFilled } from "@element-plus/icons-vue";
import { Decimal } from "decimal.js";
import type { UploadFile, UploadFiles, UploadInstance, UploadProps, UploadRequestOptions, UploadUserFile } from "element-plus";
import { ElIcon, ElMessage, ElNotification, ElUpload, formContextKey, formItemContextKey, genFileId, uploadProps } from "element-plus";
import type { gUploadSlots } from "./define";
import { gUploadEmits, gUploadProps } from "./define";
import type { GUploadStates } from "./type";
import { useUpload } from "./useUpload";
import { uploadUtil, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GUpload 组件
 */
export default defineComponent({
	name: "GUpload",
	components: {
		ElUpload,
		ElIcon,
		UploadFilled,
	},
	props: gUploadProps,
	emits: gUploadEmits,
	slots: Object as SlotsType<typeof gUploadSlots>,
	setup(props, { attrs, slots, emit, expose }) {
		// 获取 el-form 组件上下文
		const formContext = inject(formContextKey, undefined);
		// 获取 el-form-item 组件上下文
		const formItemContext = inject(formItemContextKey, undefined);

		const states: GUploadStates = reactive({
			loading: false,
			disabled: computed(() => {
				return props.disabled || formContext?.disabled;
			}),
			fileList: [],
		});

		const uploadRef = ref<UploadInstance>();

		const handleValue = (): void => {
			if (states.fileList.length > 0) {
				if (props.multiple) {
					const value = states.fileList.map((m) => m.url);
					emit("update:modelValue", value);
					emit("change", value);
				} else {
					emit("update:modelValue", states.fileList[0].url);
					emit("change", states.fileList[0].url);
				}
			} else {
				emit("update:modelValue", null);
				emit("change", null);
			}
		};

		const handleOnChange: UploadProps["onChange"] = (uploadFile, uploadFiles) => {
			if (uploadFile.status !== "ready") return;

			const fileSizeKB = new Decimal(uploadFile.size).div(new Decimal(1024));
			if (fileSizeKB.greaterThan(new Decimal(props.maxSize))) {
				const maxSizeMB = new Decimal(props.maxSize).div(new Decimal(1024));
				console.warn("[gejia-GUpload]", `【${uploadFile.name}】文件上传大小不能超过 ${maxSizeMB.toString()}MB`);
				ElMessage.warning(`【${uploadFile.name}】文件上传大小不能超过 ${maxSizeMB.toString()}MB`);
				states.fileList = states.fileList.slice(0, -1);
				return;
			}

			if (props.accept && props.accept.split(",").every((e) => e !== uploadFile.raw.type)) {
				const uploadFileNames = uploadUtil.detectFileType(props.accept);
				ElMessage.error(`只允许上传【${uploadFileNames}】格式的文件`);
				console.error("[gejia-GUpload]", `只允许上传【${uploadFileNames}】格式的文件`);
				states.fileList = states.fileList.slice(0, -1);
				return;
			}

			props.onChange && props.onChange(uploadFile, uploadFiles);
		};

		const handleHttpRequest = async (options: UploadRequestOptions): Promise<void> => {
			if (!props.fileType) {
				ElMessage.error("上传文件类型不能为空");
				console.error("[gejia-GUpload]", "上传文件类型 “fileType” 不能为空");
				return;
			}
			let propsData: anyObj;
			if (props.data) {
				propsData = uploadUtil.getPropsData(options.file, props.data);
			}
			states.loading = true;
			try {
				const fileUrl = await uploadUtil.uploadFile(options.file, options.filename, props.fileType, propsData);
				options.onSuccess(fileUrl);
			} finally {
				states.loading = false;
			}
		};

		const handleOnSuccess = (fileUrl: string, uploadFile: UploadFile, uploadFiles: UploadFiles): void => {
			if (!fileUrl) return;
			if (!props.multiple && uploadFiles.length > 1) {
				uploadFiles.shift();
			}
			uploadFile.url = fileUrl;
			handleValue();
			// 调用 el-form 内部的校验方法（可自动校验）
			formItemContext?.prop && formContext?.validateField([formItemContext.prop as string]);
			ElMessage.success("上传成功");
			props.onSuccess && props.onSuccess(fileUrl, uploadFile, uploadFiles);
		};

		const handleOnError = (error: Error, uploadFile: UploadFile, uploadFiles: UploadFiles): void => {
			ElNotification({
				message: `【${uploadFile.name}】文件上传失败，请您重新上传`,
				type: "error",
			});
			props.onError && props.onError(error, uploadFile, uploadFiles);
		};

		const handleOnExceed = (files: File[], uploadFiles: UploadUserFile[]): void => {
			ElMessage.warning(`最多只能上传 ${props.limit} 个文件，请移除后再进行上传`);
			props.onExceed && props.onExceed(files, uploadFiles);
		};

		watch(
			() => states.fileList,
			(newValue) => {
				emit("update:fileList", newValue);
			}
		);

		/**
		 * 监听 v-model 绑定数据
		 */
		watch(
			() => props.modelValue,
			(newValue) => {
				states.fileList = [];
				if (newValue) {
					if (Array.isArray(newValue)) {
						newValue.forEach((item) => {
							states.fileList.push({
								name: "",
								status: "success",
								uid: genFileId(),
								url: item,
							});
						});
					} else {
						states.fileList.push({
							name: "",
							status: "success",
							uid: genFileId(),
							url: newValue,
						});
					}
				}
			},
			{
				immediate: true,
			}
		);

		const bindProps = useProps(props, uploadProps, ["fileList", "disabled", "httpRequest", "onExceed", "onSuccess", "onError", "onChange"]);

		useRender(() => (
			<ElUpload
				{...attrs}
				{...bindProps.value}
				ref={uploadRef}
				class="g-upload"
				vLoading={states.loading}
				vModel:fileList={states.fileList}
				disabled={states.disabled}
				httpRequest={handleHttpRequest}
				onExceed={handleOnExceed}
				onSuccess={handleOnSuccess}
				onError={handleOnError}
				onChange={handleOnChange}
			>
				{{
					default: () =>
						slots.empty ? (
							slots.empty(states)
						) : (
							<>
								<ElIcon class="el-icon--upload">
									<UploadFilled />
								</ElIcon>
								<div class="el-upload__text">
									Drop file here or <em>click to upload</em>
								</div>
							</>
						),
					...(slots.trigger && { trigger: (): VNode | VNode[] => slots.trigger(states) }),
					tip: () =>
						slots.tip ? (
							slots.tip(states)
						) : (
							<>
								<div class="el-upload__tip">
									files with a size less than {new Decimal(props.maxSize).div(new Decimal(1024)).toString()}MB
								</div>
								{!props.showFileList && states.fileList?.length > 0 ? (
									<div class="el-upload__tip">
										{states.fileList.map((item, index) => (
											<>
												{item.name}
												{states.fileList.length <= index ? <br /> : null}
											</>
										))}
									</div>
								) : null}
							</>
						),
					...(slots.file && { file: ({ file }: { file: UploadFile }): VNode | VNode[] => slots.file({ ...states, file }) }),
				}}
			</ElUpload>
		));

		expose({
			states,
			...useUpload(uploadRef),
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			...useUpload(uploadRef),
		};
	},
});
