import type { App } from "vue";
import GejiaElementPlusComponents from "./component";
import GejiaElementPlusDirectives from "./directive";
import GejiaElementPlusLayouts from "./layout";
import { GejiaElementPlusIconsVue } from "@gejia-element-plus/icons-vue";

const installGejiaElementPlus = (app: App): void => {
	/** 注册所有 Gejia Element Plus Icon */
	for (const component of GejiaElementPlusIconsVue) {
		// 这里是给 GIcon 使用的
		app.component(`g-icon-${component.name}`, component);
	}

	GejiaElementPlusComponents.forEach((c) => app.use(c));
	GejiaElementPlusLayouts.forEach((l) => app.use(l));

	GejiaElementPlusDirectives.forEach((d) => app.use(d));
};

export { installGejiaElementPlus };
