import type { ExtractPropTypes } from "vue";
import type { gDialogEmits, gDialogProps } from "./src/define";
import Dialog from "./src/dialog";
import { withInstall } from "@gejia-element-plus/utils";

export const GDialog = withInstall(Dialog);
export default GDialog;

export * from "./src/define";
export * from "./src/type";

export type GDialogInstance = InstanceType<typeof Dialog>;

export type GDialogProps = ExtractPropTypes<typeof gDialogProps>;

export type GDialogEmits = typeof gDialogEmits;
