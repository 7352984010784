/**
 * GModuleMenu 放在公共组件是因为样式问题
 */
import ModuleMenu from "./src/moduleMenu";
import { withInstall } from "@gejia-element-plus/utils";

export const GModuleMenu = withInstall(ModuleMenu);
export default GModuleMenu;

export * from "./src/type";

export type GModuleMenuInstance = InstanceType<typeof ModuleMenu>;
