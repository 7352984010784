import type { PropType, VNode } from "vue";
import type { UploadFile, UploadUserFile } from "element-plus";
import { uploadProps } from "element-plus";
import type { GUploadStates, StoreFileType } from "./type";

export const gUploadProps = {
	...uploadProps,
	/**
	 * v-model绑定值
	 */
	modelValue: [String, Array] as PropType<string | string[]>,
	/**
	 * 是否启用拖拽上传
	 */
	drag: {
		type: Boolean,
		default: true,
	},
	/**
	 * 最大上传
	 */
	limit: {
		type: Number,
		default: 1,
	},
	/**
	 * 文件类型
	 */
	fileType: {
		type: Number as PropType<StoreFileType>,
	},
	/**
	 * 大小限制，单位kb
	 */
	maxSize: {
		type: Number,
		default: 5120,
	},
};

export const gUploadEmits = {
	/**
	 * v-model 回调
	 */
	"update:modelValue": (value: string | string[]): boolean => true,
	/**
	 * v-model:fileList 回调
	 */
	"update:fileList": (value: UploadUserFile[]): boolean => true,
	/**
	 * 改变
	 */
	change: (value: string | string[]): boolean => true,
};

export const gUploadSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GUploadStates): VNode[] => [],
	/**
	 * 触发文件选择框的内容
	 */
	trigger: (states: GUploadStates): VNode[] => [],
	/**
	 * 提示说明文字
	 */
	tip: (states: GUploadStates): VNode[] => [],
	/**
	 * 缩略图模板的内容
	 */
	file: (states: GUploadStates & { file: UploadFile }): VNode[] => [],
	/**
	 * 空状态
	 */
	empty: (states: GUploadStates): VNode[] => [],
};
