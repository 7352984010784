import type { ExtractPropTypes } from "vue";
import type { gLayoutLogoProps } from "./src/define";
import LayoutLogo from "./src/logo";
import { withInstall } from "@gejia-element-plus/utils";

export const GLayoutLogo = withInstall(LayoutLogo);
export default GLayoutLogo;

export * from "./src/define";

export type GLayoutLogoInstance = InstanceType<typeof LayoutLogo>;

export type GLayoutLogoProps = ExtractPropTypes<typeof gLayoutLogoProps>;
