import type { PropType } from "vue";
import { SelectProps } from "@gejia-element-plus/components/select";

export const gSelectPageProps = {
	...SelectProps,
	/**
	 * v-model绑定值
	 */
	modelValue: [Number, String, Boolean, Object, Array] as PropType<number | string | boolean | object | any[]>,
	valueKey: {
		type: String,
		default: "value",
	},
	/**
	 * 请求下拉框数据的api
	 */
	requestApi: {
		type: Function as PropType<(param?: PagedInput) => ApiPromise<PagedResult<ElSelectorOutput | any>>>,
		required: true,
	},
	/**
	 * v-model:label 绑定值
	 * 选项显示的值
	 */
	label: String,
	/**
	 * Label Key，默认 label
	 */
	labelKey: {
		type: String,
		default: "label",
	},
	/**
	 * disabled Key，默认 disabled
	 */
	disabledKey: {
		type: String,
		default: "disabled",
	},
	/**
	 * children Key，默认 children
	 */
	childrenKey: {
		type: String,
		default: "children",
	},
	/**
	 * 宽度
	 */
	width: {
		type: String,
		default: "100%",
	},
	/**
	 * 默认参数
	 */
	initParam: {
		type: Object as PropType<anyObj>,
		default: {},
	},
	/**
	 * 更多细节，只有自定义slot的时候有用
	 */
	moreDetail: Boolean,
};

export const gSelectPageEmits = {
	/**
	 * v-model 回调
	 */
	"update:modelValue": (value: number | string | boolean | object | any[]): boolean => true,
	/**
	 * v-model:label 回调
	 */
	"update:label": (value: string | number): boolean => true,
	/**
	 * 选中改变事件
	 */
	change: (value: ElSelectorOutput | any): boolean => true,
	/**
	 * 下拉框出现/隐藏事件
	 */
	visibleChange: (visible: boolean): boolean => true,
	/**
	 * 选择器数据改变回调事件
	 */
	dataChangeCallBack: (data: ElSelectorOutput[] | any[]): boolean => true,
};
