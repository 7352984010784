import type { SlotsType } from "vue";
import { defineComponent } from "vue";
import { QuestionFilled } from "@element-plus/icons-vue";
import { ElIcon, ElTooltip } from "element-plus";
import { gFormItemTipProps, type gFormItemTipSlots } from "./define";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GFormItemTip 组件
 */
export default defineComponent({
	name: "GFormItemTip",
	components: {
		ElTooltip,
		ElIcon,
		QuestionFilled,
	},
	props: gFormItemTipProps,
	slots: Object as SlotsType<typeof gFormItemTipSlots>,
	setup(props, { slots, expose }) {
		useRender(() => (
			<div class="g-form-item-tip">
				<ElTooltip effect="dark" rawContent content={props.tips} placement="top">
					<ElIcon>
						<QuestionFilled />
					</ElIcon>
				</ElTooltip>
				{slots.label ? slots.label() : <span class="g-form-item-tip__label">{props.label}</span>}
			</div>
		));

		expose({});

		return {
			slots,
		};
	},
});
