import { defineComponent, reactive, ref, watch } from "vue";
import { Search } from "@element-plus/icons-vue";
import { ElButton, ElInput, ElOption, ElPagination, ElSelect } from "element-plus";
import { gSelectPageEmits, gSelectPageProps } from "./define";
import type { GSelectPageStates } from "./type";
import { GSelectOption, SelectProps } from "@gejia-element-plus/components/select";
import { useSelect } from "@gejia-element-plus/components/select/src/useSelect";
import { errorHandler, typeUtil, useProps, useRender, withTryNumber } from "@gejia-element-plus/utils";

/**
 * GSelectPage 组件
 */
export default defineComponent({
	name: "GSelectPage",
	components: {
		ElSelect,
		ElOption,
		ElInput,
		ElButton,
		ElPagination,
		GSelectOption,
	},
	props: gSelectPageProps,
	emits: gSelectPageEmits,
	setup(props, { attrs, slots, emit, expose }) {
		const states: GSelectPageStates = reactive({
			value: undefined,
			label: undefined,
			loading: false,
			selectorData: [],
			debut: true,
			echo: true,
			nextRefresh: false,
			pageIndex: 1,
			pageSize: 15,
			totalRows: 0,
			searchValue: undefined,
			defaultSelectorData: undefined,
		});

		const selectRef = ref<InstanceType<typeof ElSelect>>();

		const handleSelectorData = (selectorData: any[]): any[] => {
			return selectorData.map((item) => {
				return { ...item, [props.valueKey]: withTryNumber(item[props.valueKey]) };
			});
		};

		const loadData = (pageIndex?: number): void => {
			states.loading = true;
			states.pageIndex = pageIndex ?? states.pageIndex;
			const param = {
				...(props.initParam ?? {}),
				pageIndex: states.pageIndex,
				pageSize: states.pageSize,
				searchValue: states.searchValue,
			};
			props
				.requestApi(param)
				.then((apiResult) => {
					// 这里不允许回显了
					states.echo = false;
					states.totalRows = apiResult.data.totalRows;
					states.selectorData = handleSelectorData(apiResult.data.rows);
					emit("dataChangeCallBack", states.selectorData);
				})
				.catch((error) => {
					console.error("[gejia-GSelectPage]", error);
					states.pageIndex = 1;
					states.totalRows = 0;
					states.selectorData = [];
					errorHandler(error);
				})
				.finally(() => {
					states.loading = false;
				});
		};

		const handleChange = (value: number | string | boolean | object | any[]): void => {
			if (typeUtil.isEqual(props.modelValue, value)) return;
			if (value != undefined && value != null) {
				const data = states.selectorData.find((f) => f[props.valueKey] == value);
				states.value = value;
				states.label = data[props.labelKey];
				emit("update:modelValue", value);
				emit("update:label", states.label);
				emit("change", data);
			} else {
				states.value = null;
				states.label = null;
				emit("update:modelValue", null);
				emit("update:label", null);
				emit("change", null);
			}
		};

		const handleVisibleChange = (visible: boolean): void => {
			if (visible) {
				if (states.debut) {
					// 首次出现
					states.debut = false;
					loadData();
				} else {
					// 判断再次出现是否需要刷新数据
					if (states.nextRefresh) {
						states.nextRefresh = false;
						loadData();
					}
				}
			}
			emit("visibleChange", visible);
		};

		/**
		 * 设置选中
		 */
		const setSelected = (value: number | string | boolean | object | any[]): void => {
			handleChange(value);
		};

		/**
		 * 清空选中
		 */
		const clearSelected = (): void => {
			handleChange(null);
		};

		watch(
			() => props.initParam,
			(newValue, oldValue) => {
				if (!typeUtil.isEqual(newValue, oldValue)) {
					states.nextRefresh = true;
				}
			}
		);

		watch(
			() => props.modelValue,
			(newValue) => {
				if (states.echo && newValue != undefined && newValue != null) {
					states.defaultSelectorData = {
						[props.valueKey]: withTryNumber(newValue),
						[props.labelKey]: props.label,
						[props.disabledKey]: true,
					};
					states.value = withTryNumber(newValue);
				} else {
					states.value = withTryNumber(newValue);
				}
			},
			{
				immediate: true,
			}
		);

		const bindProps = useProps(props, SelectProps, ["modelValue", "loading"], {
			loadingText: "加载中...",
			noMatchText: "暂无匹配的数据",
			noDataText: "暂无数据",
			collapseTags: true,
			collapseTagsTooltip: true,
		});

		useRender(() => (
			<ElSelect
				{...attrs}
				{...bindProps.value}
				ref={selectRef}
				class="g-select-page"
				popperClass="g-select-page-dropdown"
				style={{ width: props.width }}
				vModel={states.value}
				loading={states.loading}
				onChange={handleChange}
				onVisibleChange={handleVisibleChange}
			>
				{{
					header: () => (
						<>
							{states.defaultSelectorData ? (
								<ElOption
									class="g-select-page-dropdown__default-selector"
									label={states.defaultSelectorData[props.labelKey]}
									value={states.defaultSelectorData[props.valueKey]}
									disabled={true}
								>
									<span>{states.defaultSelectorData[props.labelKey]}</span>
									<span>Default</span>
								</ElOption>
							) : null}
							<ElInput
								class="g-select-page-dropdown__search-input"
								clearable
								placeholder="请输入关键字搜索"
								vModel_trim={states.searchValue}
								onKeyup={(event: KeyboardEvent) => {
									if (event.key == "Enter") {
										loadData(1);
									}
								}}
							>
								{{
									append: () => <ElButton loading={states.loading} icon={Search} onClick={() => loadData(1)}></ElButton>,
								}}
							</ElInput>
						</>
					),
					default: () =>
						states.selectorData.map((item) => (
							<GSelectOption
								vSlots={slots}
								data={item}
								valueKey={props.valueKey}
								labelKey={props.labelKey}
								childrenKey={props.childrenKey}
								disabledKey={props.disabledKey}
								moreDetail={props.moreDetail}
							/>
						)),
					footer: () => (
						<ElPagination
							class="g-select-page-dropdown__pagination"
							size="small"
							currentPage={states.pageIndex}
							pageSize={states.pageSize}
							total={states.totalRows}
							layout="prev, pager, next, total"
							pagerCount={5}
							onCurrentChange={loadData}
						/>
					),
				}}
			</ElSelect>
		));

		expose({
			states,
			refresh: loadData,
			setSelected,
			clearSelected,
			...useSelect(selectRef),
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			refresh: loadData,
			setSelected,
			clearSelected,
			...useSelect(selectRef),
		};
	},
});
