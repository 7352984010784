import { type Ref } from "vue";
import type { ElSelect } from "element-plus";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSelect = (selectRef: Ref<InstanceType<typeof ElSelect>>) => {
	const focus = (): void => selectRef?.value?.focus();
	const blur = (): void => selectRef?.value?.blur();

	return {
		focus,
		blur,
	};
};
