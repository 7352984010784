import type { SlotsType } from "vue";
import { computed, defineComponent, reactive } from "vue";
import { Picture as ElIconPicture } from "@element-plus/icons-vue";
import { ElIcon, ElImage, imageProps } from "element-plus";
import { gImageProps, type gImageSlots } from "./define";
import type { GImageStates } from "./type";
import { useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GImage 组件
 */
export default defineComponent({
	name: "GImage",
	components: {
		ElImage,
		ElIcon,
		ElIconPicture,
	},
	props: gImageProps,
	slots: Object as SlotsType<typeof gImageSlots>,
	setup(props, { attrs, slots, expose }) {
		const states: GImageStates = reactive({
			src: computed(() => {
				if (props.src) {
					if (props.original) {
						return props.src;
					} else if (props.normal) {
						return `${props.src}@!normal`;
					} else if (props.small) {
						return `${props.src}@!small`;
					} else if (props.thumb) {
						return `${props.src}@!thumb`;
					} else {
						// 默认使用缩略图
						return `${props.src}@!thumb`;
					}
				} else {
					return "";
				}
			}),
			previewList: computed(() => (props.preview ? [props.src] : [])),
		});

		const bindProps = useProps(props, imageProps, ["src", "previewSrcList"], {
			closeOnPressEscape: true,
			hideOnClickModal: true,
			previewTeleported: true,
		});

		useRender(() => (
			<ElImage {...attrs} {...bindProps.value} class="g-image" src={states.src} previewSrcList={states.previewList}>
				{{
					error: () =>
						slots.error ? (
							slots.error(states)
						) : (
							<div class="g-image__error-image">
								<ElIcon class="icon">
									<ElIconPicture />
								</ElIcon>
							</div>
						),
				}}
			</ElImage>
		));

		expose({
			states,
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
		};
	},
});
