import type { CSSProperties, PropType, VNode } from "vue";
import type { AxiosResponse } from "axios";
import type { TableColumnCtx, TableProps } from "element-plus";
import type { GTableColumnCtx, GTableExportExcelInput, GTableStates } from "./type";
import type { GLayoutGridBreakPoint } from "@gejia-element-plus/components/layoutGrid";

// import tableProps from "element-plus/es/components/table/src/table/defaults";
export const tableProps = {
	/**
	 * @description table data
	 */
	data: {
		type: Array as PropType<any[]>,
		default: (): [] => [],
	},
	/**
	 * @description size of Table
	 */
	type: String as PropType<"" | "default" | "small" | "large">,
	width: [String, Number],
	/**
	 * @description table's height. By default it has an `auto` height. If its value is a number, the height is measured in pixels; if its value is a string, the value will be assigned to element's style.height, the height is affected by external styles
	 */
	height: [String, Number],
	/**
	 * @description table's max-height. The legal value is a number or the height in px
	 */
	maxHeight: [String, Number],
	/**
	 * @description whether width of column automatically fits its container
	 */
	fit: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description whether Table is striped
	 */
	stripe: Boolean,
	/**
	 * @description whether Table has vertical border
	 */
	border: Boolean,
	/**
	 * @description key of row data, used for optimizing rendering. Required if `reserve-selection` is on or display tree data. When its type is String, multi-level access is supported, e.g. `user.info.id`, but `user.info[0].id` is not supported, in which case `Function` should be used
	 */
	rowKey: [String, Function] as PropType<TableProps<any>["rowKey"]>,
	/**
	 * @description whether Table header is visible
	 */
	showHeader: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description whether to display a summary row
	 */
	showSummary: Boolean,
	/**
	 * @description displayed text for the first column of summary row
	 */
	sumText: String,
	/**
	 * @description custom summary method
	 */
	summaryMethod: Function as PropType<TableProps<any>["summaryMethod"]>,
	/**
	 * @description function that returns custom class names for a row, or a string assigning class names for every row
	 */
	rowClassName: [String, Function] as PropType<TableProps<any>["rowClassName"]>,
	/**
	 * @description function that returns custom style for a row, or an object assigning custom style for every row
	 */
	rowStyle: [Object, Function] as PropType<TableProps<any>["rowStyle"]>,
	/**
	 * @description function that returns custom class names for a cell, or a string assigning class names for every cell
	 */
	cellClassName: [String, Function] as PropType<TableProps<any>["cellClassName"]>,
	/**
	 * @description function that returns custom style for a cell, or an object assigning custom style for every cell
	 */
	cellStyle: [Object, Function] as PropType<TableProps<any>["cellStyle"]>,
	/**
	 * @description function that returns custom class names for a row in table header, or a string assigning class names for every row in table header
	 */
	headerRowClassName: [String, Function] as PropType<TableProps<any>["headerRowClassName"]>,
	/**
	 * @description function that returns custom style for a row in table header, or an object assigning custom style for every row in table header
	 */
	headerRowStyle: [Object, Function] as PropType<TableProps<any>["headerRowStyle"]>,
	/**
	 * @description function that returns custom class names for a cell in table header, or a string assigning class names for every cell in table header
	 */
	headerCellClassName: [String, Function] as PropType<TableProps<any>["headerCellClassName"]>,
	/**
	 * @description function that returns custom style for a cell in table header, or an object assigning custom style for every cell in table header
	 */
	headerCellStyle: [Object, Function] as PropType<TableProps<any>["headerCellStyle"]>,
	/**
	 * @description whether current row is highlighted
	 */
	highlightCurrentRow: Boolean,
	/**
	 * @description key of current row, a set only prop
	 */
	currentRowKey: [String, Number],
	/**
	 * @description displayed text when data is empty. You can customize this area with `#empty`
	 */
	emptyText: String,
	/**
	 * @description set expanded rows by this prop, prop's value is the keys of expand rows, you should set row-key before using this prop
	 */
	expandRowKeys: Array as PropType<TableProps<any>["expandRowKeys"]>,
	/**
	 * @description whether expand all rows by default, works when the table has a column type="expand" or contains tree structure data
	 */
	defaultExpandAll: Boolean,
	/**
	 * @description set the default sort column and order. property `prop` is used to set default sort column, property `order` is used to set default sort order
	 */
	defaultSort: Object as PropType<TableProps<any>["defaultSort"]>,
	/**
	 * @description the `effect` of the overflow tooltip
	 */
	tooltipEffect: String,
	/**
	 * @description the options for the overflow tooltip, [see the following tooltip component](tooltip.html#attributes)
	 */
	tooltipOptions: Object as PropType<TableProps<any>["tooltipOptions"]>,
	/**
	 * @description method that returns rowspan and colspan
	 */
	spanMethod: Function as PropType<TableProps<any>["spanMethod"]>,
	/**
	 * @description controls the behavior of master checkbox in multi-select tables when only some rows are selected (but not all). If true, all rows will be selected, else deselected
	 */
	selectOnIndeterminate: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description horizontal indentation of tree data
	 */
	indent: {
		type: Number,
		default: 16,
	},
	/**
	 * @description configuration for rendering nested data
	 */
	treeProps: {
		type: Object as PropType<TableProps<any>["treeProps"]>,
		default: (): TableProps<any>["treeProps"] => {
			return {
				hasChildren: "hasChildren",
				children: "children",
			};
		},
	},
	/**
	 * @description whether to lazy loading data
	 */
	lazy: Boolean,
	/**
	 * @description method for loading child row data, only works when `lazy` is true
	 */
	load: Function as PropType<TableProps<any>["load"]>,
	style: {
		type: Object as PropType<CSSProperties>,
		default: (): CSSProperties => ({}),
	},
	className: {
		type: String,
		default: "",
	},
	/**
	 * @description sets the algorithm used to lay out table cells, rows, and columns
	 */
	tableLayout: {
		type: String as PropType<"fixed" | "auto">,
		default: "fixed",
	},
	/**
	 * @description always show scrollbar
	 */
	scrollbarAlwaysOn: Boolean,
	/**
	 * @description ensure main axis minimum-size doesn't follow the content
	 */
	flexible: Boolean,
	/**
	 * @description whether to hide extra content and show them in a tooltip when hovering on the cell.It will affect all the table columns
	 */
	showOverflowTooltip: [Boolean, Object] as PropType<TableProps<any>["showOverflowTooltip"]>,
};

// import tableColumnProps from "element-plus/es/components/table/src/table-column/defaults";
export const tableColumnProps = {
	/**
	 * @description type of the column. If set to `selection`, the column will display checkbox. If set to `index`, the column will display index of the row (staring from 1). If set to `expand`, the column will display expand icon
	 */
	type: {
		type: String,
		default: "default",
	},
	/**
	 * @description column label
	 */
	label: String,
	/**
	 * @description class name of cells in the column
	 */
	className: String,
	/**
	 * @description class name of the label of this column
	 */
	labelClassName: String,
	/**
	 * @description
	 */
	property: String,
	/**
	 * @description field name. You can also use its alias: `property`
	 */
	prop: String,
	/**
	 * @description column width
	 */
	width: {
		type: [String, Number],
		default: "",
	},
	/**
	 * @description column minimum width. Columns with `width` has a fixed width, while columns with `min-width` has a width that is distributed in proportion
	 */
	minWidth: {
		type: [String, Number],
		default: "",
	},
	/**
	 * @description render function for table header of this column
	 */
	renderHeader: Function as PropType<TableColumnCtx<any>["renderHeader"]>,
	/**
	 * @description whether column can be sorted. Remote sorting can be done by setting this attribute to 'custom' and listening to the `sort-change` event of Table
	 */
	sortable: {
		type: [Boolean, String],
		default: false,
	},
	/**
	 * @description sorting method, works when `sortable` is `true`. Should return a number, just like Array.sort
	 */
	sortMethod: Function as PropType<TableColumnCtx<any>["sortMethod"]>,
	/**
	 * @description specify which property to sort by, works when `sortable` is `true` and `sort-method` is `undefined`. If set to an Array, the column will sequentially sort by the next property if the previous one is equal
	 */
	sortBy: [String, Function, Array] as PropType<TableColumnCtx<any>["sortBy"]>,
	/**
	 * @description whether column width can be resized, works when `border` of `el-table` is `true`
	 */
	resizable: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description column's key. If you need to use the filter-change event, you need this attribute to identify which column is being filtered
	 */
	columnKey: String,
	/**
	 * @description alignment, the value should be 'left' \/ 'center' \/ 'right'
	 */
	align: String,
	/**
	 * @description alignment of the table header. If omitted, the value of the above `align` attribute will be applied, the value should be 'left' \/ 'center' \/ 'right'
	 */
	headerAlign: String,
	/**
	 * @description whether to hide extra content and show them in a tooltip when hovering on the cell
	 */
	showOverflowTooltip: {
		type: [Boolean, Object] as PropType<TableColumnCtx<any>["showOverflowTooltip"]>,
		default: undefined,
	},
	/**
	 * @description whether column is fixed at left / right. Will be fixed at left if `true`
	 */
	fixed: [Boolean, String],
	/**
	 * @description function that formats cell content
	 */
	formatter: Function as PropType<TableColumnCtx<any>["formatter"]>,
	/**
	 * @description function that determines if a certain row can be selected, works when `type` is 'selection'
	 */
	selectable: Function as PropType<TableColumnCtx<any>["selectable"]>,
	/**
	 * @description whether to reserve selection after data refreshing, works when `type` is 'selection'. Note that `row-key` is required for this to work
	 */
	reserveSelection: Boolean,
	/**
	 * @description data filtering method. If `filter-multiple` is on, this method will be called multiple times for each row, and a row will display if one of the calls returns `true`
	 */
	filterMethod: Function as PropType<TableColumnCtx<any>["filterMethod"]>,
	/**
	 * @description filter value for selected data, might be useful when table header is rendered with `render-header`
	 */
	filteredValue: Array as PropType<TableColumnCtx<any>["filteredValue"]>,
	/**
	 * @description an array of data filtering options. For each element in this array, `text` and `value` are required
	 */
	filters: Array as PropType<TableColumnCtx<any>["filters"]>,
	/**
	 * @description placement for the filter dropdown
	 */
	filterPlacement: String,
	/**
	 * @description whether data filtering supports multiple options
	 */
	filterMultiple: {
		type: Boolean,
		default: true,
	},
	/**
	 * @description className for the filter dropdown
	 */
	filterClassName: String,
	/**
	 * @description customize indices for each row, works on columns with `type=index`
	 */
	index: [Number, Function] as PropType<TableColumnCtx<any>["index"]>,
	/**
	 * @description the order of the sorting strategies used when sorting the data, works when `sortable` is `true`. Accepts an array, as the user clicks on the header, the column is sorted in order of the elements in the array
	 */
	sortOrders: {
		type: Array as PropType<TableColumnCtx<any>["sortOrders"]>,
		default: (): TableColumnCtx<any>["sortOrders"] => {
			return ["ascending", "descending", null];
		},
		validator: (val: TableColumnCtx<unknown>["sortOrders"]): boolean => {
			return val.every((order: string) => ["ascending", "descending", null].includes(order));
		},
	},
};

export const gTableProps = {
	...tableProps,
	/**
	 * 是否带有纵向边框 ==> 非必传（默认为true）
	 */
	border: {
		type: Boolean,
		default: true,
	},
	/**
	 * 行数据的 Key，用来优化 Table 的渲染，当表格数据多选时，所指定的 id ==> 非必传（默认为 id）
	 */
	rowKey: {
		type: String,
		default: "id",
	},
	/**
	 * 表格数据
	 */
	data: {
		type: Array as PropType<anyObj[]>,
		default: [],
	},
	/**
	 * 表格Key
	 */
	tableKey: {
		type: String,
		required: true,
	},
	/**
	 * 初始化请求参数 ==> 非必传（默认为{}）
	 */
	initParam: {
		type: Object as PropType<anyObj>,
		default: {},
	},
	/**
	 * 请求表格数据的api
	 */
	requestApi: {
		type: Function as PropType<(param?: PagedInput) => ApiPromise<PagedResult<anyObj>> | ApiPromise<Array<anyObj>>>,
	},
	/**
	 * 返回数据的回调函数，可以对数据进行处理 ==> 非必传
	 */
	dataCallback: {
		type: Function as PropType<(data: anyObj) => void>,
	},
	/**
	 * 列配置项
	 */
	columns: {
		type: [Array, Boolean] as PropType<GTableColumnCtx[] | false>,
		default: false,
	},
	/**
	 * 是否需要分页组件 ==> 非必传（默认为true）
	 */
	pagination: {
		type: Boolean,
		default: true,
	},
	/**
	 * 搜索列配置
	 */
	searchFormSize: {
		type: Object as PropType<number | Record<GLayoutGridBreakPoint, number>>,
		default: (): number | Record<GLayoutGridBreakPoint, number> => ({ xs: 3, sm: 3, md: 4, lg: 5, xl: 6 }),
	},
	/**
	 * 显示搜索表单
	 */
	searchForm: {
		type: Boolean,
		default: true,
	},
	/**
	 * 显示表格头部区域
	 */
	headerCard: {
		type: Boolean,
		default: true,
	},
	/**
	 * 显示刷新按钮
	 */
	refreshBtn: {
		type: Boolean,
		default: true,
	},
	/**
	 * 显示搜索按钮
	 */
	searchBtn: {
		type: Boolean,
		default: true,
	},
	/**
	 * 显示导出Excel按钮
	 */
	exportExcelBtn: {
		type: Boolean,
		default: true,
	},
	/**
	 * 导出Excel方法点击
	 * 优先级最高
	 */
	exportExcelFn: {
		type: Function as PropType<(data: GTableExportExcelInput, response: AxiosResponse) => Promise<void>>,
	},
	/**
	 * 是否显示表格功能按钮 ==> 非必传（默认为true）
	 */
	toolBtn: {
		type: Boolean,
		default: true,
	},
	/**
	 * 是否为树形数据
	 */
	treeData: Boolean,
	/**
	 * 树形节点名称，默认 children
	 */
	treeChildrenName: {
		type: String,
		default: "children",
	},
	/**
	 * 单选
	 */
	single: Boolean,
	/**
	 * 打印类型
	 */
	printType: Number,
	/**
	 * 隐藏搜索时间
	 */
	hideSearchTime: Boolean,
	/**
	 * 默认列合并的字段，如果不传，则默认不合并
	 */
	spanProp: String,
};

export const gTableEmits = {
	/**
	 * 表单重置事件
	 */
	tableReset: (): boolean => true,
	/**
	 * 选中改变事件
	 */
	selectionChange: (selectedList: anyObj[]): boolean => true,
	/**
	 * 排序更改事件
	 */
	sortChange: ({ column, prop, order }: { column: GTableColumnCtx; prop: string; order: string }): boolean => true,
	/**
	 *页面大小改变事件
	 */
	sizeChange: (pageSize: number): boolean => true,
	/**
	 * 分页改变事件
	 */
	paginationChange: (pageIndex: number, pageSize: number): boolean => true,
	/**
	 * 数据改变事件
	 */
	dataChange: (param: anyObj, pagedData: anyObj[], orgPagedData: anyObj[]): boolean => true,
	/**
	 * 自定义单元格点击事件
	 */
	customCellClick: (emitName: string, { row, $index }: { row: anyObj; $index: number }): boolean => true,
};

export const gTableSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GTableStates): VNode[] => [],
	/**
	 * 顶部Header插槽
	 */
	topHeader: (states: GTableStates): VNode[] => [],
	/**
	 * 表格头部Header插槽
	 */
	header: (states: GTableStates): VNode[] => [],
	/**
	 * 工具按钮插槽
	 */
	toolButton: (states: GTableStates): VNode[] => [],
	/**
	 * 工具按钮高级选项插槽
	 */
	toolButtonAdv: (states: GTableStates): VNode[] => [],
	/**
	 * 插入至表格最后一行之后的内容， 如果需要对表格的内容进行无限滚动操作，可能需要用到这个 slot。 若表格有合计行，该 slot 会位于合计行之上。
	 */
	append: (states: GTableStates): VNode[] => [],
	/**
	 * 当数据为空时自定义的内容
	 */
	empty: (states: GTableStates): VNode[] => [],
	/**
	 * 分页插槽
	 */
	pagination: (states: GTableStates): VNode[] => [],
	/**
	 * 尾部Footer插槽
	 */
	footer: (states: GTableStates): VNode[] => [],
	/**
	 * 操作列插槽
	 */
	operation: (states: GTableStates & { row: any; column: GTableColumnCtx; $index: number }): VNode[] => [],
};

export const gTableColumnProps = {
	/**
	 * 列配置
	 */
	column: {
		required: true,
		type: Object as PropType<GTableColumnCtx>,
		default: (): GTableColumnCtx => ({}) as GTableColumnCtx,
	},
	/**
	 * 隐藏图片
	 */
	hideImage: Boolean,
	/**
	 * 是否可以拖动列宽
	 */
	resizable: Boolean,
	/**
	 * 表格大小
	 */
	tableSize: {
		type: String as PropType<"default" | "small">,
		default: "default",
	},
};

export const gTableColumnEmits = {
	/**
	 * 图片预览
	 */
	imagePreview: (url: string): boolean => true,
	/**
	 * 自定义单元格点击事件
	 */
	customCellClick: (emitName: string, { row, $index }: { row: anyObj; $index: number }): boolean => true,
};

export const gTableColumnsSettingDialogEmits = {
	/**
	 * 列保存事件
	 */
	columnSave: (change: boolean): boolean => true,
};

export const gTableExportExcelDialogProps = {
	/**
	 * 导出Excel方法点击
	 * 优先级最高
	 */
	exportExcelFn: {
		type: Function as PropType<(data: GTableExportExcelInput, response: AxiosResponse) => Promise<void>>,
	},
	/**
	 * 初始化请求参数 ==> 非必传（默认为{}）
	 */
	initParam: {
		type: Object as PropType<anyObj>,
		default: {},
	},
};

export const gTablePaginationProps = {
	/**
	 * 页码改变
	 */
	sizeChange: {
		type: Function as PropType<(pageSize: number) => void>,
		required: true,
	},
	/**
	 * 当前页数改变
	 */
	currentChange: {
		type: Function as PropType<(currentPage: number) => void>,
		required: true,
	},
};

export const gTableSearchFormProps = {
	/**
	 * 显示
	 */
	show: {
		type: Boolean,
		required: true,
	},
	/**
	 * 搜索列配置
	 */
	size: {
		type: Object as PropType<number | Record<GLayoutGridBreakPoint, number>>,
		default: (): number | Record<GLayoutGridBreakPoint, number> => ({ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }),
	},
	/**
	 * 搜索方法
	 */
	search: Function as PropType<() => void>,
	/**
	 * 重置方法
	 */
	reset: Function as PropType<() => void>,
};

export const gTableSearchFormItemProps = {
	/**
	 * 类配置
	 */
	column: {
		type: Object as PropType<GTableColumnCtx>,
		required: true,
		default: (): GTableColumnCtx => ({}) as GTableColumnCtx,
	},
	/**
	 * 搜索方法
	 */
	search: {
		type: Function as PropType<() => void>,
		required: true,
	},
};
