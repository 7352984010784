import type { SlotsType } from "vue";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { ElTreeSelect } from "element-plus";
import type { gTreeSelectSlots } from "./define";
import { gTreeSelectEmits, gTreeSelectProps } from "./define";
import type { GTreeSelectStates } from "./type";
import { SelectProps } from "@gejia-element-plus/components/select";
import { treeProps } from "@gejia-element-plus/components/tree";
import { errorHandler, typeUtil, useProps, useRender, withTryNumber } from "@gejia-element-plus/utils";

/**
 * GTreeSelect 组件
 */
export default defineComponent({
	name: "GTreeSelect",
	components: {
		ElTreeSelect,
	},
	props: gTreeSelectProps,
	emits: gTreeSelectEmits,
	slots: Object as SlotsType<typeof gTreeSelectSlots>,
	setup(props, { attrs, slots, emit, expose }) {
		const states: GTreeSelectStates = reactive({
			value: undefined,
			label: undefined,
			loading: false,
			orgTreeData: [],
			treeData: [],
			debut: true,
			echo: props.data?.length > 0 ? false : true,
			nextRefresh: false,
		});

		const treeSelectRef = ref<InstanceType<typeof ElTreeSelect>>();

		/**
		 * 设置树形数据
		 */
		const handleTreeData = (treeData: ElTreeOutput[] | any[]): any[] => {
			return treeData.map((item) => {
				const localValue = withTryNumber(item[props.nodeKey]);
				const result = {
					...item,
					[props.nodeKey]: localValue,
					value: localValue,
					label: item[props.labelKey],
					children: [],
				};
				if (item[props.childrenKey]?.length > 0) {
					result.children = handleTreeData(item[props.childrenKey]);
				}
				return result;
			});
		};

		const loadData = async (): Promise<void> => {
			// 判断是否需要自动请求
			if (props.requestApi) {
				states.loading = true;
				const param = props.initParam ?? {};
				try {
					const apiResult = await props.requestApi(param);
					// 这里不允许回显了
					states.echo = false;
					states.orgTreeData = apiResult.data;
					states.treeData = handleTreeData(apiResult.data);
				} catch (error) {
					console.error("[gejia-GTreeSelect]", error);
					states.treeData = [];
					errorHandler(error);
				} finally {
					states.loading = false;
				}
			} else {
				states.orgTreeData = props.data;
				states.treeData = handleTreeData(props.data);
			}
			emit("dataChangeCallBack", states.treeData);
		};

		/**
		 * 树形节点过滤
		 */
		const handleFilterNode = (value, data, node): boolean => {
			if (!value) return true;
			let parentNode = node.parent,
				labels = [node.label],
				level = 1;
			while (level < node.level) {
				labels = [...labels, parentNode.label];
				parentNode = parentNode.parent;
				level++;
			}
			return labels.some((label) => label.indexOf(value) !== -1);
		};

		const handleNodeClick = (data: ElTreeOutput | any, node, treeNode, event: MouseEvent): void => {
			if (data) {
				if (typeUtil.isEqual(props.modelValue, data[props.nodeKey])) return;
				states.value = data[props.nodeKey];
				states.label = data[props.labelKey];
				emit("update:modelValue", states.value);
				emit("update:label", states.label);
				emit("change", data);
			} else {
				states.value = null;
				states.label = null;
				emit("update:modelValue", null);
				emit("update:label", null);
				emit("change", null);
			}
			emit("nodeClick", data, node, treeNode, event);
		};

		/**
		 * 下拉框出现/隐藏时触发
		 */
		const handleVisibleChange = async (visible: boolean): Promise<void> => {
			if (visible) {
				if (states.debut) {
					// 首次出现
					states.debut = false;
					// 懒加载
					props.lazy && (await loadData());
				} else {
					// 判断再次出现是否需要刷新数据
					if (states.nextRefresh) {
						states.nextRefresh = false;
						await loadData();
					}
				}
			}
			emit("visibleChange", visible);
		};

		/**
		 * 监听初始化参数是否改变
		 * 为了解决改变初始化参数不能刷新数据的问题
		 */
		watch(
			() => props.initParam,
			(newValue, oldValue) => {
				if (!typeUtil.isEqual(newValue, oldValue)) {
					states.nextRefresh = true;
					states.value = null;
					states.label = null;
					emit("update:modelValue", null);
					emit("update:label", null);
					emit("change", null);
				}
			}
		);

		/**
		 * 监听 v-model 绑定数据
		 * 为了解决懒加载存在回显值的问题
		 */
		watch(
			() => props.modelValue,
			(newValue) => {
				if (states.echo && newValue != undefined && newValue != null) {
					states.treeData = [
						{
							[props.nodeKey]: withTryNumber(newValue),
							[props.labelKey]: props.label,
						},
					];
					states.value = withTryNumber(newValue);
				} else {
					states.value = withTryNumber(newValue);
				}
			},
			{
				immediate: true,
			}
		);

		onMounted(async () => {
			await loadData();
		});

		const bindProps = useProps(props, { ...SelectProps, ...treeProps }, ["modelValue", "loading", "filterNodeMethod"], {
			popperClass: "g-tree-select-dropdown",
			loadingText: "加载中...",
			noMatchText: "暂无匹配的数据",
			noDataText: "暂无数据",
			collapseTags: true,
			collapseTagsTooltip: true,
			defaultExpandAll: true,
			highlightCurrent: true,
			expandOnClickNode: false,
			checkOnClickNode: true,
		});

		useRender(() => (
			<ElTreeSelect
				{...attrs}
				{...bindProps.value}
				ref={treeSelectRef}
				class="g-tree-select"
				style={{ width: props.width }}
				vModel={states.value}
				loading={states.loading}
				data={states.treeData}
				filterNodeMethod={handleFilterNode}
				onNodeClick={handleNodeClick}
				onVisibleChange={handleVisibleChange}
			/>
		));

		expose({
			states,
			refresh: loadData,
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			refresh: loadData,
		};
	},
});
