import { computed, defineComponent, inject, reactive, ref } from "vue";
import { ElInputNumber } from "element-plus";
import { useRoute } from "vue-router";
import { gTableExportExcelDialogProps } from "./define";
import type { GTableExportExcelDialogStates, GTableExportExcelInput } from "./type";
import type { GDialogInstance } from "@gejia-element-plus/components/dialog";
import { GDialog } from "@gejia-element-plus/components/dialog";
import type { GFormInstance } from "@gejia-element-plus/components/form";
import { GForm, GFormItem } from "@gejia-element-plus/components/form";
import type { GTableColumnCtx, GTableStates } from "@gejia-element-plus/components/table";
import { pinia, useGejiaApp } from "@gejia-element-plus/stores";
import { axiosUtil, typeUtil, useRender } from "@gejia-element-plus/utils";

/**
 * GTableExportExcelDialog 组件
 */
export default defineComponent({
	name: "GTableExportExcelDialog",
	components: {
		// GDialog,
		// GForm,
		// GFormItem,
		ElInputNumber,
	},
	props: gTableExportExcelDialogProps,
	setup(props, { expose }) {
		const gejiaAppStore = useGejiaApp(pinia);

		const tableStates = inject<GTableStates>("tableStates");

		const states: GTableExportExcelDialogStates = reactive({
			title: "",
			formRules: {
				pageIndex: [{ required: true, message: "请输入导出页码", trigger: "blur" }],
				pageSize: [{ required: true, message: "请输入导出行数", trigger: "blur" }],
				columnsIndex: [{ required: true, message: "请选择导出的列", trigger: "change" }],
			},
			formData: {
				pageIndex: 1,
				pageSize: 1000,
			},
			columns: computed(() => tableStates.tableColumns.filter((f) => f.prop && f.type != "expand")),
			maxPageIndex: 1,
		});

		const route = useRoute();

		const gDialogRef = ref<GDialogInstance>();
		const gFormRef = ref<GFormInstance>();

		const enumMap = inject("enumMap", ref(new Map()));
		const tableKey = inject<string>("tableKey");

		const handlePageSizeChange = (value: number): void => {
			states.formData.pageIndex = 1;
			if (tableStates.tablePagination.totalRows === 0) {
				states.maxPageIndex = 0;
			} else {
				states.maxPageIndex = Math.ceil(tableStates.tablePagination.totalRows / value);
			}
		};

		const open = (): void => {
			states.title = `导出Excel选项（共 ${tableStates.tablePagination.totalRows} 条）`;
			gDialogRef.value.open(async () => {
				handlePageSizeChange(states.formData.pageSize);
			});
		};

		const handleExportExcel = (): void => {
			gFormRef.value.validateScrollToField().then(async () => {
				const requestData: GTableExportExcelInput = {
					tableKey,
					pageTitle: route.meta.title,
					searchParam: {
						...props.initParam,
						...tableStates.searchParam,
						pageIndex: states.formData.pageIndex,
						pageSize: states.formData.pageSize,
					},
					apiUrl: tableStates.response.config.url,
					method: tableStates.response.config.method.toUpperCase(),
					columns: [],
				};

				states.columns.forEach((col: GTableColumnCtx, index: number) => {
					let enumData;
					if (typeUtil.isString(col.enum)) {
						enumData = enumMap.value.get(col.enum);
					} else {
						enumData = col.enum;
					}
					requestData.columns.push({
						prop: col.prop,
						label: col.label,
						dateFormat: col.dateFormat,
						enum: enumData,
					});
				});

				// 判断是否传入了导出Excel方法
				if (props.exportExcelFn) {
					await props.exportExcelFn(requestData, tableStates.response);
				}
				// 判断全局的方法
				else if (gejiaAppStore.states.tableConfig.exportExcelFn) {
					await gejiaAppStore.states.tableConfig.exportExcelFn(tableKey, requestData, tableStates.response);
				} else {
					await axiosUtil.request({
						url: gejiaAppStore.states.tableConfig.exportExcelUrl,
						method: "post",
						data: requestData,
						responseType: "blob",
						requestType: "export",
						autoDownloadFile: true,
						loading: true,
					});
				}
			});
		};

		useRender(() => (
			<GDialog
				ref={gDialogRef}
				class="g-table__export-excel-dialog"
				title={states.title}
				onConfirmClick={handleExportExcel}
				showFullscreen={false}
				closeButtonText="关闭"
				confirmButtonText="导出"
				width="400px"
			>
				<GForm ref={gFormRef} model={states.formData} rules={states.formRules}>
					<GFormItem label="行数" prop="pageSize">
						<ElInputNumber
							vModel={states.formData.pageSize}
							min={100}
							max={
								tableStates.tablePagination.totalRows >= 10000
									? 10000
									: tableStates.tablePagination.totalRows < 100
										? 100
										: tableStates.tablePagination.totalRows
							}
							onChange={handlePageSizeChange}
						/>
					</GFormItem>
					<GFormItem label="页码" prop="pageIndex">
						<ElInputNumber vModel={states.formData.pageIndex} min={1} max={states.maxPageIndex} />
					</GFormItem>
				</GForm>
			</GDialog>
		));

		expose({
			states,
			open,
		});

		return {
			states,
			open,
		};
	},
});
