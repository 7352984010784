import type { PropType, VNode } from "vue";
import { formItemProps, formProps } from "element-plus";
import type { GFormStates } from "./type";
import type { GLayoutGridBreakPoint } from "@gejia-element-plus/components/layoutGrid";

export const gFormProps = {
	...formProps,
	/**
	 * 标签的长度，例如 '50px'。 作为 Form 直接子元素的 form-item 会继承该值。 可以使用 auto。
	 */
	labelWidth: {
		type: [String, Number] as PropType<string | number>,
		// default: "150px",
		default: "auto",
	},
	/**
	 * 表单域标签的后缀
	 */
	labelSuffix: {
		type: String,
		default: "：",
	},
	/**
	 * 当校验失败时，滚动到第一个错误表单项
	 */
	scrollToError: {
		type: Boolean,
		default: true,
	},
	/**
	 * 详情From
	 * @description 会删除 FormItem 的 padding-bottom
	 */
	detailForm: Boolean,
	/**
	 * Grid 布局
	 */
	grid: {
		type: Boolean,
		default: true,
	},
	/**
	 * 列
	 * @default { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }
	 */
	cols: {
		type: [Number, String, Object] as PropType<number | string | Record<GLayoutGridBreakPoint, number>>,
		default: (): number | string | Record<GLayoutGridBreakPoint, number> => ({ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }),
	},
};

export const gFormSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GFormStates): VNode[] => [],
};

export const gFormItemProps = {
	...formItemProps,
	/**
	 * Label tips 提示
	 */
	tips: String,
	/**
	 * Grid 布局
	 */
	grid: {
		type: Boolean,
		default: true,
	},
	offset: { type: Number, default: 0 },
	span: [Number, String],
	/**
	 * 独占一行
	 * @description 如果设置 span 则无效
	 */
	row: Boolean,
};

export const gFormItemSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (): VNode[] => [],
	/**
	 * 标签位置显示的内容
	 */
	label: ({ label: string }): VNode[] => [],
	/**
	 * 验证错误信息的显示内容
	 */
	error: ({ error: string }): VNode[] => [],
};
