import type { VNode } from "vue";
import { avatarProps } from "element-plus";
import type { GAvatarStates } from "./type";

export const gAvatarProps = {
	...avatarProps,
	/**
	 * 原图
	 */
	original: Boolean,
	/**
	 * 标准
	 */
	normal: Boolean,
	/**
	 * 小图
	 */
	small: Boolean,
	/**
	 * 缩略图
	 */
	thumb: Boolean,
};

export const gAvatarSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GAvatarStates): VNode[] => [],
};
