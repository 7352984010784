import type { ExtractPropTypes } from "vue";
import type { gLayoutGridEmits, gLayoutGridItemProps, gLayoutGridProps } from "./src/define";
import LayoutGrid from "./src/layoutGrid";
import LayoutGridItem from "./src/layoutGridItem";
import { withInstall, withNoopInstall } from "@gejia-element-plus/utils";

export const GLayoutGrid = withInstall(LayoutGrid, {
	LayoutGridItem,
});
export default GLayoutGrid;

export const GLayoutGridItem = withNoopInstall(LayoutGridItem);

export * from "./src/define";
export * from "./src/type";

export type GLayoutGridInstance = InstanceType<typeof LayoutGrid>;

export type LayoutGridProps = ExtractPropTypes<typeof gLayoutGridProps>;

export type LayoutGridEmits = typeof gLayoutGridEmits;

export type GLayoutGridItemInstance = InstanceType<typeof LayoutGridItem>;

export type GLayoutGridItemProps = ExtractPropTypes<typeof gLayoutGridItemProps>;
