import { nextTick } from "vue";
import { AxiosError } from "axios";
import { ElNotification } from "element-plus";

/**
 * 全局异常捕获
 */
export const errorHandler = (error: any): boolean => {
	if (!error) return;
	// 过滤 Axios 错误
	if (error instanceof AxiosError) {
		console.error("[gejia-Axios]", error);
		return false;
	}
	const errorMap: any = {
		InternalError: "Javascript引擎内部错误",
		ReferenceError: "未找到对象",
		TypeError: "使用了错误的类型或对象",
		RangeError: "使用内置对象时，参数超范围",
		SyntaxError: "语法错误",
		EvalError: "错误的使用了Eval",
		URIError: "URI错误",
	};
	if (error === "cancel") {
		console.warn("[gejia-Warning]", "操作已取消");
	} else {
		const errorName = errorMap[error.name] || "未知错误";
		console.error("[gejia-Error]", error);
		nextTick(() => {
			ElNotification({
				title: "错误",
				message: errorName,
				duration: 3000,
				position: "top-right",
			});
		});
	}
};
