import type { ExtractPropTypes } from "vue";
import type { gFormItemProps, gFormProps } from "./src/define";
import Form from "./src/form";
import FormItem from "./src/formItem";
import { withInstall, withNoopInstall } from "@gejia-element-plus/utils";

export const GForm = withInstall(Form, {
	FormItem,
});
export default GForm;

export const GFormItem = withNoopInstall(FormItem);

export * from "./src/define";
export * from "./src/type";

export type GFormInstance = InstanceType<typeof Form>;

export type GFormProps = ExtractPropTypes<typeof gFormProps>;

export type GFormItemInstance = InstanceType<typeof FormItem>;

export type GFormItemProps = ExtractPropTypes<typeof gFormItemProps>;
