import type { ExtractPropTypes } from "vue";
import type { gTreeSelectEmits, gTreeSelectProps } from "./src/define";
import TreeSelect from "./src/treeSelect";
import { withInstall } from "@gejia-element-plus/utils";

export const GTreeSelect = withInstall(TreeSelect);
export default GTreeSelect;

export * from "./src/define";
export * from "./src/type";

export type GTreeSelectInstance = InstanceType<typeof TreeSelect>;

export type GTreeSelectProps = ExtractPropTypes<typeof gTreeSelectProps>;

export type GTreeSelectEmits = typeof gTreeSelectEmits;
