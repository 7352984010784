import type { ExtractPropTypes } from "vue";
import LayoutClassic from "./src/classic";
import type { gLayoutClassicProps, gLayoutClassicSlots } from "./src/define";
import { withInstall } from "@gejia-element-plus/utils";

export const GLayoutClassic = withInstall(LayoutClassic);
export default GLayoutClassic;

export * from "./src/define";
export * from "./src/type";

export type GLayoutClassicInstance = InstanceType<typeof LayoutClassic>;

export type GLayoutClassicProps = ExtractPropTypes<typeof gLayoutClassicProps>;

export type GLayoutClassicSlots = typeof gLayoutClassicSlots;
