import type { SlotsType } from "vue";
import { computed, defineComponent, inject, reactive, ref, watch } from "vue";
import { Delete, Edit, Plus, ZoomIn } from "@element-plus/icons-vue";
import { Decimal } from "decimal.js";
import type { UploadFile, UploadFiles, UploadInstance, UploadProps, UploadRequestOptions, UploadUserFile } from "element-plus";
import { ElIcon, ElImageViewer, ElMessage, ElNotification, ElUpload, formContextKey, formItemContextKey, genFileId, uploadProps } from "element-plus";
import type { gUploadImagesSlots } from "./define";
import { gUploadImagesEmits, gUploadImagesProps } from "./define";
import type { GUploadImagesStates } from "./type";
import { useUpload } from "@gejia-element-plus/components/upload/src/useUpload";
import { GMimeType } from "@gejia-element-plus/constants";
import { stringUtil, uploadUtil, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GUploadImages 组件
 */
export default defineComponent({
	name: "GUploadImages",
	components: {
		ElUpload,
		ElImageViewer,
		ElIcon,
		Edit,
		ZoomIn,
		Delete,
		Plus,
	},
	props: gUploadImagesProps,
	emits: gUploadImagesEmits,
	slots: Object as SlotsType<typeof gUploadImagesSlots>,
	setup(props, { attrs, slots, emit, expose }) {
		// 获取 el-form 组件上下文
		const formContext = inject(formContextKey, undefined);
		// 获取 el-form-item 组件上下文
		const formItemContext = inject(formItemContextKey, undefined);

		const states: GUploadImagesStates = reactive({
			uploadKey: `g-upload-images__${stringUtil.generateRandomString(8)}`,
			loading: false,
			disabled: computed(() => {
				return props.disabled || formContext?.disabled;
			}),
			preview: false,
			fileList: [],
			previewIndex: -1,
			previewList: [],
		});

		const uploadRef = ref<UploadInstance>();

		const handleValue = (): void => {
			if (states.fileList.length > 0) {
				const value = states.fileList.map((m) => m.url);
				emit("update:modelValue", value);
				emit("change", value);
			} else {
				emit("update:modelValue", null);
				emit("change", null);
			}
		};

		const handleEdit = (): void => {
			const uploadInputEl = document.querySelector(`.${states.uploadKey} .el-upload__input`);
			uploadInputEl && uploadInputEl.dispatchEvent(new MouseEvent("click"));
		};

		const handlePreview = (uploadFile: UploadFile): void => {
			states.previewIndex = states.fileList.findIndex((f) => f.url === uploadFile.url);
			states.previewList = states.fileList.map((m) => m.url);
			states.preview = true;
		};

		const handleRemove = (uploadFile: UploadFile): void => {
			const fIdx = states.fileList.findIndex((f) => f.url === uploadFile.url);
			if (fIdx !== -1) {
				states.fileList.splice(fIdx, 1);
			}
			handleValue();
		};

		const handleBeforeUpload: UploadProps["beforeUpload"] = (rawFile) => {
			const fileSizeKB = new Decimal(rawFile.size).div(new Decimal(1024));
			if (fileSizeKB.greaterThan(new Decimal(props.maxSize))) {
				const maxSizeMB = new Decimal(props.maxSize).div(new Decimal(1024));
				console.warn("[gejia-GUploadImages]", `【${rawFile.name}】图片上传大小不能超过 ${maxSizeMB.toString()}MB`);
				ElMessage.warning(`【${rawFile.name}】图片上传大小不能超过 ${maxSizeMB.toString()}MB`);
				return false;
			}
			if (props.accept && props.accept.split(",").every((e) => e !== rawFile.type)) {
				const uploadFileNames = uploadUtil.detectFileType(props.accept);
				ElMessage.error(`只允许上传【${uploadFileNames}】类型的图片`);
				console.error("[gejia-GUploadImages]", `只允许上传【${uploadFileNames}】类型的图片`);
				return false;
			}
			if (props.beforeUpload) {
				return props.beforeUpload(rawFile);
			}
			return true;
		};

		const handleHttpRequest = async (options: UploadRequestOptions): Promise<void> => {
			if (!props.fileType) {
				ElMessage.error("上传文件类型不能为空");
				console.error("[gejia-GUploadImages]", "上传文件类型 “fileType” 不能为空");
				return;
			}
			let propsData: anyObj;
			if (props.data) {
				propsData = uploadUtil.getPropsData(options.file, props.data);
			}
			states.loading = true;
			try {
				const fileUrl = await uploadUtil.uploadFile(options.file, options.filename, props.fileType, propsData);
				options.onSuccess(fileUrl);
			} finally {
				states.loading = false;
			}
		};

		const handleOnSuccess = (fileUrl: string, uploadFile: UploadFile, uploadFiles: UploadFiles): void => {
			if (!fileUrl) return;
			uploadFile.url = fileUrl;
			handleValue();
			// 调用 el-form 内部的校验方法（可自动校验）
			formItemContext?.prop && formContext?.validateField([formItemContext.prop as string]);
			ElMessage.success("上传成功");
			props.onSuccess && props.onSuccess(fileUrl, uploadFile, uploadFiles);
		};

		const handleOnError = (error: Error, uploadFile: UploadFile, uploadFiles: UploadFiles): void => {
			ElNotification({
				message: `【${uploadFile.name}】图片上传失败，请您重新上传`,
				type: "error",
			});
			props.onError && props.onError(error, uploadFile, uploadFiles);
		};

		const handleOnExceed = (files: File[], uploadFiles: UploadUserFile[]): void => {
			ElMessage.warning(`最多只能上传 ${props.limit} 个图片，请移除后再进行上传`);
			props.onExceed && props.onExceed(files, uploadFiles);
		};

		watch(
			() => states.fileList,
			(newValue) => {
				emit("update:fileList", newValue);
			}
		);

		/**
		 * 监听 v-model 绑定数据
		 */
		watch(
			() => props.modelValue,
			(newValue) => {
				states.fileList = [];
				if (newValue?.length > 0) {
					newValue.forEach((item) => {
						states.fileList.push({
							name: "",
							status: "success",
							uid: genFileId(),
							url: item,
						});
					});
				}
			},
			{
				immediate: true,
			}
		);

		const bindProps = useProps(props, uploadProps, ["fileList", "disabled", "httpRequest", "beforeUpload", "onExceed", "onSuccess", "onError"], {
			autoUpload: true,
			showFileList: true,
			drag: false,
			multiple: true,
			listType: "picture-card",
			accept: GMimeType.Image,
		});

		useRender(() => (
			<>
				<ElUpload
					{...attrs}
					{...bindProps.value}
					ref={uploadRef}
					class={["g-upload-images", states.uploadKey, states.fileList.length >= props.limit ? "g-upload-images__hidden-upload" : ""]}
					vLoading={states.loading}
					vModel:fileList={states.fileList}
					disabled={states.disabled}
					httpRequest={handleHttpRequest}
					beforeUpload={handleBeforeUpload}
					onExceed={handleOnExceed}
					onSuccess={handleOnSuccess}
					onError={handleOnError}
				>
					{{
						default: () =>
							states.fileList.length >= props.limit ? null : slots.empty ? (
								slots.empty(states)
							) : (
								<ElIcon>
									<Plus />
								</ElIcon>
							),
						tip: () => (
							<div class="el-upload__tip">
								files with a size less than {new Decimal(props.maxSize).div(new Decimal(1024)).toString()}MB
							</div>
						),
						file: ({ file }: { file: UploadFile }) => (
							<div>
								<img class="el-upload-list__item-thumbnail" src={file.url} />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" onClick={() => handlePreview(file)} title="查看">
										<ElIcon>
											<ZoomIn />
										</ElIcon>
									</span>
									{states.disabled ? null : (
										<>
											<span class="el-upload-list__item-delete" onClick={handleEdit} title="编辑">
												<ElIcon>
													<Edit />
												</ElIcon>
											</span>
											<span class="el-upload-list__item-delete" onClick={() => handleRemove(file)} title="删除">
												<ElIcon>
													<Delete />
												</ElIcon>
											</span>
										</>
									)}
								</span>
							</div>
						),
					}}
				</ElUpload>
				{states.preview ? (
					<ElImageViewer
						closeOnPressEscape
						hideOnClickModal
						teleported
						onClose={() => (states.preview = false)}
						urlList={states.previewList}
					/>
				) : null}
			</>
		));

		expose({
			states,
			...useUpload(uploadRef),
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			...useUpload(uploadRef),
		};
	},
});
