import type { PropType, VNode } from "vue";
import type { GTreeSelectStates } from "./type";
import { SelectProps } from "@gejia-element-plus/components/select";
import { treeProps } from "@gejia-element-plus/components/tree";

export const gTreeSelectProps = {
	...SelectProps,
	...treeProps,
	/**
	 * 懒加载节点的缓存数据，结构与数据相同，用于获取未加载数据的标签
	 * @description The cached data of the lazy node, the structure is the same as the data, used to get the label of the unloaded data
	 */
	cacheData: {
		type: Array,
		default: (): [] => [],
	},
	/**
	 * 树形数据数据
	 */
	data: {
		type: Array as PropType<ElTreeOutput[] | any[]>,
		default: [],
	},
	/**
	 * v-model绑定值
	 */
	modelValue: [Number, String, Boolean, Object, Array] as PropType<number | string | boolean | object | any[]>,
	/**
	 * 请求树形数据的api
	 */
	requestApi: {
		type: Function as PropType<(params?: any) => ApiPromise<ElTreeOutput[] | any[]>>,
	},
	/**
	 * v-model:label 绑定值
	 * 选项显示的值
	 */
	label: String,
	/**
	 * Label Key，默认 label
	 */
	labelKey: {
		type: String,
		default: "label",
	},
	/**
	 * disabled Key，默认 disabled
	 */
	disabledKey: {
		type: String,
		default: "disabled",
	},
	/**
	 * children Key，默认 children
	 */
	childrenKey: {
		type: String,
		default: "children",
	},
	/**
	 * 宽度
	 */
	width: {
		type: String,
		default: "100%",
	},
	/**
	 * 默认参数
	 */
	initParam: {
		type: Object as PropType<anyObj>,
		default: {},
	},
};

export const gTreeSelectEmits = {
	/**
	 * v-model 回调
	 */
	"update:modelValue": (value: number | string | boolean | object | any[]): boolean => true,
	/**
	 * v-model:label 回调
	 */
	"update:label": (value: string | number): boolean => true,
	/**
	 * 选中改变
	 */
	change: (value: ElTreeOutput | any, node?, treeNode?, event?: MouseEvent): boolean => true,
	/**
	 * 节点点击
	 */
	nodeClick: (data: ElTreeOutput | any, node, treeNode, event: MouseEvent): boolean => true,
	/**
	 * 下拉框出现/隐藏
	 */
	visibleChange: (visible: boolean): boolean => true,
	/**
	 * 树形数据改变回调
	 */
	dataChangeCallBack: (data: ElTreeOutput[] | any[]): boolean => true,
};

export const gTreeSelectSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GTreeSelectStates): VNode[] => [],
};
