import { nextTick } from "vue";

export const useLoading = {
	show: (): void => {
		// 判断是否存在加载效果
		if (!window.loading) {
			const bodyEl: Element = document.body;
			const div = document.createElement("div");
			div.className = "g-global-loading";
			div.innerHTML = `
                <div class="g-global-loading-box">
                    <div class="g-global-loading-box-warp">
                        <div class="g-global-loading-box-item"></div>
                    </div>
                </div>
            `;
			bodyEl.insertBefore(div, bodyEl.childNodes[0]);
			window.loading = true;
		}
	},
	hide: (): void => {
		// 判断是否存在加载效果
		if (window.loading) {
			nextTick(() => {
				setTimeout(() => {
					const loadingEl = document.querySelector(".g-global-loading");
					loadingEl && loadingEl.parentNode?.removeChild(loadingEl);
					window.loading = false;
				}, 500);
			});
		}
	},
};
