import type { ExtractPropTypes } from "vue";
import type { gIconSelectorProps } from "./src/define";
import IconSelector from "./src/iconSelector";
import { withInstall } from "@gejia-element-plus/utils";

export const GIconSelector = withInstall(IconSelector);
export default GIconSelector;

export * from "./src/define";
export * from "./src/type";

export type GIconSelectorInstance = InstanceType<typeof IconSelector>;

export type GIconSelectorProps = ExtractPropTypes<typeof gIconSelectorProps>;
