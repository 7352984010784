import type { Component, PropType, VNode } from "vue";
import type { GTreeStates } from "./type";

// import { treeProps } from "element-plus/es/components/tree-v2/src/virtual-tree";
export const treeProps = {
	data: {
		type: Array,
		default: (): [] => [],
	},
	emptyText: {
		type: String,
	},
	renderAfterExpand: {
		type: Boolean,
		default: true,
	},
	nodeKey: {
		type: String,
		default: "value",
	},
	checkStrictly: Boolean,
	defaultExpandAll: Boolean,
	expandOnClickNode: {
		type: Boolean,
		default: true,
	},
	checkOnClickNode: Boolean,
	checkDescendants: Boolean,
	autoExpandParent: {
		type: Boolean,
		default: true,
	},
	defaultCheckedKeys: Array,
	defaultExpandedKeys: Array,
	currentNodeKey: [String, Number] as PropType<string | number>,
	renderContent: Function,
	showCheckbox: Boolean,
	draggable: Boolean,
	allowDrag: Function,
	allowDrop: Function,
	props: {
		type: Object as PropType<{
			children?: string;
			label?: string | ((data: any, node: any) => string);
			disabled?: string | ((data: any, node: any) => boolean);
			isLeaf?: string | ((data: any, node: any) => boolean);
			class?: (
				data: any,
				node: any
			) =>
				| string
				| {
						[key: string]: boolean;
				  };
		}>,
		default: (): {
			children?: string;
			label?: string | ((data: any, node: any) => string);
			disabled?: string | ((data: any, node: any) => boolean);
			isLeaf?: string | ((data: any, node: any) => boolean);
			class?: (
				data: any,
				node: any
			) =>
				| string
				| {
						[key: string]: boolean;
				  };
		} => ({
			children: "children",
			label: "label",
			disabled: "disabled",
		}),
	},
	lazy: Boolean,
	highlightCurrent: Boolean,
	load: Function as PropType<(rootNode: any, loadedCallback: (data: any) => void) => void>,
	filterNodeMethod: Function as PropType<(value: any, data: any, child: any) => boolean>,
	accordion: Boolean,
	indent: {
		type: Number,
		default: 18,
	},
	icon: {
		type: Object as PropType<string | Component>,
	},
};

export const gTreeProps = {
	...treeProps,
	/**
	 * 树形数据数据
	 */
	data: {
		type: Array as PropType<ElTreeOutput[] | any[]>,
		default: [],
	},
	/**
	 * v-model绑定值
	 */
	modelValue: [Number, String] as PropType<number | string>,
	/**
	 * 自动请求
	 */
	requestAuto: {
		type: Boolean,
		default: true,
	},
	/**
	 * 请求树形数据的api
	 */
	requestApi: Function as PropType<(params?: any) => ApiPromise<ElTreeOutput[] | any[]>>,
	/**
	 * 标题
	 */
	title: String,
	/**
	 * v-model:label 绑定值
	 * 选项显示的值
	 */
	label: String,
	/**
	 * Label Key，默认 label
	 */
	labelKey: {
		type: String,
		default: "label",
	},
	/**
	 * disabled Key，默认 disabled
	 */
	disabledKey: {
		type: String,
		default: "disabled",
	},
	/**
	 * children Key，默认 children
	 */
	childrenKey: {
		type: String,
		default: "children",
	},
	/**
	 * 宽度，默认为 220
	 */
	width: {
		type: [Number, String] as PropType<number | string>,
		default: 230,
	},
	/**
	 * 默认参数
	 */
	initParam: {
		type: Object as PropType<anyObj>,
		default: {},
	},
	/**
	 * 默认 Value
	 */
	defaultSelected: [Number, String] as PropType<number | string>,
	/**
	 * 隐藏全部 ==> 非必传，默认为 false
	 */
	hideAll: Boolean,
	/**
	 * 隐藏过滤 ==> 非必传，默认为 false
	 */
	hideFilter: Boolean,
	/**
	 * 全部值 ==> 非必传
	 */
	allValue: {
		type: [Number, String, Boolean] as PropType<number | string | boolean>,
		default: 0,
	},
	/**
	 * 折叠
	 */
	hamburger: Boolean,
};

export const gTreeEmits = {
	/**
	 * v-model 回调
	 */
	"update:modelValue": (value: number | string): boolean => true,
	/**
	 * v-model:label 回调
	 */
	"update:label": (value: string): boolean => true,
	/**
	 * 选中改变
	 */
	change: (value: ElTreeOutput | any, node?, treeNode?, event?: MouseEvent): boolean => true,
	/**
	 * 节点点击
	 */
	nodeClick: (data: ElTreeOutput | any, node, treeNode, event: MouseEvent): boolean => true,
	/**
	 * 树形数据改变回调
	 */
	dataChangeCallBack: (data: ElTreeOutput[] | any[], orgData: ElTreeOutput[] | any[]): boolean => true,
};

export const gTreeSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GTreeStates & { node: any; data: any }): VNode[] => [],
	/**
	 * label插槽
	 */
	label: (states: GTreeStates & { node: any; data: any }): VNode[] => [],
};
