import { defineComponent, inject } from "vue";
import { ElMessageBox, ElPagination } from "element-plus";
import { gTablePaginationProps } from "./define";
import type { GTableStates } from "@gejia-element-plus/components/table";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GTablePagination 组件
 */
export default defineComponent({
	name: "GTablePagination",
	components: {
		ElPagination,
	},
	props: gTablePaginationProps,
	setup(props, { expose }) {
		const tableStates = inject<GTableStates>("tableStates");

		const handleSizeChange = (pageSize: number): void => {
			if (pageSize > 100) {
				ElMessageBox.confirm("当前页码已经超过100条/页，可能会造成页面卡顿，是否继续？", {
					confirmButtonText: "继续",
					type: "warning",
				}).then(() => {
					props.sizeChange(pageSize);
				});
			} else {
				props.sizeChange(pageSize);
			}
		};

		useRender(() => (
			<ElPagination
				class="g-table-pagination"
				size="small"
				vModel:currentPage={tableStates.tablePagination.pageIndex}
				vModel:pageSize={tableStates.tablePagination.pageSize}
				pageSizes={[10, 20, 30, 50, 100, 300, 500, 1000]}
				background
				layout="jumper, prev, pager, next, sizes, total"
				total={tableStates.tablePagination.totalRows}
				onSizeChange={handleSizeChange}
				onCurrentChange={props.currentChange}
			/>
		));

		expose({});

		return {};
	},
});
