import { type Ref, computed } from "vue";
import type { ButtonInstance } from "element-plus";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const userButton = (buttonRef: Ref<ButtonInstance>) => {
	const ref = computed(() => buttonRef?.value?.ref)?.value;
	const size = computed(() => buttonRef?.value?.size)?.value;
	const type = computed(() => buttonRef?.value?.type)?.value;
	const disabled = computed(() => buttonRef?.value?.disabled)?.value;
	const shouldAddSpace = computed(() => buttonRef?.value?.shouldAddSpace)?.value;

	return {
		ref,
		size,
		type,
		disabled,
		shouldAddSpace,
	};
};
