import { Transition, defineComponent, onMounted, reactive } from "vue";
import { useEventListener } from "@vueuse/core";
import { gContextMenuEmits, gContextMenuProps } from "./define";
import type { GContextMenuData, GContextMenuStates } from "./type";
import { GIcon } from "@gejia-element-plus/components/icon";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GContextMenu 组件
 */
export default defineComponent({
	name: "GContextMenu",
	components: {
		GIcon,
	},
	props: gContextMenuProps,
	emits: gContextMenuEmits,
	setup(props, { emit, expose }) {
		const states: GContextMenuStates = reactive({
			visible: false,
			axis: {
				x: 0,
				y: 0,
			},
		});

		const handleClick = (event: MouseEvent, data: GContextMenuData): void => {
			if (data?.disabled === true) return;
			data.click(event, data);
			emit("click", event, data);
		};

		const open = (axis: { x: number; y: number } = { x: 0, y: 0 }): void => {
			states.axis = axis;
			states.visible = true;
		};

		const close = (): void => {
			states.visible = false;
		};

		onMounted(() => {
			useEventListener(document, "click", close);
		});

		useRender(() => (
			<Transition name="el-zoom-in-center">
				<div
					vShow={states.visible}
					class="g-context-menu el-popper el-dropdown__popper"
					style={{ top: `${states.axis.y + 5}px`, left: `${states.axis.x + 14}px` }}
					key={Math.random()}
					ariaHidden="false"
					dataPopperPlacement="bottom"
				>
					<ul class="el-dropdown-menu">
						{props.data.map((item) => (
							<li
								class={["el-dropdown-menu__item", item?.disabled === true ? "is-disabled" : ""]}
								tabIndex="-1"
								onClick={(event: MouseEvent) => {
									handleClick(event, item);
								}}
							>
								{item?.icon ? <GIcon name={item?.icon} /> : null}
								<span>{item.label}</span>
							</li>
						))}
					</ul>
				</div>
			</Transition>
		));

		expose({
			states,
			open,
			close,
		});

		return {
			states,
			open,
			close,
		};
	},
});
