import { computed, defineComponent, reactive } from "vue";
import { ElBreadcrumb, ElBreadcrumbItem } from "element-plus";
import { useRoute } from "vue-router";
import type { GLayoutBreadcrumbStates } from "./type";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GLayoutBreadcrumb 组件
 */
export default defineComponent({
	name: "GLayoutBreadcrumb",
	components: {
		ElBreadcrumb,
		ElBreadcrumbItem,
	},
	setup(props, { attrs, expose }) {
		const route = useRoute();

		const states: GLayoutBreadcrumbStates = reactive({
			breadcrumbs: computed(() => {
				return route.matched
					.filter((f) => {
						return f.meta?.breadcrumb !== false && f.meta?.title;
					})
					.map((m) => m.meta?.title);
			}),
		});

		useRender(() => (
			<ElBreadcrumb class="g-layout-breadcrumb" separator="/">
				<ElBreadcrumbItem to="/">首页</ElBreadcrumbItem>
				{states.breadcrumbs.map((item) => (
					<ElBreadcrumbItem>{item}</ElBreadcrumbItem>
				))}
			</ElBreadcrumb>
		));

		expose({
			states,
		});

		return {
			attrs,
			states,
		};
	},
});
