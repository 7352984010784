import type { PropType } from "vue";
import { buttonProps } from "element-plus";

export const gPrintButtonProps = {
	...buttonProps,
	/**
	 * 圆形按钮
	 */
	circle: {
		type: Boolean,
		default: true,
	},
	/**
	 * 链接按钮
	 */
	link: Boolean,
	/**
	 * 打印类型
	 */
	printType: {
		type: Number,
		required: true,
	},
	/**
	 * 订单ID
	 */
	orderID: {
		type: [Number, String, Array] as PropType<number | number[] | string | string[]>,
		required: true,
	},
};
