/**
 * 缓存Key
 */
export const CacheKey = {
	/**
	 * 本地缓存前缀
	 */
	CACHE_PREFIX: "gejia-",
	/**
	 * 本地缓存过期值后缀
	 */
	CACHE_EXPIRE_SUFFIX: "_Expire",
	/**
	 * 设备ID
	 */
	DEVICE_ID: "DEVICE_ID",
	/**
	 * Http 缓存前缀
	 */
	HTTP_CACHE: "HTTP_CACHE_",
};
