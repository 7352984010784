import type { Component, PropType, VNode } from "vue";
import { Eleme } from "@element-plus/icons-vue";
import { buttonProps } from "element-plus";
import type { GButtonStates } from "./type";

export const gButtonProps = {
	...buttonProps,
	/**
	 * 加载图标
	 */
	loadingIcon: {
		type: [String, Object] as PropType<string | Component>,
		default: Eleme,
	},
	/**
	 * 禁用加载
	 */
	disabledLoading: Boolean,
};

export const gButtonEmits = {
	/**
	 * 点击事件
	 * @param _done 需要手动隐藏Loading
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	click: (event: MouseEvent, done: () => void = () => {}): boolean => event instanceof MouseEvent,
};

export const gButtonSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GButtonStates): VNode[] => [],
	/**
	 * 自定义加载中组件
	 */
	loading: (states: GButtonStates): VNode[] => [],
	/**
	 * 自定义图标组件
	 */
	icon: (states: GButtonStates): VNode[] => [],
};
