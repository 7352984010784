import type { ExtractPropTypes } from "vue";
import type { gSelectPageEmits, gSelectPageProps } from "./src/define";
import SelectPage from "./src/selectPage";
import { withInstall } from "@gejia-element-plus/utils";

export const GSelectPage = withInstall(SelectPage);
export default GSelectPage;

export * from "./src/define";
export * from "./src/type";

export type GSelectPageInstance = InstanceType<typeof SelectPage>;

export type GSelectPageProps = ExtractPropTypes<typeof gSelectPageProps>;

export type GSelectPageEmits = typeof gSelectPageEmits;
