import type { PropType } from "vue";

export const gIconSelectorProps = {
	/**
	 * v-model绑定值
	 */
	modelValue: String,
	/**
	 * 自定义图标
	 */
	customIcons: [Array] as PropType<string[]>,
};

export const gIconSelectorEmits = {
	/**
	 * v-model 回调
	 */
	"update:modelValue": (value: string): boolean => true,
	/**
	 * 选中改变事件
	 */
	change: (value: string): boolean => true,
};
