import type { Ref } from "vue";
import type { ElDrawer } from "element-plus";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useDrawer = (drawerRef: Ref<InstanceType<typeof ElDrawer>>) => {
	const handleClose = (): void => drawerRef?.value?.handleClose();

	return {
		handleClose,
	};
};
