/**
 * 车牌号地区
 */
export const CarNumberArea: Readonly<string[]> = [
	"京",
	"津",
	"冀",
	"晋",
	"蒙",
	"辽",
	"吉",
	"黑",
	"沪",
	"苏",
	"浙",
	"皖",
	"闽",
	"赣",
	"鲁",
	"豫",
	"鄂",
	"湘",
	"粤",
	"桂",
	"琼",
	"渝",
	"川",
	"贵",
	"云",
	"藏",
	"陕",
	"甘",
	"青",
	"宁",
	"新",
	"军",
	"使",
	"领",
];

/**
 * 车牌号字母
 */
export const CarNumberLetter: Readonly<string[]> = [
	"Q",
	"W",
	"E",
	"R",
	"T",
	"Y",
	"U",
	"O",
	"P",
	"挂",
	"A",
	"S",
	"D",
	"F",
	"G",
	"H",
	"J",
	"K",
	"L",
	"学",
	"Z",
	"X",
	"C",
	"V",
	"B",
	"N",
	"M",
	"警",
	"港",
	"澳",
];

/**
 * 车牌号数字
 */
export const CarNumberDigit: Readonly<string[]> = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

/**
 * 特殊车牌号
 */
// export const CarNumberSpecial: Readonly<string[]> = ["挂", "学", "警", "港", "澳"];
