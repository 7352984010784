import { type App } from "vue";
import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";
import { CacheKey } from "@gejia-element-plus/constants";
import { base64Util } from "@gejia-element-plus/utils";

export * from "./gejiaApp";
export * from "./navTabs";

const pinia = createPinia();

/**
 * 注册持久化缓存
 * @param storageCrypto 存储加密
 */
const loadPinia = (app: App, storageCrypto = false): void => {
	pinia.use(
		createPersistedState({
			storage: {
				getItem: (key: string) => {
					const result = window.localStorage.getItem(`${CacheKey.CACHE_PREFIX}${key}`);
					if (!result) return null;
					return storageCrypto ? base64Util.base64ToStr(result) : result;
				},
				setItem: (key: string, value: string) => {
					window.localStorage.setItem(`${CacheKey.CACHE_PREFIX}${key}`, storageCrypto ? base64Util.toBase64(value) : value);
				},
			},
			// 当设置为 true 时，持久化/恢复 Store 时可能发生的任何错误都将使用 console.error 输出。
			debug: true,
		})
	);

	app.use(pinia);

	// 挂载到window中
	window.pinia = pinia;
};

export { pinia, loadPinia };
