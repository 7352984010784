import type { SlotsType, VNode } from "vue";
import { KeepAlive, Transition, computed, defineComponent, reactive, ref, watch } from "vue";
import { useWindowSize } from "@vueuse/core";
import { ElAside, ElContainer, ElFooter, ElHeader, ElMain, ElScrollbar, ElWatermark } from "element-plus";
import { RouterView, useRoute } from "vue-router";
import { gLayoutClassicProps, type gLayoutClassicSlots } from "./define";
import type { GLayoutClassicStates } from "./type";
import type { GModuleMenuInstance } from "@gejia-element-plus/components/moduleMenu";
import { GModuleMenu } from "@gejia-element-plus/components/moduleMenu";
import { GLayoutBreadcrumb } from "@gejia-element-plus/layouts/components/breadcrumb";
import { GLayoutLogo } from "@gejia-element-plus/layouts/components/logo";
import { GLayoutNavBarTab } from "@gejia-element-plus/layouts/components/navBarTab";
import { pinia, useGejiaApp, useNavTabs } from "@gejia-element-plus/stores";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GLayoutClassic 组件
 */
export default defineComponent({
	name: "GLayoutClassic",
	components: {
		ElContainer,
		ElAside,
		ElHeader,
		ElMain,
		ElScrollbar,
		ElWatermark,
		ElFooter,
		GLayoutBreadcrumb,
		GLayoutLogo,
		GModuleMenu,
		RouterView,
		GLayoutNavBarTab,
	},
	props: gLayoutClassicProps,
	slots: Object as SlotsType<typeof gLayoutClassicSlots>,
	setup(props, { slots, expose }) {
		const route = useRoute();
		const navTabsStore = useNavTabs(pinia);
		const gejiaAppStore = useGejiaApp(pinia);
		const windowSize = useWindowSize();

		const states: GLayoutClassicStates = reactive({
			mainStyle: computed(() => {
				let height = "var(--g-window-height)";
				if (!navTabsStore.states.contentFull) {
					if (!navTabsStore.states.contentLarge) {
						height += " - var(--g-header-height)";
					} else {
						height += " - var(--g-header-nav-bar-tab-height)";
					}
				}
				if (props.footer) {
					if (!navTabsStore.states.contentFull) {
						height += " - var(--g-footer-height) - var(--el-border-width)";
					}
				}

				return {
					"--g-layout-main-height": `calc(${height})`,
					height: "var(--g-layout-main-height)",
				};
			}),
			mainContentStyle: computed(() => {
				let height = "var(--g-window-height)";
				if (!navTabsStore.states.contentFull) {
					if (!navTabsStore.states.contentLarge) {
						height += " - var(--g-header-height)";
					} else {
						height += " - var(--g-header-nav-bar-tab-height)";
					}
				}
				if (props.footer) {
					if (!navTabsStore.states.contentFull) {
						height += " - var(--g-footer-height) - var(--el-border-width)";
					}
				}

				return {
					"--g-layout-main-content-height": `calc(${height} - (var(--g-main-padding) * 2))`,
					height: "var(--g-layout-main-content-height)",
				};
			}),
		});

		const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>();
		const menuRef = ref<GModuleMenuInstance>();

		watch(
			() => [windowSize.width.value, windowSize.height.value],
			() => {
				if (windowSize.width.value > 1366) {
					// 1366 以上使用默认
					gejiaAppStore.setAppConfig({ size: "default" });
				} else {
					gejiaAppStore.setAppConfig({ size: "small" });
				}

				// 如果宽度小于最小的，则就是最小的
				let width = "100vw";
				if (windowSize.width.value <= gejiaAppStore.states.appConfig.minWidth) {
					width = `${gejiaAppStore.states.appConfig.minWidth}px`;
				} else {
					width = `${windowSize.width.value}px`;
				}

				document.documentElement.style.setProperty("--g-window-width", width);
				document.documentElement.style.setProperty("--g-window-height", `${windowSize.height.value}px`);
				document.documentElement.style.setProperty("--g-window-min-width", `${gejiaAppStore.states.appConfig.minWidth}px`);
			},
			{
				immediate: true,
			}
		);

		const defaultRender = (): VNode | VNode[] => {
			return (
				<ElContainer class="g-layout-Classic g-layout-container">
					<ElAside
						class={[
							"g-layout-aside",
							navTabsStore.states.contentFull ? "g-layout-aside__contentFull" : "",
							navTabsStore.states.contentLarge ? "g-layout-aside__contentLarge" : "",
						]}
					>
						<GLayoutLogo siteName={props.siteName} logo={props.logo} />
						<GModuleMenu ref={menuRef} />
					</ElAside>
					<ElContainer>
						<ElHeader class={["g-layout-header", navTabsStore.states.contentFull ? "g-layout-header__contentFull" : ""]}>
							<div class={["g-layout-nav-bar", navTabsStore.states.contentLarge ? "g-layout-nav-bar__contentLarge" : ""]}>
								<GLayoutBreadcrumb />
								<div class="g-layout-nav-bar__left">{slots.header && slots.header(states)}</div>
								<div class="g-layout-nav-bar__right">{slots.navMenu && slots.navMenu(states)}</div>
							</div>
							{slots.navBarTab ? <div class="g-layout-nav-bar-tab">{slots.navBarTab(states)}</div> : <GLayoutNavBarTab />}
						</ElHeader>

						<ElMain class="g-layout-main" style={states.mainStyle}>
							<ElScrollbar ref={scrollbarRef}>
								<RouterView>
									{{
										default: ({ Component }) => (
											<Transition mode="out-in" name={props.mainAnimation}>
												<KeepAlive include={navTabsStore.states.keepAliveComponentNameList}>
													<Component key={route.name} class="g-layout-main-content" style={states.mainContentStyle} />
												</KeepAlive>
											</Transition>
										),
									}}
								</RouterView>
							</ElScrollbar>
						</ElMain>
						{props.footer ? (
							<ElFooter class={["g-layout-footer", navTabsStore.states.contentFull ? "g-layout-footer__contentFull" : ""]}>
								{slots.footer && slots.footer(states)}
							</ElFooter>
						) : null}
					</ElContainer>
				</ElContainer>
			);
		};

		useRender(() =>
			props.watermark ? (
				<ElWatermark
					class="g-layout-watermark"
					font={{
						fontSize: gejiaAppStore.states.appConfig.size === "small" ? 12 : 14,
						color: props.isDark ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.2)",
					}}
					zIndex={9999}
					rotate={-38}
					gap={[-50, -50]}
					offset={[-10, 0]}
					width={200}
					height={300}
					content={props.watermarkContent}
				>
					{defaultRender()}
				</ElWatermark>
			) : (
				defaultRender()
			)
		);

		expose({
			states,
			windowSize,
		});

		return {
			slots,
			states,
			windowSize,
		};
	},
});
