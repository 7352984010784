import type { PropType } from "vue";
import type { GContextMenuData } from "./type";

export const gContextMenuProps = {
	/**
	 * 数据
	 */
	data: {
		type: Array as PropType<GContextMenuData[]>,
		default: [],
	},
};

export const gContextMenuEmits = {
	/**
	 * 点击事件
	 */
	click: (event: MouseEvent, data: GContextMenuData): boolean => event instanceof MouseEvent,
};
