import { defineComponent, onMounted, reactive } from "vue";
import { Printer } from "@element-plus/icons-vue";
import { ElButton, ElDropdown, ElDropdownItem, ElDropdownMenu, ElLoading, ElMessage, buttonProps } from "element-plus";
import { gPrintButtonProps } from "./define";
import type { GPrintButtonStates } from "./type";
import { pinia, useGejiaApp } from "@gejia-element-plus/stores";
import { errorHandler, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GPrintButton 组件
 */
export default defineComponent({
	name: "GPrintButton",
	components: {
		ElDropdown,
		ElDropdownMenu,
		ElDropdownItem,
		ElButton,
		Printer,
	},
	props: gPrintButtonProps,
	setup(props, { attrs, expose }) {
		const states: GPrintButtonStates = reactive({
			show: false,
			printTemplateList: [],
		});

		let loadingInstance: ReturnType<typeof ElLoading.service>;

		/**
		 * 处理打印
		 * @param templateID 模板ID
		 * @param orderID 订单ID
		 */
		const handlePrint = (templateID: number, orderID?: number | number[] | string | string[]): void => {
			if (!templateID) {
				console.error("[gejia-GPrintButton]", "模板ID不能为空");
				ElMessage.error("模板ID不能为空");
			}

			const localOrderID = orderID ?? props.orderID;
			const orderIDList = [];

			if (Array.isArray(localOrderID)) {
				orderIDList.push(...localOrderID);
			} else if (localOrderID) {
				orderIDList.push(localOrderID);
			}

			if (orderIDList.length === 0) {
				console.error("[gejia-GPrintButton]", "请选择一个单据进行打印");
				ElMessage.error("请选择一个单据进行打印");
				return;
			}

			loadingInstance = ElLoading.service({
				fullscreen: true,
				lock: true,
				text: "打印中...",
				background: "rgba(0, 0, 0, 0.7)",
			});

			try {
				for (const item of orderIDList) {
					if (!item || item === 0) {
						console.error("[gejia-GPrintButton]", "打印ID不能为空或者零");
						ElMessage.error("打印ID不能为空或者零");
					} else {
						gePrint.Print(templateID, item).then((printRes) => {
							if (printRes.isSuccess) {
								ElMessage.success(printRes.message);
							} else {
								console.error("[gejia-GPrintButton]", printRes.message);
								ElMessage.error(printRes.message);
							}
						});
					}
				}
			} catch (error) {
				console.error("[gejia-GPrintButton]", error);
				errorHandler(error);
			} finally {
				loadingInstance.close();
			}
		};

		/**
		 * 处理打印预览
		 * @param printType 打印类型
		 * @param orderID 订单ID
		 */
		const handlePrintPreview = (printType?: number, orderID?: number | string): void => {
			const localPrintType = printType ?? props.printType;
			if (!localPrintType) {
				console.error("[gejia-GPrintButton]", "打印类型不能为空");
				ElMessage.error("打印类型不能为空");
			}

			let localOrderID = orderID ?? props.orderID;

			if (Array.isArray(localOrderID)) {
				if (localOrderID.length > 1) {
					console.error("[gejia-GPrintButton]", "只能选择一个单据进行打印预览");
					ElMessage.error("只能选择一个单据进行打印预览");
					return;
				}
				localOrderID = localOrderID[0];
			}

			gePrint
				.PrintManager(localPrintType, localOrderID)
				.then((printRes) => {
					if (printRes.isSuccess) {
						ElMessage.success(printRes.message);
					} else {
						console.error("[gejia-GPrintButton]", printRes.message);
						ElMessage.error(printRes.message);
					}
				})
				.catch((error) => {
					console.error("[gejia-GPrintButton]", error);
					ElMessage.error("打印预览失败");
					errorHandler(error);
				});
		};

		onMounted(() => {
			const gejiaAppStore = useGejiaApp(pinia);

			if (gejiaAppStore.states.printConfig.printEnvironment === undefined) {
				try {
					// 检测打印环境
					states.show = gePrint.GetPrintable();
					gejiaAppStore.setPrintEnvironment(states.show);
				} catch (err) {
					states.show = false;
					gejiaAppStore.setPrintEnvironment(false);
					console.warn("[Gejia-GPrintButton]", "打印环境检测失败", err);
				}
			} else {
				states.show = gejiaAppStore.states.printConfig.printEnvironment;
			}

			// 开发环境判断
			if (gejiaAppStore.states.viteEnv === "development") {
				states.show = true;
			}

			if (states.show) {
				// 查询打印模板
				if (!gejiaAppStore.states.printConfig.queryPrintTemplate) {
					console.error("[gejia-GPrintButton]", "查询打印模板配置不存在");
					ElMessage.error("查询打印模板配置不存在");
				} else {
					gejiaAppStore.states.printConfig
						.queryPrintTemplate(props.printType)
						.then((templateRes) => {
							states.printTemplateList = templateRes.data;
						})
						.catch((error) => {
							console.error("[gejia-GPrintButton]", error);
							ElMessage.error("查询打印模板配置错误");
							errorHandler(error);
						});
				}
			}
		});

		const bindProps = useProps(props, buttonProps, null, {
			icon: Printer,
		});

		useRender(() =>
			states.show ? (
				<div class="g-print-button">
					<ElDropdown trigger="click">
						{{
							default: () => <ElButton {...attrs} {...bindProps.value} title="打印" />,
							dropdown: () => (
								<ElDropdownMenu>
									<>
										{states.printTemplateList.map((item) => (
											<ElDropdownItem onClick={() => handlePrint(item?.templateID)}>{item?.title}</ElDropdownItem>
										))}
										<ElDropdownItem divided onClick={() => handlePrintPreview()}>
											打印预览
										</ElDropdownItem>
									</>
								</ElDropdownMenu>
							),
						}}
					</ElDropdown>
				</div>
			) : null
		);

		expose({
			states,
			print: handlePrint,
			printPreview: handlePrintPreview,
		});

		return {
			attrs,
			bindProps,
			states,
			print: handlePrint,
			printPreview: handlePrintPreview,
		};
	},
});
