/**
 * MIME 类型
 */
export const GMimeType = {
	/**
	 * PDF
	 */
	PDF: "application/pdf",
	/**
	 * 文本文件
	 */
	Text: "text/plain",
	/**
	 * Word 和 DOCX（Word 2007 及以后版本）
	 */
	Word: "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	/**
	 * Excel 和 CSV
	 */
	Excel: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv",
	/**
	 * PowerPoint 和 PPTX（PowerPoint 2007 及以后版本）
	 */
	Powerpoint: "application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
	/**
	 * 压缩文件
	 */
	ZIP: "application/zip,application/x-zip-compressed",
	/**
	 * 图片
	 */
	Image: "image/jpeg,image/png,image/gif",
	/**
	 * 视频
	 */
	Video: "video/mp4,video/mpeg,video/quicktime",
	/**
	 * 音频
	 */
	Audio: "audio/mpeg,audio/wav",
	/**
	 * HTML
	 */
	Html: "text/html",
	/**
	 * CSS
	 */
	Css: "text/css",
	/**
	 * JavaScript
	 */
	JavaScript: "application/javascript,text/javascript",
	/**
	 * JSON
	 */
	Json: "application/json",
	/**
	 * XML
	 */
	XML: "application/xml,text/xml",
};
