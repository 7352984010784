export const gLayoutLogoProps = {
	/**
	 * 站点名称
	 */
	siteName: String,
	/**
	 * Logo
	 */
	logo: String,
};
