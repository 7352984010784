import type { UploadUserFile } from "element-plus";

export interface GUploadStates {
	/**
	 * 加载
	 */
	loading: boolean;
	/**
	 * 禁用
	 */
	disabled: boolean;
	/**
	 * 文件集合
	 */
	fileList: UploadUserFile[];
}

/**
 * 文件类型
 */
export enum StoreFileType {
	/**
	 * None
	 */
	None = 0,
	/**
	 * StoreImage
	 */
	StoreImage = 1,
	/**
	 * Product
	 */
	Product = 2,
	/**
	 * Article
	 */
	Article = 4,
	/**
	 * Share
	 */
	Share = 8,
	/**
	 * Order
	 */
	Order = 16,
	/**
	 * Feed
	 */
	Feed = 32,
	/**
	 * Dynamic
	 */
	Dynamic = 64,
	/**
	 * Reimbursement
	 */
	Reimbursement = 128,
	/**
	 * Activity
	 */
	Activity = 256,
	/**
	 * Album
	 */
	Album = 512,
	/**
	 * Decorate
	 */
	Decorate = 1024,
	/**
	 * Advertise
	 */
	Advertise = 2048,
	/**
	 * Logo
	 */
	Logo = 4096,
	/**
	 * Sign
	 */
	Sign = 8192,
	/**
	 * Integral
	 */
	Integral = 16384,
	/**
	 * Contract
	 */
	Contract = 65536,
	/**
	 * ProductVideo
	 */
	ProductVideo = 131072,
	/**
	 * Chat
	 */
	Chat = 262144,
	/**
	 * Worker
	 */
	Worker = 524288,
	/**
	 * Voucher
	 */
	Voucher = 1048576,
	/**
	 * Other
	 */
	Other = 2097152,
	/**
	 * CustomerMaterial
	 */
	CustomerMaterial = 4194304,
	/**
	 * DecorateVideo
	 */
	DecorateVideo = 8388608,
	/**
	 * ContractPDF
	 */
	ContractPDF = 16777216,
	/**
	 * ReportPreview
	 */
	ReportPreview = 33554432,
	/**
	 * Journal
	 */
	Journal = 67108864,
	/**
	 * DynamicVideo
	 */
	DynamicVideo = 134217728,
	/**
	 * FeedVideo
	 */
	FeedVideo = 268435456,
	/**
	 * Asset
	 */
	Asset = 536870912,
	/**
	 * SupplierMaterial
	 */
	SupplierMaterial = 1073741824,
	/**
	 * Audio
	 */
	Audio = 1073741825,
	/**
	 * Invoice
	 */
	Invoice = -2147483646,
}
