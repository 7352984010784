import { inputProps } from "element-plus";

export const gCarNumberProps = {
	...inputProps,
	/**
	 * v-model绑定值
	 */
	modelValue: String,
};

export const gCarNumberEmits = {
	/**
	 * v-model 回调
	 */
	"update:modelValue": (value: string): boolean => true,
	/**
	 * 改变
	 */
	change: (value: string): boolean => true,
};
