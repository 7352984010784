import type { SetupContext } from "vue";
import { computed, defineComponent, reactive } from "vue";
import { ElPopover, ElScrollbar } from "element-plus";
import type { RouteRecordRaw } from "vue-router";
import { RouterLink, useRoute, useRouter } from "vue-router";
import type { GModuleMenuStates } from "./type";
import { GIcon } from "@gejia-element-plus/components/icon";
import { routerUtil, useRender } from "@gejia-element-plus/utils";

/**
 * GModuleMenu 组件
 */
export default defineComponent({
	name: "GModuleMenu",
	components: {
		ElScrollbar,
		ElPopover,
		RouterLink,
		GIcon,
	},
	setup(_, { slots, expose }: SetupContext) {
		const router = useRouter();
		const route = useRoute();
		const states: GModuleMenuStates = reactive({
			// 直接默认查找 layout 下的路由，其余的直接忽略
			menuList: computed(() =>
				router
					.getRoutes()
					.find((f) => f.name === "layout")
					?.children?.filter((f) => f.meta?.hidden !== true)
			),
			popoverVisible: [],
			activeMenu: computed(() => route),
			curPopoverIndex: -1,
			curEl: undefined,
		});

		const isNodeContains = (element: HTMLElement, target: HTMLElement): boolean => {
			const childNodes = element.childNodes;
			for (let i = 0; i < childNodes.length; i++) {
				const node = childNodes[i];
				if (node.childNodes?.length > 0) {
					const tryA = isNodeContains(node as HTMLElement, target);
					if (tryA) {
						return true;
					}
				}
				if (node.nodeType === Node.ELEMENT_NODE && node === target) {
					return true;
				}
			}
			return false;
		};

		const handleMenuItemClick = (menu: RouteRecordRaw, index: number): void => {
			if (menu.path === route.path) return;
			states.popoverVisible[index] = false;
			routerUtil.routePushSafe(menu?.path);
		};

		const handleHidePopover = (event: MouseEvent): void => {
			const { curPopoverIndex, curEl } = { ...states };
			const groupEl = document.querySelector(`.__g-module-menu-popover__group-${curPopoverIndex}`) as HTMLElement;
			const newTarget = event.target as HTMLElement;
			if (curEl != event.target && !isNodeContains(groupEl, newTarget)) {
				states.popoverVisible[curPopoverIndex] = false;
				document.removeEventListener("click", handleHidePopover);
			}
		};

		const handleMenuClick = (event: MouseEvent, menu: RouteRecordRaw, index: number): void => {
			if (menu?.children?.length > 0) {
				states.popoverVisible = states.popoverVisible.map(() => false);
				states.popoverVisible[index] = true;
				states.curPopoverIndex = index;
				states.curEl = event.target as HTMLElement;
				document.addEventListener("click", handleHidePopover);
			} else {
				if (menu.path === route.path) return;
				routerUtil.routePushSafe({ path: menu.path });
			}
		};

		useRender(() => (
			<ElScrollbar class="g-module-menu">
				{states.menuList.map((item, index) => (
					<>
						{item?.children?.length > 0 ? (
							<ElPopover
								popperClass="g-module-menu-popover"
								visible={states.popoverVisible[index]}
								placement="right-start"
								width="auto"
								trigger="click"
								showArrow={false}
								offset={7}
								showAfter={0}
								hideAfter={0}
							>
								{{
									reference: () => (
										<div
											class={[
												"g-module-menu__list",
												states.activeMenu.meta?.moduleId === item.meta?.moduleId
													? "g-module-menu__list-is-active"
													: "g-module-menu__list-not-active",
											]}
											onClick={(event: MouseEvent) => handleMenuClick(event, item, index)}
										>
											<GIcon name={item.meta?.icon ?? "g-icon-Menu"} />
											<span>{item.meta?.title}</span>
										</div>
									),
									default: () => (
										<div class={["g-module-menu-popover__group", `__g-module-menu-popover__group-${index}`]}>
											{item?.children
												?.filter((f) => f.meta?.hidden !== true)
												.map((gItem) => (
													<div>
														<div class="g-module-menu-popover__group-title">{gItem.meta?.title}</div>
														<ul class="g-module-menu-popover__group-list">
															{gItem?.children?.map((mItem) =>
																mItem.meta?.hidden !== true ? (
																	<li
																		class="g-module-menu-popover__group-list-item"
																		onClick={() => handleMenuItemClick(mItem, index)}
																	>
																		<span>{mItem.meta?.title}</span>
																	</li>
																) : null
															)}
														</ul>
													</div>
												))}
										</div>
									),
								}}
							</ElPopover>
						) : (
							<div
								class={[
									"g-module-menu__list",
									states.activeMenu.meta?.moduleId === item.meta?.moduleId
										? "g-module-menu__list-is-active"
										: "g-module-menu__list-not-active",
								]}
								onClick={(event: MouseEvent) => handleMenuClick(event, item, index)}
							>
								<GIcon name={item.meta?.icon ?? "g-icon-Menu"} />
								<span>{item.meta?.title}</span>
							</div>
						)}
					</>
				))}
			</ElScrollbar>
		));

		expose({
			states,
		});

		return {
			slots,
			states,
		};
	},
});
