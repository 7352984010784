import type { SlotsType, VNode } from "vue";
import { computed, defineComponent, nextTick, reactive, ref, watch } from "vue";
import { Close, Eleme, Refresh } from "@element-plus/icons-vue";
import type { DialogInstance } from "element-plus";
import { ElButton, ElDialog, ElIcon, ElMessage, ElMessageBox, ElScrollbar, dialogProps } from "element-plus";
import type { gDialogSlots } from "./define";
import { gDialogEmits, gDialogProps } from "./define";
import type { GDialogStates } from "./type";
import { userDialog } from "./useDialog";
import { GIcon } from "@gejia-element-plus/components/icon";
import { GPrintButton } from "@gejia-element-plus/components/printButton";
import { errorHandler, execFunction, typeUtil, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GDialog 组件
 */
export default defineComponent({
	name: "GDialog",
	components: {
		ElDialog,
		ElIcon,
		ElScrollbar,
		ElButton,
		Close,
		Eleme,
		GIcon,
		GPrintButton,
		Refresh,
	},
	props: gDialogProps,
	emits: gDialogEmits,
	slots: Object as SlotsType<typeof gDialogSlots>,
	setup(props, { attrs, slots, emit, expose }) {
		const states: GDialogStates = reactive({
			loading: false,
			visible: false,
			fullscreen: false,
			width: computed(() => {
				if (props.width) {
					if (typeUtil.isNumber(props.width)) {
						return `${props.width}px`;
					} else {
						return props.width;
					}
				}
				return "auto";
			}),
			maxHeight: computed(() => {
				let maxHeight = "calc(";
				if (states.fullscreen) {
					maxHeight += "var(--g-window-height)";
				} else {
					maxHeight += "var(--g-dialog-height)";
				}
				maxHeight += " - var(--g-dialog-header-height)";

				if (props.showFooterOperator) {
					maxHeight += " - var(--g-dialog-footer-height)";
				}
				// 16px(内容padding高度) - 2px(线条高度)
				maxHeight += " - 18px)";
				return maxHeight;
			}),
			refreshing: false,
		});

		const dialogRef = ref<DialogInstance>();

		const cacheData = {
			openFunction: undefined,
		};

		/**
		 * 打开
		 */
		const open = (openFunction?: () => Promise<void>): void => {
			states.visible = true;
			cacheData.openFunction = openFunction;
			nextTick(() => {
				states.loading = true;
				execFunction(props.afterOpen ?? openFunction)
					.then(() => {
						emit("open");
					})
					.catch((error) => {
						console.error("[gejia-GDialog]", error);
						// 自动关闭
						states.visible = false;
						errorHandler(error);
					})
					.finally(() => {
						states.loading = false;
					});
			});
		};

		/**
		 * 关闭
		 */
		const close = (closeFunction?: () => Promise<void>): void => {
			states.loading = true;
			execFunction(closeFunction)
				.then(() => {
					emit("close");
					states.visible = false;
				})
				.catch((error) => {
					console.error("[gejia-GDialog]", error);
					errorHandler(error);
				})
				.finally(() => {
					states.loading = false;
				});
		};

		/**
		 * 刷新
		 */
		const refresh = (): void => {
			states.refreshing = true;
			states.loading = true;
			setTimeout(() => {
				states.refreshing = false;
				open(cacheData.openFunction);
				ElMessage.success("刷新成功");
			}, 500);
		};

		const handleBeforeClose = (done: () => void): void => {
			if (states.loading) return;
			// 解决 image 预览摁下 ese 会关闭弹窗的问题
			if (document.querySelector(".el-image-viewer__wrapper")) return;

			const newDone = (): void => {
				emit("close");
				if (props.beforeClose) {
					props.beforeClose() && done();
				} else {
					done();
				}
			};

			if (props.showBeforeClose) {
				ElMessageBox.confirm("确定关闭？", { type: "warning" }).then(() => {
					newDone();
				});
			} else {
				newDone();
			}
		};

		const handleFullscreen = (): void => {
			if (states.loading) return;
			states.fullscreen = !states.fullscreen;
		};

		const handleConfirmClick = (): void => {
			if (states.loading) return;
			emit("confirmClick", states);
		};

		const handleClose = (): void => {
			if (states.loading) return;
			close();
		};

		watch(
			() => states.visible,
			(newValue) => {
				emit("update:modelValue", newValue);
			}
		);

		const bindProps = useProps(props, dialogProps, ["modelValue", "fullscreen", "beforeClose"], {
			showClose: false,
		});

		useRender(() => (
			<ElDialog
				{...attrs}
				{...bindProps.value}
				ref={dialogRef}
				class={["g-dialog", props.fillHeight ? "g-dialog__fill-height" : "", states.fullscreen ? "g-dialog__fullscreen" : ""]}
				style={{
					height: props.height ? props.height : "auto",
					maxHeight: props.height ? props.height : "",
					width: states.width,
					maxWidth: states.width,
				}}
				vModel={states.visible}
				fullscreen={states.fullscreen}
				beforeClose={handleBeforeClose}
			>
				{{
					header: () => (
						<>
							<div class="g-dialog__header-title">
								{props.title}
								{slots.header && slots.header(states)}
							</div>
							{props.printType && props.orderID ? (
								<GPrintButton
									class={["g-dialog__header-icon", states.loading ? "g-click-disabled g-click-disabled__cursor " : "g-twinkle"]}
									link
									printType={props.printType}
									orderID={props.orderID}
								/>
							) : null}
							{props.showRefresh ? (
								<div
									title="刷新"
									class={["g-dialog__header-icon", states.loading ? "g-click-disabled g-click-disabled__cursor " : "g-twinkle"]}
									onClick={refresh}
								>
									<ElIcon class="icon">
										<Refresh />
									</ElIcon>
								</div>
							) : null}
							{props.showFullscreen ? (
								<div
									title={states.fullscreen ? "关闭全屏显示" : "全屏显示"}
									class={["g-dialog__header-icon", states.loading ? "g-click-disabled g-click-disabled__cursor " : "g-twinkle"]}
									onClick={handleFullscreen}
								>
									<GIcon name={states.fullscreen ? "g-icon-FullScreenExit" : "g-icon-FullScreen"} />
								</div>
							) : null}
							{props.showClose ? (
								<div
									title="关闭"
									class={["g-dialog__header-icon", states.loading ? "g-click-disabled g-click-disabled__cursor " : "g-twinkle"]}
									onClick={handleClose}
								>
									<ElIcon class="icon">
										<Close />
									</ElIcon>
								</div>
							) : null}
						</>
					),
					default: () => (
						<ElScrollbar
							class="g-dialog__scrollbar"
							wrapStyle={{ "--g-dialog-scrollbar__max-height": states.maxHeight, maxHeight: "var(--g-dialog-scrollbar__max-height)" }}
							vLoading={states.loading}
							element-loading-text="加载中..."
						>
							{states.refreshing ? null : slots.default && slots.default(states)}
						</ElScrollbar>
					),
					...(props.showFooterOperator && {
						footer: (): VNode[] => (
							<>
								{slots.footer && slots.footer(states)}
								{props.showCloseButton ? (
									<ElButton disabled={states.loading} onClick={handleClose}>
										{props.closeButtonText}
									</ElButton>
								) : null}
								{props.showConfirmButton ? (
									<ElButton
										loading={states.loading}
										loadingIcon={Eleme}
										disabled={props.disabledConfirmButton}
										type="primary"
										onClick={handleConfirmClick}
									>
										{states.loading ? "加载中..." : props.confirmButtonText}
									</ElButton>
								) : null}
							</>
						),
					}),
				}}
			</ElDialog>
		));

		expose({
			states,
			open,
			close,
			refresh,
			...userDialog(dialogRef),
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			open,
			close,
			refresh,
			...userDialog(dialogRef),
		};
	},
});
