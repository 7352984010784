import type { PropType } from "vue";
import type { GTableColumnCtx } from "@gejia-element-plus/components/table";

export const gInputDialogPageProps = {
	/**
	 * v-model绑定值
	 */
	modelValue: [Number, String, Boolean] as PropType<number | string | boolean>,
	/**
	 * 显示文字
	 */
	label: String,
	/**
	 * 输入框占位文本
	 */
	placeholder: {
		type: String,
		default: "请选择",
	},
	/**
	 * 禁用
	 */
	disabled: Boolean,
	/**
	 * 标题
	 */
	title: String,
	/**
	 * 表格Key
	 */
	tableKey: {
		type: String,
		// required: true,
		required: false,
	},
	/**
	 * 初始化请求参数
	 */
	initParam: {
		type: Object as PropType<anyObj>,
		default: {},
	},
	/**
	 * 请求表格数据的api
	 */
	requestApi: {
		type: Function as PropType<(param?: PagedInput) => ApiPromise<PagedResult<anyObj>> | ApiPromise<Array<anyObj>>>,
	},
	/**
	 * 列配置项
	 */
	columns: {
		type: [Array, Boolean] as PropType<GTableColumnCtx[] | false>,
		default: false,
	},
	/**
	 * 行数据的 Key，用来优化 Table 的渲染，当表格数据多选时，所指定的 id ==> 非必传（默认为 id）
	 */
	rowKey: {
		type: String,
		default: "id",
	},
	/**
	 * 显示文本 Key
	 */
	labelKey: {
		type: String,
		default: "name",
	},
};

export const gInputDialogPageEmits = {
	/**
	 * v-model 回调
	 */
	"update:modelValue": (value: number | string | boolean): boolean => true,
	/**
	 * v-model:label 回调
	 */
	"update:label": (value: string): boolean => true,
	/**
	 * 选中改变事件
	 */
	change: (value: any): boolean => true,
};
