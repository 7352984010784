import type { Ref, SlotsType, VNode } from "vue";
import { defineComponent, inject, ref } from "vue";
import { ElFormItem, formItemProps } from "element-plus";
import { gFormItemProps, type gFormItemSlots } from "./define";
import { GFormItemTip } from "@gejia-element-plus/components/formItemTip";
import { type GLayoutGridBreakPoint, GLayoutGridItem } from "@gejia-element-plus/components/layoutGrid";
import { typeUtil, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GFormItem 组件
 */
export default defineComponent({
	name: "GFormItem",
	components: {
		ElFormItem,
		GLayoutGridItem,
		GFormItemTip,
	},
	props: gFormItemProps,
	slots: Object as SlotsType<typeof gFormItemSlots>,
	setup(props, { attrs, slots, expose }) {
		const gFormCols = inject<Record<GLayoutGridBreakPoint, number>>("gFormCols");
		const cols = inject<Ref<number>>("cols", ref(4));

		// 获取响应式设置
		const getResponsive = (): {
			span: number;
			offset: number;
		} => {
			if (props.span) {
				return {
					span: typeUtil.isNumber(props.span) ? props.span : Number(props.span),
					offset: props.offset,
				};
			} else {
				const result = {
					span: 1,
					offset: props.offset,
				};
				if (props.row) {
					result.span = cols.value;
				}
				return result;
			}
		};

		const bindProps = useProps(props, formItemProps);

		useRender(() =>
			props.grid ? (
				<GLayoutGridItem {...getResponsive()}>
					<ElFormItem {...attrs} {...bindProps.value}>
						{{
							default: () => slots.default(),
							...(slots.label
								? { label: ({ label }: { label: string }): VNode[] => slots.label({ label }) }
								: props.tips && {
										label: ({ label }: { label: string }): VNode[] => (
											<GFormItemTip label={label ?? props.label} tips={props.tips} />
										),
									}),
							...(slots.error && { error: ({ error }: { error: string }): VNode[] => slots.error({ error }) }),
						}}
					</ElFormItem>
				</GLayoutGridItem>
			) : (
				<ElFormItem {...attrs} {...bindProps.value}>
					{{
						default: () => slots.default(),
						...(slots.label
							? { label: ({ label }: { label: string }): VNode[] => slots.label({ label }) }
							: props.tips && {
									label: ({ label }: { label: string }): VNode[] => <GFormItemTip label={label ?? props.label} tips={props.tips} />,
								}),
						...(slots.error && { error: ({ error }: { error: string }): VNode[] => slots.error({ error }) }),
					}}
				</ElFormItem>
			)
		);

		expose({
			gFormCols,
		});

		return {
			attrs,
			bindProps,
			slots,
			gFormCols,
		};
	},
});
