import type { PropType, VNode } from "vue";
import { dialogProps } from "element-plus";
import type { GDialogStates } from "./type";

export const gDialogProps = {
	...dialogProps,
	appendToBody: {
		type: Boolean,
		default: true,
	},
	draggable: {
		type: Boolean,
		default: true,
	},
	overflow: {
		type: Boolean,
		default: true,
	},
	destroyOnClose: {
		type: Boolean,
		default: true,
	},
	/**
	 * 宽度
	 */
	width: [Number, String] as PropType<number | string>,
	/**
	 * 高度
	 */
	height: String,
	/**
	 * 显示刷新按钮
	 */
	showRefresh: {
		type: Boolean,
		default: true,
	},
	/**
	 * 显示全屏图标
	 */
	showFullscreen: {
		type: Boolean,
		default: true,
	},
	/**
	 * 显示关闭按钮
	 */
	showCloseButton: {
		type: Boolean,
		default: true,
	},
	/**
	 * 显示确认按钮
	 */
	showConfirmButton: {
		type: Boolean,
		default: true,
	},
	/**
	 * 禁用确认按钮
	 */
	disabledConfirmButton: Boolean,
	/**
	 * 关闭按钮文字，默认取消
	 */
	closeButtonText: {
		type: String,
		default: "取消",
	},
	/**
	 * 确认按钮文字，默认确认
	 */
	confirmButtonText: {
		type: String,
		default: "确认",
	},
	/**
	 * 显示关闭回调
	 */
	showBeforeClose: Boolean,
	/**
	 * 显示底部操作
	 */
	showFooterOperator: {
		type: Boolean,
		default: true,
	},
	/**
	 * 撑满高度
	 */
	fillHeight: Boolean,
	/**
	 * 打开之后
	 */
	afterOpen: {
		type: Function as PropType<() => Promise<void>>,
	},
	/**
	 * 关闭之前
	 */
	beforeClose: {
		type: Function as PropType<() => Promise<boolean>>,
	},
	/**
	 * 打印类型
	 */
	printType: Number,
	/**
	 * 打印订单ID
	 */
	orderID: {
		type: [Number, String] as PropType<number | string>,
	},
};

export const gDialogEmits = {
	/**
	 * v-model 回调
	 */
	"update:modelValue": (value: boolean): boolean => true,
	/**
	 * 打开事件
	 */
	open: (): boolean => true,
	/**
	 * 关闭事件
	 */
	close: (): boolean => true,
	/**
	 * 确认按钮点击事件
	 */
	confirmClick: (states?: GDialogStates): boolean => true,
};

export const gDialogSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GDialogStates): VNode[] => [],
	/**
	 * 头部插槽
	 */
	header: (states: GDialogStates): VNode[] => [],
	/**
	 * 底部插槽
	 */
	footer: (states: GDialogStates): VNode[] => [],
};
