import type { VNode } from "vue";

export const gFormItemTipProps = {
	/**
	 * 提示
	 */
	tips: String,
	/**
	 * 显示
	 */
	label: String,
};

export const gFormItemTipSlots = {
	/**
	 * label插槽
	 */
	label: (): VNode[] => [],
};
