import type { Ref, SlotsType } from "vue";
import { computed, defineComponent, inject, reactive, ref, watch } from "vue";
import type { gLayoutGridItemSlots } from "./define";
import { gLayoutGridItemProps } from "./define";
import type { GLayoutGridItemResponsive, GLayoutGridItemStates } from "./type";
import type { GLayoutGridBreakPoint } from "@gejia-element-plus/components/layoutGrid";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GLayoutGridItem 组件
 */
export default defineComponent({
	name: "GLayoutGridItem",
	components: {},
	props: gLayoutGridItemProps,
	slots: Object as SlotsType<typeof gLayoutGridItemSlots>,
	setup(props, { attrs, slots, emit, expose }) {
		const states: GLayoutGridItemStates = reactive({
			show: true,
		});

		const attrsObj = attrs as any;

		// 注入断点
		const breakPoint = inject<Ref<GLayoutGridBreakPoint>>("breakPoint", ref("xl"));
		const shouldHiddenIndex = inject<Ref<number>>("shouldHiddenIndex", ref(-1));

		watch(
			() => [shouldHiddenIndex.value, breakPoint.value],
			(n) => {
				if (~~attrsObj.index) {
					states.show = !(n[0] !== -1 && parseInt(attrsObj.index) >= Number(n[0]));
				}
			},
			{ immediate: true }
		);

		const gap = inject("gap", 0);
		const cols = inject<Ref<number>>("cols", ref(4));

		const style = computed(() => {
			const breakPointObk = props[breakPoint.value] as GLayoutGridItemResponsive;
			const span = breakPointObk?.span ?? props.span;
			const offset = breakPointObk?.offset ?? props.offset;
			if (props.suffix) {
				return {
					gridColumnStart: cols.value - span - offset + 1,
					gridColumnEnd: `span ${span + offset}`,
					marginLeft: offset !== 0 ? `calc(((100% + ${gap}px) / ${span + offset}) * ${offset})` : "unset",
				};
			} else {
				return {
					gridColumn: `span ${span + offset > cols.value ? cols.value : span + offset}/span ${
						span + offset > cols.value ? cols.value : span + offset
					}`,
					marginLeft: offset !== 0 ? `calc(((100% + ${gap}px) / ${span + offset}) * ${offset})` : "unset",
				};
			}
		});

		useRender(() => (
			<div style={style.value} vShow={states.show}>
				{slots.default && slots.default(states)}
			</div>
		));

		expose({
			props,
			emit,
			states,
		});

		return {
			props,
			emit,
			states,
		};
	},
});
