import { type Ref, computed } from "vue";
import type { TableInstance } from "element-plus";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useTable = (tableRef: Ref<TableInstance>) => {
	const clearSelection = (): void => tableRef?.value?.clearSelection();
	const getSelectionRows = (): any => tableRef?.value?.getSelectionRows();
	const toggleRowSelection = (row: any, selected?: boolean | undefined): void => tableRef?.value?.toggleRowSelection(row, selected);
	const toggleAllSelection = (): void => tableRef?.value?.toggleAllSelection();
	const toggleRowExpansion = (row: any, expanded?: boolean | undefined): void => tableRef?.value?.toggleRowExpansion(row, expanded);
	const setCurrentRow = (row: any): void => tableRef?.value?.setCurrentRow(row);
	const clearSort = (): void => tableRef?.value?.clearSort();
	const clearFilter = (columnKeys?: string[] | undefined): void => tableRef?.value?.clearFilter(columnKeys);
	const doLayout = (): void => tableRef?.value?.doLayout();
	const sort = (prop: string, order: string): void => tableRef?.value?.sort(prop, order);
	const scrollTo = (options: number | ScrollToOptions, yCoord?: number | undefined): void => tableRef?.value?.scrollTo(options, yCoord);
	const setScrollTop = (top?: number | undefined): void => tableRef?.value?.setScrollTop(top);
	const setScrollLeft = (left?: number | undefined): void => tableRef?.value?.setScrollLeft(left);
	const columns = computed(() => tableRef?.value?.columns)?.value;

	return {
		clearSelection,
		getSelectionRows,
		toggleRowSelection,
		toggleAllSelection,
		toggleRowExpansion,
		setCurrentRow,
		clearSort,
		clearFilter,
		doLayout,
		sort,
		scrollTo,
		setScrollTop,
		setScrollLeft,
		columns,
	};
};
