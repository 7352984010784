import { reactive } from "vue";
import { defineStore } from "pinia";
import type { Router } from "vue-router";
import type { INavBarTab, NavTabs } from "./interface";
import { routerUtil } from "@gejia-element-plus/utils";

export const useNavTabs = defineStore(
	"navTabs",
	() => {
		const states: NavTabs = reactive({
			// 激活tab的index
			activeIndex: -1,
			// 最后一个激活tab的index
			lastActiveIndex: -1,
			// 激活的tab
			activeTab: null,
			// 导航栏tab列表
			navBarTabs: [],
			// keep-alive缓存组件名称集合
			keepAliveComponentNameList: [],
			// 内容区放大
			contentLarge: false,
			// 当前tab是否全屏
			contentFull: false,
		});

		/**
		 * 重置
		 */
		const $reset = (): void => {
			states.activeIndex = -1;
			states.lastActiveIndex = -1;
			states.activeTab = null;
			states.navBarTabs = [];
			states.keepAliveComponentNameList = [];
			states.contentLarge = false;
			states.contentFull = false;
		};

		/**
		 * 刷新 Tab
		 */
		const refreshTab = (route: INavBarTab): void => {
			const fIdx = states.keepAliveComponentNameList.findIndex((f) => f === route.name.toString());
			if (fIdx >= 0) {
				states.keepAliveComponentNameList.splice(fIdx, 1);
			}
			routerUtil.routePushSafe({ path: `/redirect${route.path}`, query: route.query });
		};

		/**
		 * 添加 Tab
		 */
		const addTab = (route: INavBarTab): void => {
			const fRouteIdx = states.navBarTabs.findIndex((f) => f.path == route.path);
			//  判断警告页面数量
			if (fRouteIdx === -1) {
				states.navBarTabs.push(routerUtil.pickByRoute(route));
				if (route.meta.keepAlive !== false) {
					if (!states.keepAliveComponentNameList.includes(route.name.toString())) {
						states.keepAliveComponentNameList.push(route.name.toString());
					}
				} else {
					const fIdx = states.keepAliveComponentNameList.findIndex((f) => f === route.name.toString());
					if (fIdx >= 0) {
						states.keepAliveComponentNameList.splice(fIdx, 1);
					}
				}
			} else {
				// 存在更新
				states.navBarTabs[fRouteIdx] = routerUtil.pickByRoute(route);
				if (route.meta.keepAlive !== false) {
					if (!states.keepAliveComponentNameList.includes(route.name.toString())) {
						states.keepAliveComponentNameList.push(route.name.toString());
					}
				} else {
					const fIdx = states.keepAliveComponentNameList.findIndex((f) => f === route.name.toString());
					if (fIdx >= 0) {
						states.keepAliveComponentNameList.splice(fIdx, 1);
					}
				}
			}
		};

		const toLastTab = (): void => {
			const lastTab = states.navBarTabs.slice(-1)[0];
			if (lastTab) {
				routerUtil.routePush(lastTab?.fullPath ?? lastTab?.path);
			} else {
				routerUtil.routePush({ path: "/" });
			}
		};

		/**
		 * 关闭 Tab
		 */
		const closeTab = (route: INavBarTab): void => {
			if (route?.meta?.affix === true) return;
			const findIndex = states.navBarTabs.findIndex((f) => f.path === route.path);
			if (findIndex >= 0) {
				states.navBarTabs.splice(findIndex, 1);
			}
			const fIdx = states.keepAliveComponentNameList.findIndex((f) => f === route.name.toString());
			if (fIdx >= 0) {
				states.keepAliveComponentNameList.splice(fIdx, 1);
			}
			if (states.lastActiveIndex !== -1 && states.lastActiveIndex !== states.activeIndex && states.lastActiveIndex < states.navBarTabs.length) {
				const lastTab = states.navBarTabs[states.lastActiveIndex];
				routerUtil.routePush(lastTab?.fullPath ?? lastTab?.path);
			} else {
				toLastTab();
			}
		};

		/**
		 * 关闭多个 Tab
		 * @param retainRoute 保留的路由，否则关闭全部标签
		 */
		const closeTabs = (retainRoute: INavBarTab | false = false): void => {
			const affixNavBarTabs = states.navBarTabs.filter((f) => f?.meta?.affix === true);
			states.keepAliveComponentNameList = affixNavBarTabs.filter((f) => f?.meta?.keepAlive !== false).map((m) => m.name.toString());
			if (retainRoute) {
				states.navBarTabs = [...affixNavBarTabs, retainRoute];
				if (retainRoute.meta.keepAlive !== false) {
					states.keepAliveComponentNameList.push(retainRoute.name.toString());
				}
			} else {
				states.navBarTabs = [...affixNavBarTabs];
			}
			toLastTab();
		};

		/**
		 * 设置活动路由
		 */
		const setActiveRoute = (route: INavBarTab): void => {
			const fIdx = states.navBarTabs.findIndex((f) => f.path == route.path);
			if (fIdx === -1) return;
			states.activeTab = routerUtil.pickByRoute(route);
			states.lastActiveIndex = states.activeIndex;
			states.activeIndex = fIdx;
		};

		/**
		 * 设置放大
		 */
		const setContentLarge = (contentLarge: boolean): void => {
			states.contentLarge = contentLarge;
		};

		/**
		 * 设置全屏
		 */
		const setContentFull = (contentFull: boolean): void => {
			states.contentFull = contentFull;
		};

		/**
		 * 初始化
		 */
		const initNavBarTabs = (router: Router): void => {
			// 直接默认查找 layout 下的路由，其余的直接忽略
			const allRoutes = router
				.getRoutes()
				.find((f) => f.name === "layout")
				?.children.filter((f) => !(f.meta?.hidden === true));

			// 扁平化获取固定的标签
			const flRoutes = routerUtil.flattenRoutes(allRoutes);

			const affixNavBarTabs = flRoutes.filter((f) => f?.meta?.affix === true);
			affixNavBarTabs.forEach((item) => {
				if (!states.keepAliveComponentNameList.includes(item.name.toString())) {
					states.keepAliveComponentNameList.push(item.name.toString());
				}
			});

			const oldNavBarTabs = states.navBarTabs.filter((f) => f?.meta?.affix !== true);
			oldNavBarTabs.forEach((item) => {
				if (!states.keepAliveComponentNameList.includes(item.name.toString())) {
					states.keepAliveComponentNameList.push(item.name.toString());
				}
			});

			states.navBarTabs = [...affixNavBarTabs, ...oldNavBarTabs];
		};

		return { states, $reset, refreshTab, addTab, closeTab, closeTabs, setActiveRoute, setContentLarge, setContentFull, initNavBarTabs };
	},
	{
		persist: {
			key: "store-nav-tabs",
		},
	}
);

export * from "./interface";
