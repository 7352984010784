import type { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import axios, { AxiosError } from "axios";
import type { LoadingOptions } from "element-plus";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import { CacheKey } from "@gejia-element-plus/constants";
import type { AxiosOptions } from "@gejia-element-plus/stores";
import { pinia, useGejiaApp } from "@gejia-element-plus/stores";
import { AESCrypto, Local, downloadUtil, typeUtil } from "@gejia-element-plus/utils";

const pendingMap = new Map();

/**
 * 登录弹出层的变量
 */
let loginCallBack = false;

/**
 * 加载实例
 */
const loadingInstance = {
	// ElLoading 的实例信息
	target: null,
	// 总数
	count: 0,
};

/**
 * 请求类型
 */
export type RequestType = "auth" | "query" | "add" | "edit" | "delete" | "import" | "export" | "download" | "upload" | "other";

/**
 * Http 错误状态码处理
 */
function httpErrorStatusHandle(error: AxiosError | any, errorCodeMessages: anyObj): void {
	// 判断请求是否被取消
	if (axios.isCancel(error)) {
		console.error("[gejia-axios]", errorCodeMessages["cancelDuplicate"]);
		return;
	}
	let message = "";
	// 判断是否断网
	if (!window.navigator.onLine) {
		message = errorCodeMessages["offLine"];
	} else {
		// 其他错误码处理
		// 尝试获取 Restful 风格返回Code，或者获取响应状态码
		const code = error?.response?.data?.code || error?.response?.status || error?.code || "default";
		// 400业务异常
		// 500服务器内部错误，可能返回错误信息
		message = error?.response?.data?.message || errorCodeMessages[code];
	}
	ElMessage.error(message);
}

/**
 * 重新登录处理
 */
function reloadLoginHandle(
	response: AxiosResponse,
	reloadLoginCodes: Readonly<Array<number>>,
	reloadLoginMessage: string,
	reloadLoginButtonText: string,
	// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
	loginCallBackFunc: Function
): boolean {
	// 尝试获取 Restful 风格返回Code，或者获取响应状态码
	const code = response?.data?.code || response?.status;
	if (code && reloadLoginCodes.includes(code)) {
		if (!loginCallBack) {
			loginCallBack = true;
			ElMessageBox.alert(reloadLoginMessage, {
				type: "warning",
				showClose: false,
				confirmButtonText: reloadLoginButtonText,
				callback: () => {
					loginCallBack = false;
					loginCallBackFunc();
				},
			});
		}
		return true;
	}
	return false;
}

/**
 * 关闭Loading层实例
 */
function closeLoading(options: AxiosOptions): void {
	if (options.loading && loadingInstance.count > 0) loadingInstance.count--;
	if (loadingInstance.count === 0) {
		loadingInstance.target.close();
		loadingInstance.target = null;
	}
}

/**
 * 生成每个请求的唯一key
 */
function getPendingKey(axiosConfig: AxiosRequestConfig): string {
	let { data } = axiosConfig;
	const { url, method, params, headers } = axiosConfig;
	// response里面返回的config.data是个字符串对象
	if (typeof data === "string") data = JSON.parse(data);
	return [
		url,
		method,
		headers && (headers as anyObj)["Authorization"] ? (headers as anyObj)["Authorization"] : "",
		headers && (headers as anyObj)["X-Authorization"] ? (headers as anyObj)["X-Authorization"] : "",
		JSON.stringify(params),
		JSON.stringify(data),
	].join("&");
}

/**
 * 储存每个请求的唯一cancel回调, 以此为标识
 */
function addPending(axiosConfig: AxiosRequestConfig): void {
	const pendingKey = getPendingKey(axiosConfig);
	axiosConfig.cancelToken =
		axiosConfig.cancelToken ||
		new axios.CancelToken((cancel) => {
			if (!pendingMap.has(pendingKey)) {
				pendingMap.set(pendingKey, cancel);
			}
		});
}

/**
 * 删除重复的请求
 */
function removePending(axiosConfig: AxiosRequestConfig): void {
	const pendingKey = getPendingKey(axiosConfig);
	if (pendingMap.has(pendingKey)) {
		const cancelToken = pendingMap.get(pendingKey);
		cancelToken(pendingKey);
		pendingMap.delete(pendingKey);
	}
}

/**
 * 创建 Axios
 */
const createAxios = <T = any>(
	/**
	 * axios 请求配置
	 */
	axiosConfig: AxiosRequestConfig & {
		requestType: RequestType;
	} & AxiosOptions,
	/**
	 * 加载配置
	 */
	loading: LoadingOptions = {}
): ApiPromise<T> => {
	const gejiaAppStore = useGejiaApp(pinia);
	// 合并选项
	const options = { ...gejiaAppStore.states.axiosOptions, ...axiosConfig };

	// 只有GET请求和query类型才可以进行缓存处理，且默认是不存在loading的
	if (options.cache && axiosConfig.method.toUpperCase() === "GET" && axiosConfig.requestType === "query") {
		const cacheKey = `${CacheKey.HTTP_CACHE}${axiosConfig.url}`;

		// 如果存在缓存，默认是不能存在参数的
		if (axiosConfig.params) {
			delete axiosConfig.params;
			console.warn("[gejia-axios]", "如果使用Http Cache，则不能存在Params参数");
		}

		const cacheResult = Local.get<ApiResponse<T>>(cacheKey);

		if (cacheResult) {
			// 这里只能使用简洁响应
			return Promise.resolve(cacheResult);
		}
	} else {
		// 上面的不满足，默认不适用缓存
		options.cache = false;
	}

	const timestamp = new Date().getTime();

	// 创建 Axios 请求
	const Axios = axios.create({
		baseURL: gejiaAppStore.states.axiosConfig.baseUrl,
		timeout: gejiaAppStore.states.axiosConfig.timeout,
		headers: {
			// 配置请求来源，标识为PC端
			"Gejia-DeviceType": "Web",
			"Gejia-DeviceID": Local.get(CacheKey.DEVICE_ID),
		},
		responseType: "json",
	});

	/**
	 * 请求拦截
	 */
	Axios.interceptors.request.use(
		(config: InternalAxiosRequestConfig<any>) => {
			// 删除重复请求
			removePending(config);

			// 判断是否开启取消重复请求
			options.cancelDuplicateRequest && addPending(config);

			// 判断是否显示loading层
			if (options.loading) {
				loadingInstance.count++;
				if (loadingInstance.count === 1) {
					// 合并 Loading 配置
					loading = { ...loading, ...gejiaAppStore.states.axiosConfig.loading };

					loadingInstance.target = ElLoading.service(loading);
				}
			}

			if (config.headers) {
				if (gejiaAppStore.states.axiosConfig.getToken) {
					// Token 处理
					const { token, refreshToken } = gejiaAppStore.states.axiosConfig.getToken();
					if (token) {
						config.headers["Authorization"] = token;
					}
					// 刷新 Token
					refreshToken && (config.headers["X-Authorization"] = refreshToken);
				}
			}

			// 请求参数加密
			if (gejiaAppStore.states.axiosConfig.requestCipher) {
				let requestData = config.params || config.data;
				const dataStr = JSON.stringify(requestData);
				if (dataStr != null && dataStr != "" && dataStr != "{}") {
					// eslint-disable-next-line no-console
					console.debug("[gejia-axios]", `HTTP request data("${config.url}")`, requestData);
					const decryptData = AESCrypto.encrypt(dataStr, `${timestamp}`, `FIV${timestamp}`);
					// 组装请求格式
					requestData = {
						data: decryptData,
						timestamp,
					};
					switch (config.method.toUpperCase()) {
						case "GET":
						case "DELETE":
						case "HEAD":
							config.params = requestData;
							break;
						case "POST":
						case "PUT":
						case "PATCH":
							config.data = requestData;
							break;
						case "OPTIONS":
						case "CONNECT":
						case "TRACE":
							throw new Error("This request mode is not supported.");
					}

					// 请求头部增加加密标识
					config.headers["Gejia-Request-Encipher"] = "true";
				}
			} else {
				// GET 请求缓存处理
				if (config.method.toUpperCase() === "GET") {
					config.params = config.params || {};
					config.params._ = timestamp;
				}
			}

			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	/**
	 * 响应拦截
	 */
	Axios.interceptors.response.use(
		(response: AxiosResponse) => {
			// 删除重复请求标识
			removePending(response.config);
			// 关闭loading层
			options.loading && closeLoading(options);
			// 判断是否忽略错误
			if (options.ignoreError) {
				return Promise.resolve(response);
			}

			// 设置 Token
			gejiaAppStore.states.axiosConfig.setToken && gejiaAppStore.states.axiosConfig.setToken(response);

			// 重新登录处理
			if (
				reloadLoginHandle(
					response,
					gejiaAppStore.states.axiosConfig.reloadLoginCodes,
					gejiaAppStore.states.axiosConfig.reloadLoginMessage,
					gejiaAppStore.states.axiosConfig.reloadLoginButtonText,
					() => {
						gejiaAppStore.states.axiosConfig.logout && gejiaAppStore.states.axiosConfig.logout();
					}
				)
			) {
				return Promise.reject(new AxiosError("重新登录"));
			}

			switch (response.config.responseType) {
				// 配置了blob，不处理直接返回文件流
				case "blob":
					if (response.status === 200) {
						// 判断是否自动下载
						if (options.autoDownloadFile === true) {
							// 下载文件
							downloadUtil.downloadFile(response);
						}
						return Promise.resolve({ ...response, response });
					} else {
						ElMessage.error(gejiaAppStore.states.axiosConfig.errorCodeMessages["fileDownloadError"]);
						return Promise.reject(new AxiosError(gejiaAppStore.states.axiosConfig.errorCodeMessages["fileDownloadError"]));
					}
				// 正常 JSON 格式响应处理
				case "json": {
					// 获取 Restful 风格返回数据
					const responseData = response.data as ApiResponse;
					const code = responseData.code;
					if (code < 200 || code > 299 || !responseData.success) {
						// 判断是否显示错误消息
						if (options.showCodeMessage) {
							// 判断返回的 message 是否为对象类型
							if (typeUtil.isObject(responseData.message) && responseData.message) {
								ElMessage.error(JSON.stringify(responseData.message));
							} else {
								ElMessage.error(responseData.message);
							}
						}
						return Promise.reject({ ...responseData, response });
					}

					// 请求响应解密
					if (gejiaAppStore.states.axiosConfig.requestCipher) {
						if (responseData.data) {
							responseData.data = AESCrypto.decrypt(responseData.data, `${responseData.timestamp}`, `FIV${responseData.timestamp}`);
							// 处理 ""xxx"" 这种数据
							if (typeof responseData.data == "string" && responseData.data.startsWith('"') && responseData.data.endsWith('"')) {
								responseData.data = responseData.data.replace(/"/g, "");
							}
							// eslint-disable-next-line no-console
							console.debug("[gejia-axios]", `HTTP response data("${response.config.url}")`, responseData.data);
						}
					}

					// 判断是否缓存
					if (options.cache) {
						const cacheKey = `${CacheKey.HTTP_CACHE}${axiosConfig.url}`;
						// 默认缓存24小时
						Local.set(cacheKey, responseData, 60 * 24);
					}

					// 简洁响应
					return Promise.resolve<ApiResponse<T>>({ ...responseData, response });
				}
				default:
					// 简洁响应
					return Promise.resolve({ ...response.data, response });
			}
		},
		(error: AxiosError | any) => {
			// 删除重复请求标识
			error?.config && removePending(error?.config);
			// 关闭loading层
			options.loading && closeLoading(options);
			// 判断是否忽略错误
			if (options.ignoreError) {
				return Promise.resolve(error);
			}
			// 判断请求是否被取消
			if (!axios.isCancel(error)) {
				console.error("[gejia-axios]", error);
			}
			// 重新登录处理
			if (
				reloadLoginHandle(
					error?.response,
					gejiaAppStore.states.axiosConfig.reloadLoginCodes,
					gejiaAppStore.states.axiosConfig.reloadLoginMessage,
					gejiaAppStore.states.axiosConfig.reloadLoginButtonText,
					() => {
						gejiaAppStore.states.axiosConfig.logout && gejiaAppStore.states.axiosConfig.logout();
					}
				)
			) {
				return Promise.reject(new AxiosError("重新登录"));
			}
			// 处理错误状态码
			options.showErrorMessage && httpErrorStatusHandle(error, gejiaAppStore.states.axiosConfig.errorCodeMessages);
			// 错误继续返回给到具体页面
			return Promise.reject(error);
		}
	);

	return Axios(axiosConfig) as ApiPromise<T>;
};

/**
 * 检测版本更新
 */
async function versionUpdate(version: string): Promise<void> {
	// eslint-disable-next-line no-console
	console.log("[gejia-axios]", `当前版本 ${version}`);

	const response = await axios.get<{ version: string; dateTime: string }>(`/version.json?_=${new Date().getTime()}`);

	const gejiaAppStore = useGejiaApp(pinia);

	// 开发环境判断
	if (gejiaAppStore.states.viteEnv === "development") return;

	if (version !== response.data.version) {
		// eslint-disable-next-line no-console
		console.log("[gejia-axios]", `发现新版本 ${response.data.version}`);
		ElMessageBox.confirm(`发现新版本 ${response.data.version}，是否立即更新？`, {
			type: "warning",
			confirmButtonText: "更新",
			closeOnClickModal: false,
		})
			.then(() => {
				// eslint-disable-next-line no-console
				console.log("[gejia-axios]", `更新版本 ${response.data.version}`);
				// // 更新的时候关闭所有的Tab
				// const navTabsStore = useNavTabs(pinia);
				// // 重置整个Tab持久化
				// navTabsStore.$reset();
				// 强制刷新浏览器
				window.location.reload();
			})
			.catch(() => {
				console.warn("[gejia-axios]", `取消更新版本 ${response.data.version}`);
				ElMessage.warning({
					message: "您取消了更新，将在十分钟后再次进行提示！",
				});
			});
		// ElMessage.info({
		//     message: `发现新版本 ${response.data.version}，自动更新中...`,
		//     duration: 1500,
		//     onClose: () => {
		//         // 强制刷新浏览器
		//         window.location.reload();
		//     },
		// });
	}
}

/**
 * 10分钟检测一次版本更新
 */
const checkVersionUpdate = async (version: string): Promise<void> => {
	versionUpdate(version);
	setInterval(
		() => {
			versionUpdate(version);
		},
		10 * 60 * 1000
	);
};

export const axiosUtil = {
	/**
	 * 请求
	 */
	request: createAxios,
	/**
	 * 删除HTTP 缓存数据
	 */
	deleteHttpCache: (): void => {
		Local.removeByPrefix(CacheKey.HTTP_CACHE);
	},
	checkVersionUpdate,
};
