import type { ExtractPropTypes } from "vue";
import type { gInputDialogPageEmits, gInputDialogPageProps } from "./src/define";
import InputDialogPage from "./src/inputDialogPage";
import { withInstall } from "@gejia-element-plus/utils";

export const GInputDialogPage = withInstall(InputDialogPage);
export default GInputDialogPage;

export * from "./src/define";
export * from "./src/type";

export type GInputDialogPageInstance = InstanceType<typeof InputDialogPage>;

export type GInputDialogPageProps = ExtractPropTypes<typeof gInputDialogPageProps>;

export type GInputDialogPageEmits = typeof gInputDialogPageEmits;
