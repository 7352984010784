import { type Ref, computed, nextTick } from "vue";
import type { ValidateFieldsError } from "async-validator";
import type { FormInstance, FormItemProp, FormValidateCallback, FormValidationResult } from "element-plus";
import { formUtil } from "@gejia-element-plus/utils";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useForm = (formRef: Ref<FormInstance>) => {
	const validate = (): Promise<void | ValidateFieldsError> => formUtil.validate(formRef);
	const validateScrollToField = (): Promise<void | ValidateFieldsError> => formUtil.validateScrollToField(formRef);
	const validateField = (formProps?: FormItemProp[], callback?: FormValidateCallback): FormValidationResult => {
		// eslint-disable-next-line vue/valid-next-tick
		return nextTick(() => {
			return formRef?.value?.validateField(formProps, callback);
		});
	};
	const resetFields = (formProps?: FormItemProp[]): void => {
		nextTick(() => {
			formRef?.value?.resetFields(formProps);
		});
	};
	const scrollToField = (formProp?: FormItemProp): void => {
		nextTick(() => {
			formRef?.value?.scrollToField(formProp);
		});
	};
	const clearValidate = (formProps?: FormItemProp[]): void => {
		nextTick(() => {
			formRef?.value?.clearValidate(formProps);
		});
	};
	const fields = computed(() => {
		return formRef?.value?.fields;
	});

	return {
		validate,
		validateScrollToField,
		validateField,
		resetFields,
		scrollToField,
		clearValidate,
		fields,
	};
};
