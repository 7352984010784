import type { ExtractPropTypes } from "vue";
import type { gTableColumnProps, gTableEmits, gTableProps } from "./src/define";
import Table from "./src/table";
import TableColumn from "./src/tableColumn";
import TableColumnsSettingDialog from "./src/tableColumnsSettingDialog";
import TableExportExcelDialog from "./src/tableExportExcelDialog";
import TablePagination from "./src/tablePagination";
import TableSearchForm from "./src/tableSearchForm";
import TableSearchFormItem from "./src/tableSearchFormItem";
import { withInstall, withNoopInstall } from "@gejia-element-plus/utils";

export const GTable = withInstall(Table, {
	TableColumn,
	TableColumnsSettingDialog,
	TableExportExcelDialog,
	TablePagination,
	TableSearchForm,
	TableSearchFormItem,
});
export default GTable;

export const GTableColumn = withNoopInstall(TableColumn);

export * from "./src/define";
export * from "./src/type";

export type GTableInstance = InstanceType<typeof Table>;

export type GTableProps = ExtractPropTypes<typeof gTableProps>;

export type GTableEmits = typeof gTableEmits;

export type GTableColumnInstance = InstanceType<typeof TableColumn>;

export type GTableColumnProps = ExtractPropTypes<typeof gTableColumnProps>;
