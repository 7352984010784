import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { gLayoutLogoProps } from "./define";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GLayoutLogo 组件
 */
export default defineComponent({
	name: "GLayoutLogo",
	components: {},
	props: gLayoutLogoProps,
	setup(props, { attrs, expose }) {
		const router = useRouter();

		useRender(() => (
			<div class="g-layout-logo" onClick={() => router.push("/")} title="首页">
				<img class="g-layout-logo__img" src={props.logo} alt="Logo" />
				<div class="g-layout-logo__site-name" title={props.siteName}>
					{props.siteName}
				</div>
			</div>
		));

		expose({});

		return {
			attrs,
		};
	},
});
