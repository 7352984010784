import type { ExtractPropTypes } from "vue";
import ContextMenu from "./src/contextMenu";
import type { gContextMenuEmits, gContextMenuProps } from "./src/define";
import { withInstall } from "@gejia-element-plus/utils";

export const GContextMenu = withInstall(ContextMenu);
export default GContextMenu;

export * from "./src/define";
export * from "./src/type";

export type GContextMenuInstance = InstanceType<typeof ContextMenu>;

export type GContextMenuProps = ExtractPropTypes<typeof gContextMenuProps>;

export type GContextMenuEmits = typeof gContextMenuEmits;
