import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { ElSelect } from "element-plus";
import { SelectProps, gSelectEmits, gSelectProps } from "./define";
import GSelectOption from "./selectOption";
import type { GSelectStates } from "./type";
import { useSelect } from "./useSelect";
import { errorHandler, typeUtil, useProps, useRender, withTryNumber } from "@gejia-element-plus/utils";

/**
 * GSelect 组件
 */
export default defineComponent({
	name: "GSelect",
	components: {
		ElSelect,
	},
	props: gSelectProps,
	emits: gSelectEmits,
	setup(props, { attrs, slots, emit, expose }) {
		const states: GSelectStates = reactive({
			value: undefined,
			label: undefined,
			loading: false,
			selectorData: [],
			debut: true,
			echo: props.data?.length > 0 ? false : true,
			nextRefresh: false,
		});

		const selectRef = ref<InstanceType<typeof ElSelect>>();

		const handleSelectorData = (selectorData: ElSelectorOutput[] | any[]): any[] => {
			return selectorData.map((item) => {
				return { ...item, [props.valueKey]: withTryNumber(item[props.valueKey]) };
			});
		};

		const loadData = (): void => {
			// 判断是否需要自动请求
			if (props.requestApi) {
				states.loading = true;
				const param = props.initParam ?? {};
				props
					.requestApi(param)
					.then((apiResult) => {
						// 这里不允许回显了
						states.echo = false;
						states.selectorData = handleSelectorData(apiResult.data);
						emit("dataChangeCallBack", states.selectorData);
					})
					.catch((error) => {
						console.error("[gejia-GSelect]", error);
						states.selectorData = [];
						errorHandler(error);
					})
					.finally(() => {
						states.loading = false;
					});
			} else {
				states.selectorData = handleSelectorData(props.data);
			}
		};

		const handleChange = (value: number | string | boolean | object | any[]): void => {
			if (typeUtil.isEqual(props.modelValue, value)) return;

			if (value != undefined && value != null) {
				const data = states.selectorData.find((f) => f[props.valueKey] == value);
				states.value = value;
				states.label = data[props.labelKey];
				emit("update:modelValue", value);
				emit("update:label", states.label);
				emit("change", data);
			} else {
				states.value = null;
				states.label = null;
				emit("update:modelValue", null);
				emit("update:label", null);
				emit("change", null);
			}
		};

		const handleVisibleChange = (visible: boolean): void => {
			if (visible) {
				if (states.debut) {
					// 首次出现
					states.debut = false;
					// 懒加载
					!props.defaultSelect && props.lazy && loadData();
				} else {
					// 判断再次出现是否需要刷新数据
					if (states.nextRefresh) {
						states.nextRefresh = false;
						loadData();
					}
				}
			}
			emit("visibleChange", visible);
		};

		/**
		 * 设置选中
		 */
		const setSelected = (value: number | string | boolean | object | any[]): void => {
			handleChange(value);
		};

		/**
		 * 清空选中
		 */
		const clearSelected = (): void => {
			handleChange(null);
		};

		watch(
			() => props.initParam,
			(newValue, oldValue) => {
				if (!typeUtil.isEqual(newValue, oldValue)) {
					states.nextRefresh = true;
					clearSelected();
				}
			}
		);

		watch(
			() => props.modelValue,
			(newValue) => {
				if (states.echo && newValue != undefined && newValue != null) {
					states.selectorData = [
						{
							[props.valueKey]: withTryNumber(newValue),
							[props.labelKey]: props.label,
						},
					];
					states.value = withTryNumber(newValue);
				} else {
					states.value = withTryNumber(newValue);
				}
			},
			{
				immediate: true,
			}
		);

		onMounted(() => {
			if (props.defaultSelect) {
				loadData();
				if (states.selectorData.length > 0) {
					// 默认选中第一个
					states.value = states.selectorData[0][props.valueKey];
				}
			}
			// 判断是否为本地数据
			else if (!props.requestApi && props.data?.length > 0) {
				states.debut = false;
				loadData();
			}
			// 判断是否非默认选中,且未启用懒加载
			else if (!props.lazy) {
				loadData();
			}
		});

		const bindProps = useProps(props, SelectProps, ["modelValue", "loading"], {
			loadingText: "加载中...",
			noMatchText: "暂无匹配的数据",
			noDataText: "暂无数据",
			collapseTags: true,
			collapseTagsTooltip: true,
		});

		useRender(() => (
			<ElSelect
				{...attrs}
				{...bindProps.value}
				ref={selectRef}
				class="g-select"
				popperClass="g-select-dropdown"
				style={{ width: props.width }}
				vModel={states.value}
				loading={states.loading}
				onChange={handleChange}
				onVisibleChange={handleVisibleChange}
			>
				{states.selectorData.map((item) => (
					<GSelectOption
						vSlots={slots}
						data={item}
						valueKey={props.valueKey}
						labelKey={props.labelKey}
						childrenKey={props.childrenKey}
						disabledKey={props.disabledKey}
						moreDetail={props.moreDetail}
					/>
				))}
			</ElSelect>
		));

		expose({
			states,
			refresh: loadData,
			setSelected,
			clearSelected,
			...useSelect(selectRef),
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			refresh: loadData,
			setSelected,
			clearSelected,
			...useSelect(selectRef),
		};
	},
});
