import type { SlotsType } from "vue";
import { computed, defineComponent, inject, reactive, ref, watch, withModifiers } from "vue";
import { Delete, Edit, UploadFilled, ZoomIn } from "@element-plus/icons-vue";
import { Decimal } from "decimal.js";
import type { UploadFile, UploadFiles, UploadInstance, UploadProps, UploadRequestOptions, UploadUserFile } from "element-plus";
import { ElIcon, ElImageViewer, ElMessage, ElNotification, ElUpload, formContextKey, formItemContextKey, genFileId, uploadProps } from "element-plus";
import { gUploadImageEmits, gUploadImageProps, type gUploadImageSlots } from "./define";
import type { GUploadImageStates } from "./type";
import { useUpload } from "@gejia-element-plus/components/upload/src/useUpload";
import { GMimeType } from "@gejia-element-plus/constants";
import { stringUtil, uploadUtil, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GUploadImage 组件
 */
export default defineComponent({
	name: "GUploadImage",
	components: {
		ElUpload,
		ElImageViewer,
		ElIcon,
		Edit,
		ZoomIn,
		Delete,
		UploadFilled,
	},
	props: gUploadImageProps,
	emits: gUploadImageEmits,
	slots: Object as SlotsType<typeof gUploadImageSlots>,
	setup(props, { attrs, slots, emit, expose }) {
		// 获取 el-form 组件上下文
		const formContext = inject(formContextKey, undefined);
		// 获取 el-form-item 组件上下文
		const formItemContext = inject(formItemContextKey, undefined);

		const states: GUploadImageStates = reactive({
			uploadKey: `g-upload-image__${stringUtil.generateRandomString(8)}`,
			loading: false,
			disabled: computed(() => {
				return props.disabled || formContext?.disabled;
			}),
			preview: false,
			fileList: [],
			previewList: [],
		});

		const uploadRef = ref<UploadInstance>();

		const handleValue = (): void => {
			if (states.fileList.length > 0) {
				emit("update:modelValue", states.fileList[0].url);
				emit("change", states.fileList[0].url);
			} else {
				emit("update:modelValue", null);
				emit("change", null);
			}
		};

		const handleEdit = (): void => {
			const uploadInputEl = document.querySelector(`.${states.uploadKey} .el-upload__input`);
			uploadInputEl && uploadInputEl.dispatchEvent(new MouseEvent("click"));
		};

		const handlePreview = (): void => {
			states.previewList = [states.fileList[0].url];
			states.preview = true;
		};

		const handleRemove = (): void => {
			const fIdx = states.fileList.findIndex((f) => f.url === states.fileList[0].url);
			if (fIdx !== -1) {
				states.fileList.splice(fIdx, 1);
			}
			handleValue();
		};

		const handleBeforeUpload: UploadProps["beforeUpload"] = (rawFile) => {
			const fileSizeKB = new Decimal(rawFile.size).div(new Decimal(1024));
			if (fileSizeKB.greaterThan(new Decimal(props.maxSize))) {
				const maxSizeMB = new Decimal(props.maxSize).div(new Decimal(1024));
				console.warn("[gejia-GUploadImage]", `【${rawFile.name}】图片上传大小不能超过 ${maxSizeMB.toString()}MB`);
				ElMessage.warning(`【${rawFile.name}】图片上传大小不能超过 ${maxSizeMB.toString()}MB`);
				return false;
			}
			if (props.accept && props.accept.split(",").every((e) => e !== rawFile.type)) {
				const uploadFileNames = uploadUtil.detectFileType(props.accept);
				ElMessage.error(`只允许上传【${uploadFileNames}】类型的图片`);
				console.error("[gejia-GUploadImage]", `只允许上传【${uploadFileNames}】类型的图片`);
				return false;
			}
			if (props.beforeUpload) {
				return props.beforeUpload(rawFile);
			}
			return true;
		};

		const handleHttpRequest = async (options: UploadRequestOptions): Promise<void> => {
			if (!props.fileType) {
				ElMessage.error("上传文件类型不能为空");
				console.error("[gejia-GUploadImage]", "上传文件类型 “fileType” 不能为空");
				return;
			}
			let propsData: anyObj;
			if (props.data) {
				propsData = uploadUtil.getPropsData(options.file, props.data);
			}
			states.loading = true;
			try {
				const fileUrl = await uploadUtil.uploadFile(options.file, options.filename, props.fileType, propsData);
				options.onSuccess(fileUrl);
			} finally {
				states.loading = false;
			}
		};

		const handleOnSuccess = (fileUrl: string, uploadFile: UploadFile, uploadFiles: UploadFiles): void => {
			if (!fileUrl) return;
			if (uploadFiles.length > 1) {
				uploadFiles.shift();
			}
			uploadFile.url = fileUrl;
			handleValue();
			// 调用 el-form 内部的校验方法（可自动校验）
			formItemContext?.prop && formContext?.validateField([formItemContext.prop as string]);
			ElMessage.success("上传成功");
			props.onSuccess && props.onSuccess(fileUrl, uploadFile, uploadFiles);
		};

		const handleOnError = (error: Error, uploadFile: UploadFile, uploadFiles: UploadFiles): void => {
			ElNotification({
				message: `【${uploadFile.name}】图片上传失败，请您重新上传`,
				type: "error",
			});
			props.onError && props.onError(error, uploadFile, uploadFiles);
		};

		const handleOnExceed = (files: File[], uploadFiles: UploadUserFile[]): void => {
			ElMessage.warning(`最多只能上传 ${props.limit} 个图片，请移除后再进行上传`);
			props.onExceed && props.onExceed(files, uploadFiles);
		};

		watch(
			() => states.fileList,
			(newValue) => {
				emit("update:fileList", newValue);
			}
		);

		/**
		 * 监听 v-model 绑定数据
		 */
		watch(
			() => props.modelValue,
			(newValue) => {
				states.fileList = [];
				if (newValue) {
					if (Array.isArray(newValue)) {
						newValue.forEach((item) => {
							states.fileList.push({
								name: "",
								status: "success",
								uid: genFileId(),
								url: item,
							});
						});
					} else {
						states.fileList.push({
							name: "",
							status: "success",
							uid: genFileId(),
							url: newValue,
						});
					}
				}
			},
			{
				immediate: true,
			}
		);

		const bindProps = useProps(props, uploadProps, ["fileList", "disabled", "httpRequest", "beforeUpload", "onExceed", "onSuccess", "onError"], {
			autoUpload: true,
			showFileList: false,
			drag: true,
			multiple: false,
			listType: "picture",
			accept: GMimeType.Image,
		});

		useRender(() => (
			<>
				<ElUpload
					{...attrs}
					{...bindProps.value}
					ref={uploadRef}
					class={["g-upload-image", states.uploadKey]}
					vLoading={states.loading}
					vModel:fileList={states.fileList}
					disabled={states.disabled}
					httpRequest={handleHttpRequest}
					beforeUpload={handleBeforeUpload}
					onExceed={handleOnExceed}
					onSuccess={handleOnSuccess}
					onError={handleOnError}
				>
					{{
						default: () =>
							states.fileList.length > 0 ? (
								<>
									<img class="el-upload-list__item-thumbnail" src={states.fileList[0].url} />
									<span class="el-upload-list__item-actions" onClick={withModifiers(null, ["stop"])}>
										<span class="el-upload-list__item-icon" onClick={() => handlePreview()} title="查看">
											<ElIcon>
												<ZoomIn />
											</ElIcon>
										</span>
										{states.disabled ? null : (
											<>
												<span class="el-upload-list__item-icon" onClick={handleEdit} title="编辑">
													<ElIcon>
														<Edit />
													</ElIcon>
												</span>
												<span class="el-upload-list__item-icon" onClick={() => handleRemove()} title="删除">
													<ElIcon>
														<Delete />
													</ElIcon>
												</span>
											</>
										)}
									</span>
								</>
							) : slots.empty ? (
								slots.empty(states)
							) : (
								<>
									<ElIcon class="el-icon--upload">
										<UploadFilled />
									</ElIcon>
									<div class="el-upload__text">
										Drop file here <br />
										<em>click to upload</em>
									</div>
								</>
							),
						tip: () => (
							<div class="el-upload__tip">
								file with a size less than {new Decimal(props.maxSize).div(new Decimal(1024)).toString()}MB
							</div>
						),
					}}
				</ElUpload>
				{states.preview ? (
					<ElImageViewer
						closeOnPressEscape
						hideOnClickModal
						teleported
						onClose={() => (states.preview = false)}
						urlList={states.previewList}
					/>
				) : null}
			</>
		));

		expose({
			states,
			...useUpload(uploadRef),
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			...useUpload(uploadRef),
		};
	},
});
