import type { ExtractPropTypes } from "vue";
import type { gTreeEmits, gTreeProps, treeProps } from "./src/define";
import Tree from "./src/tree";
import { withInstall } from "@gejia-element-plus/utils";

export const GTree = withInstall(Tree);
export default GTree;

export * from "./src/define";
export * from "./src/type";

export type GTreeInstance = InstanceType<typeof Tree>;

export type TreeProps = ExtractPropTypes<typeof treeProps>;
export type GTreeProps = ExtractPropTypes<typeof gTreeProps>;

export type GTreeEmits = typeof gTreeEmits;
