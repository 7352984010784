/**
 * 本地缓存
 */

import { CacheKey } from "@gejia-element-plus/constants";
import { pinia, useGejiaApp } from "@gejia-element-plus/stores";
import { base64Util } from "@gejia-element-plus/utils";

/**
 * window.localStorage
 */
export const Local = {
	/**
	 * 设置
	 * @param key 缓存的Key
	 * @param val 缓存值
	 * @param expire 过期时间，单位分钟
	 * @param encrypt 是否对缓存的数据加密
	 */
	set(key: string, val: any, expire: number | null = null, encrypt: boolean = null): void {
		const gejiaAppStore = useGejiaApp(pinia);
		encrypt ??= gejiaAppStore.states.appConfig.storageCrypto;
		// 判断是否存在缓存过期时间
		if (expire !== null) {
			if (isNaN(expire) || expire < 1) {
				throw new Error("有效期应为一个有效数值");
			}
			// 设置过期时间的缓存
			const expireData = {
				time: Date.now(),
				expire,
			};
			const expireJson = JSON.stringify(expireData);
			window.localStorage.setItem(`${CacheKey.CACHE_PREFIX}${key}${CacheKey.CACHE_EXPIRE_SUFFIX}`, expireJson);
		}
		let valJson = JSON.stringify(val);
		if (encrypt) {
			valJson = base64Util.toBase64(valJson);
		}
		window.localStorage.setItem(`${CacheKey.CACHE_PREFIX}${key}`, valJson);
	},
	/**
	 * 获取
	 * @param key 缓存的Key
	 * @param decrypt 是否对缓存的数据解密
	 * @returns {T} 传入的对象类型，默认为 string
	 */
	get<T = string>(key: string, decrypt: boolean = null): T | null {
		const gejiaAppStore = useGejiaApp(pinia);
		decrypt ??= gejiaAppStore.states.appConfig.storageCrypto;
		// 获取缓存 JSON 字符串
		let valJson = window.localStorage.getItem(`${CacheKey.CACHE_PREFIX}${key}`);
		if (valJson) {
			try {
				// 判断是否解密
				if (decrypt) {
					valJson = base64Util.base64ToStr(valJson);
				}
				// 尝试获取缓存过期时间的 JSON 字符串
				const expireJson = window.localStorage.getItem(`${CacheKey.CACHE_PREFIX}${key}${CacheKey.CACHE_EXPIRE_SUFFIX}`);
				// 判断是否存在过期时间
				if (expireJson) {
					const expireData = JSON.parse(expireJson) as anyObj;
					if (Date.now() > expireData.time + expireData.expire * 60 * 1000) {
						// 过期了，删除对应的缓存数据
						window.localStorage.removeItem(`${CacheKey.CACHE_PREFIX}${key}`);
						window.localStorage.removeItem(`${CacheKey.CACHE_PREFIX}${key}${CacheKey.CACHE_EXPIRE_SUFFIX}`);
						return null;
					}
				}
				try {
					return JSON.parse(valJson) as T;
				} catch {
					return valJson as T;
				}
			} catch (error) {
				console.error("[gejia-Local]", error);
				return null;
			}
		}

		return null;
	},
	/**
	 * 移除
	 * @param key 缓存的Key
	 */
	remove(key: string): void {
		window.localStorage.removeItem(`${CacheKey.CACHE_PREFIX}${key}`);
		window.localStorage.removeItem(`${CacheKey.CACHE_PREFIX}${key}${CacheKey.CACHE_EXPIRE_SUFFIX}`);
	},
	/**
	 * 根据前缀移除
	 * @param key 缓存的Key
	 */
	removeByPrefix(key: string): void {
		for (const itemKey in window.localStorage) {
			if (itemKey.indexOf(`${CacheKey.CACHE_PREFIX}${key}`) !== -1) {
				window.localStorage.removeItem(itemKey);
			}
		}
	},
	/**
	 * 移除全部
	 */
	clear(): void {
		window.localStorage.clear();
	},
};

/**
 * window.sessionStorage
 */
export const Session = {
	/**
	 * 设置会话缓存
	 * @param key 缓存的Key
	 * @param val 缓存值
	 * @param expire 过期时间，单位分钟
	 * @param encrypt 是否对缓存的数据加密
	 */
	set(key: string, val: any, expire: number | null = null, encrypt: boolean = null): void {
		const gejiaAppStore = useGejiaApp(pinia);
		encrypt ??= gejiaAppStore.states.appConfig.storageCrypto;
		// 判断是否存在缓存过期时间
		if (expire !== null) {
			if (isNaN(expire) || expire < 1) {
				throw new Error("有效期应为一个有效数值");
			}
			// 设置过期时间的缓存
			const expireData = {
				time: Date.now(),
				expire,
			};
			const expireJson = JSON.stringify(expireData);
			window.sessionStorage.setItem(`${CacheKey.CACHE_PREFIX}${key}${CacheKey.CACHE_EXPIRE_SUFFIX}`, expireJson);
		}
		let valJson = JSON.stringify(val);
		if (encrypt) {
			valJson = base64Util.toBase64(valJson);
		}
		window.sessionStorage.setItem(`${CacheKey.CACHE_PREFIX}${key}`, valJson);
	},
	/**
	 * 获取会话缓存
	 * @param key 缓存的Key
	 * @param decrypt 是否对缓存的数据解密
	 * @returns {T} 传入的对象类型，默认为 string
	 */
	get<T = string>(key: string, decrypt: boolean = null): T | null {
		const gejiaAppStore = useGejiaApp(pinia);
		decrypt ??= gejiaAppStore.states.appConfig.storageCrypto;
		// 获取缓存 JSON 字符串
		let valJson = window.sessionStorage.getItem(`${CacheKey.CACHE_PREFIX}${key}`);
		if (valJson) {
			try {
				// 判断是否解密
				if (decrypt) {
					valJson = base64Util.base64ToStr(valJson);
				}
				// 尝试获取缓存过期时间的 JSON 字符串
				const expireJson = window.sessionStorage.getItem(`${CacheKey.CACHE_PREFIX}${key}${CacheKey.CACHE_EXPIRE_SUFFIX}`);
				// 判断是否存在过期时间
				if (expireJson) {
					const expireData = JSON.parse(expireJson) as anyObj;
					if (Date.now() > expireData.time + expireData.expire * 60 * 1000) {
						// 过期了，删除对应的缓存数据
						window.sessionStorage.removeItem(`${CacheKey.CACHE_PREFIX}${key}`);
						window.sessionStorage.removeItem(`${CacheKey.CACHE_PREFIX}${key}${CacheKey.CACHE_EXPIRE_SUFFIX}`);
						return null;
					}
				}
				try {
					return JSON.parse(valJson) as T;
				} catch {
					return valJson as T;
				}
			} catch (error) {
				console.error("[gejia-Session]", error);
				return null;
			}
		}

		return null;
	},
	/**
	 * 移除会话缓存
	 * @param key 缓存的Key
	 */
	remove(key: string): void {
		window.sessionStorage.removeItem(`${CacheKey.CACHE_PREFIX}${key}`);
		window.sessionStorage.removeItem(`${CacheKey.CACHE_PREFIX}${key}${CacheKey.CACHE_EXPIRE_SUFFIX}`);
	},
	/**
	 * 根据前缀移除会话缓存
	 * @param key 缓存的Key
	 */
	removeByPrefix(key: string): void {
		for (const itemKey in window.sessionStorage) {
			if (itemKey.indexOf(`${CacheKey.CACHE_PREFIX}${key}`) !== -1) {
				window.sessionStorage.removeItem(itemKey);
			}
		}
	},
	/**
	 * 移除全部会话缓存
	 */
	clear(): void {
		window.sessionStorage.clear();
	},
};
