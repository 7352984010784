import { defineComponent, onMounted } from "vue";
import { ElDropdown, ElDropdownItem, ElDropdownMenu } from "element-plus";
import { gLayoutScreenFullProps } from "./define";
import { GIcon } from "@gejia-element-plus/components/icon";
import { useScreenFull } from "@gejia-element-plus/hooks";
import { pinia, useNavTabs } from "@gejia-element-plus/stores";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GLayoutScreenFull 组件
 */
export default defineComponent({
	name: "GLayoutScreenFull",
	components: {
		ElDropdown,
		ElDropdownMenu,
		ElDropdownItem,
		GIcon,
	},
	props: gLayoutScreenFullProps,
	setup(props, { attrs, expose }) {
		const navTabsStore = useNavTabs(pinia);

		const handleContentLargeClick = (): void => {
			navTabsStore.setContentLarge(!navTabsStore.states.contentLarge);
		};

		const handleContentFullClick = (): void => {
			useScreenFull.full();
		};

		onMounted(() => {
			useScreenFull.init();
		});

		useRender(() => (
			<>
				{props.dropdown ? (
					<ElDropdown {...attrs} class="g-layout-screen-full g-twinkle">
						{{
							default: () => (
								<pre>
									<GIcon name={navTabsStore.states.contentFull ? "g-icon-FullScreenExit" : "g-icon-FullScreen"} />
								</pre>
							),
							dropdown: () => (
								<ElDropdownMenu>
									<ElDropdownItem onClick={handleContentLargeClick}>
										{navTabsStore.states.contentLarge ? "内容区复原" : "内容区放大"}
									</ElDropdownItem>
									<ElDropdownItem onClick={handleContentFullClick}>内容区全屏</ElDropdownItem>
								</ElDropdownMenu>
							),
						}}
					</ElDropdown>
				) : (
					<div {...attrs} class="g-layout-screen-full g-twinkle">
						<GIcon name={navTabsStore.states.contentFull ? "g-icon-FullScreenExit" : "g-icon-FullScreen"} />
					</div>
				)}
			</>
		));

		expose({});

		return {
			attrs,
		};
	},
});
