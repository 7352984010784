import type { ExtractPropTypes } from "vue";
import Avatar from "./src/avatar";
import type { gAvatarProps } from "./src/define";
import { withInstall } from "@gejia-element-plus/utils";

export const GAvatar = withInstall(Avatar);
export default GAvatar;

export * from "./src/define";
export * from "./src/type";

export type GAvatarInstance = InstanceType<typeof Avatar>;

export type GAvatarProps = ExtractPropTypes<typeof gAvatarProps>;
