import type { PropType, VNode } from "vue";
import type { GLayoutGridBreakPoint, GLayoutGridItemResponsive, GLayoutGridItemStates } from "./type";

export const gLayoutGridProps = {
	cols: {
		type: [Number, Object] as PropType<number | Record<GLayoutGridBreakPoint, number>>,
		// default: () => ({ xs: 1, sm: 2, md: 2, lg: 3, xl: 4 }),
		default: (): number | Record<GLayoutGridBreakPoint, number> => ({ xs: 3, sm: 3, md: 4, lg: 5, xl: 6 }),
	},
	collapsed: Boolean,
	collapsedRows: {
		type: Number,
		default: 1,
	},
	gap: {
		type: [Array, Number] as PropType<[number, number] | number>,
		default: 0,
	},
};

export const gLayoutGridEmits = {
	breakPointChange: ({ breakPoint: GLayoutGridBreakPoint }): boolean => true,
};

export const gLayoutGridSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (): VNode[] => [],
};

export const gLayoutGridItemProps = {
	offset: { type: Number, default: 0 },
	span: { type: Number, default: 1 },
	suffix: { type: Boolean, default: false },
	xs: {
		type: Object as PropType<GLayoutGridItemResponsive>,
	},
	sm: {
		type: Object as PropType<GLayoutGridItemResponsive>,
	},
	md: {
		type: Object as PropType<GLayoutGridItemResponsive>,
	},
	lg: {
		type: Object as PropType<GLayoutGridItemResponsive>,
	},
	xl: {
		type: Object as PropType<GLayoutGridItemResponsive>,
	},
};

export const gLayoutGridItemSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GLayoutGridItemStates): VNode[] => [],
};
