import type { ExtractPropTypes } from "vue";
import type { gFormItemTipProps } from "./src/define";
import FormItemTip from "./src/formItemTip";
import { withInstall } from "@gejia-element-plus/utils";

export const GFormItemTip = withInstall(FormItemTip);
export default GFormItemTip;

export * from "./src/define";

export type GFormItemTipInstance = InstanceType<typeof FormItemTip>;

export type GFormItemTipProps = ExtractPropTypes<typeof gFormItemTipProps>;
