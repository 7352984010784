import type { SlotsType } from "vue";
import { computed, defineComponent, provide, reactive, ref } from "vue";
import type { FormInstance } from "element-plus";
import { ElForm, formProps } from "element-plus";
import type { gFormSlots } from "./define";
import { gFormProps } from "./define";
import type { GFormStates } from "./type";
import { useForm } from "./useForm";
import { GLayoutGrid } from "@gejia-element-plus/components/layoutGrid";
import { typeUtil, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GForm 组件
 */
export default defineComponent({
	name: "GForm",
	components: {
		ElForm,
		GLayoutGrid,
	},
	props: gFormProps,
	slots: Object as SlotsType<typeof gFormSlots>,
	setup(props, { attrs, slots, expose }) {
		const states: GFormStates = reactive({
			cols: computed(() => {
				if (typeUtil.isObject(props.cols)) {
					return props.cols;
				} else {
					const colsNumber = typeUtil.isNumber(props.cols) ? props.cols : Number(props.cols);
					return { xs: 1, sm: colsNumber, md: colsNumber, lg: colsNumber, xl: colsNumber };
				}
			}),
			gap: [20, 0],
		});

		const formRef = ref<FormInstance>();

		// 注入 cols
		provide("gFormCols", states.cols);

		const bindProps = useProps(props, formProps);

		useRender(() => (
			<ElForm {...attrs} {...bindProps.value} ref={formRef} class={["g-form", props.detailForm ? "g-form-detail" : ""]}>
				{{
					default: () =>
						props.grid ? (
							<GLayoutGrid collapsed gap={states.gap} cols={states.cols}>
								{slots.default && slots.default(states)}
							</GLayoutGrid>
						) : (
							slots.default && slots.default(states)
						),
				}}
			</ElForm>
		));

		expose({
			states,
			...useForm(formRef),
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			...useForm(formRef),
		};
	},
});
