import type { SlotsType } from "vue";
import { computed, defineComponent, nextTick, onMounted, provide, reactive, ref, watch, watchEffect } from "vue";
import { Download, Eleme, More, Operation, Refresh, Search } from "@element-plus/icons-vue";
import {
	ElButton,
	ElDatePicker,
	ElDropdown,
	ElDropdownItem,
	ElDropdownMenu,
	ElImageViewer,
	ElInput,
	ElMessage,
	ElMessageBox,
	ElPagination,
	ElTable,
	ElTableColumn,
	dayjs,
} from "element-plus";
import type { TableColumnCtx, TableInstance } from "element-plus";
import type { gTableSlots } from "./define";
import { gTableEmits, gTableProps, tableProps } from "./define";
import GTableColumn from "./tableColumn";
import GTableColumnsSettingDialog from "./tableColumnsSettingDialog";
import GTableExportExcelDialog from "./tableExportExcelDialog";
import GTablePagination from "./tablePagination";
import GTableSearchForm from "./tableSearchForm";
import type { GTableColumnCtx, GTableEnumColumnCtx, GTableStates, GetTableColumnsOutput } from "./type";
import { useTable } from "./useTable";
import { GIcon } from "@gejia-element-plus/components/icon";
import { GPrintButton } from "@gejia-element-plus/components/printButton";
import { pinia, useGejiaApp } from "@gejia-element-plus/stores";
import { axiosUtil, debounce, errorHandler, tableUtil, typeUtil, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GTable 组件
 */
export default defineComponent({
	name: "GTable",
	components: {
		ElTable,
		ElTableColumn,
		ElInput,
		ElButton,
		ElDropdown,
		ElDropdownMenu,
		ElDropdownItem,
		ElPagination,
		Refresh,
		Search,
		More,
		Operation,
		Eleme,
		GIcon,
		Download,
		GPrintButton,
		ElImageViewer,
		ElDatePicker,
	},
	props: gTableProps,
	emits: gTableEmits,
	slots: Object as SlotsType<
		typeof gTableSlots & anyObj<{ row?: any; column: GTableColumnCtx; $index: number; search?: () => void } & Partial<GTableStates>>
	>,
	setup(props, { attrs, slots, emit, expose }) {
		const gejiaAppStore = useGejiaApp(pinia);

		const states: GTableStates = reactive({
			loading: false,
			loadingText: "加载中...",
			// 这里做一层默认值的处理
			orgColumns: [],
			searchColumns: [],
			tableColumns: [],
			spanColumns: [],
			tableData: [],
			tableSpanData: computed(() => {
				if (states.spanColumns?.length > 0 && states.tableData?.length > 0) {
					const result = [];
					states.spanColumns.forEach((item) => {
						result[item.prop] = new Array(states.tableData.length).fill(1, 0, 1).fill(0, 1);
						result[`${item.prop}-index`] = 0;
					});
					for (let i = 1; i < states.tableData.length; i++) {
						states.spanColumns.forEach((item) => {
							if (states.tableData[i][item.spanProp] == states.tableData[i - 1][item.spanProp]) {
								result[item.prop][result[`${item.prop}-index`]]++;
							} else {
								result[`${item.prop}-index`] = i;
								result[item.prop][i] = 1;
							}
						});
					}
					return result;
				}
				return [];
			}),
			tablePagination: {
				pageIndex: 1,
				pageSize: 20,
				totalRows: 0,
			},
			searchParam: {},
			showSearch: gejiaAppStore.states.tableConfig.showSearch,
			selected: false,
			selectedList: [],
			selectedListIds: computed(() => states.selectedList.map((item) => item[props.rowKey])),
			indeterminateSelectedListIds: [],
			response: undefined,
			exportExcelBtn: computed(() => {
				// 判断是否传入了方法
				if (props.exportExcelFn) {
					return props.exportExcelBtn;
				}
				// 判断全局的导出方法
				else if (gejiaAppStore.states.tableConfig.exportExcelFn) {
					return props.exportExcelBtn;
				}
				// 判断全局的导出Url是否存在
				else if (gejiaAppStore.states.tableConfig.exportExcelUrl) {
					return props.exportExcelBtn;
				} else {
					// 直接不显示
					return false;
				}
			}),
			columnBtn: computed(() => {
				// 如果是本地的，则不显示列配置
				if (props.columns) return false;
				if (
					gejiaAppStore.states.tableConfig.getColumnsUrl &&
					gejiaAppStore.states.tableConfig.saveColumnsCacheUrl &&
					gejiaAppStore.states.tableConfig.syncColumnsCacheUrl
				) {
					return states.tableColumns.length > 0;
				} else {
					// 直接不显示
					return false;
				}
			}),
			operationColumnWidth: computed(() => {
				const findAutoCol = states.autoColumnWidth.find((f) => f.prop === "__table-operation");
				if (findAutoCol) {
					return `${findAutoCol.width}px`;
				}
				switch (gejiaAppStore.states.appConfig.size) {
					case "default":
						return "54px";
					case "small":
						return "42px";
					default:
						return "auto";
				}
			}),
			imagePreview: false,
			previewList: [],
			tableSize: computed(() => gejiaAppStore.states.appConfig.size),
			existCacheColumns: false,
			tableWidth: undefined,
			tableHeight: undefined,
			autoColumnWidth: [],
		});

		// g-table Dom 元素
		const elementRef = ref<HTMLElement>();
		// el-table Dom 元素
		const tableRef = ref<TableInstance>();
		// 表格列配置
		const columnSettingDialogRef = ref<InstanceType<typeof GTableColumnsSettingDialog>>();
		const exportExcelDialogRef = ref<InstanceType<typeof GTableExportExcelDialog>>();

		/**
		 * 定义 enumMap 存储 enum 值（避免异步请求无法格式化单元格内容 || 无法填充搜索下拉选择）
		 */
		const enumMap = ref(new Map<string, GTableEnumColumnCtx[]>());
		provide("enumMap", enumMap);

		provide("tableKey", props.tableKey);
		provide("tableStates", states);

		/**
		 * 同步列缓存
		 */
		const syncColumnsCache = async (showConfirm = true): Promise<void> => {
			if (props.columns) return;
			async function localRequest(): Promise<void> {
				states.loading = true;
				states.loadingText = "同步列配置中...";
				try {
					await axiosUtil.request({
						url: gejiaAppStore.states.tableConfig.syncColumnsCacheUrl,
						method: "post",
						data: {
							tableKey: props.tableKey,
						},
						requestType: "edit",
					});
					gejiaAppStore.deleteTableColumns(props.tableKey);
					ElMessage.success("同步成功");
					// 重新加载表格列
					// eslint-disable-next-line no-use-before-define
					await loadTableColumns();
				} catch (error) {
					console.error("[gejia-GTable]", error);
					errorHandler(error);
				} finally {
					states.loading = false;
				}
			}
			if (showConfirm) {
				ElMessageBox.confirm("确认同步列缓存配置？此操作无法撤销。", {
					type: "warning",
					async beforeClose(action, instance, done) {
						await localRequest();
					},
				}).then();
			} else {
				await localRequest();
			}
		};

		/**
		 * 保存列缓存
		 */
		const saveColumnsCache = async (change = true): Promise<void> => {
			if (props.columns) return;
			if (change) {
				states.loading = true;
				states.loadingText = "保存列配置中...";
				try {
					await axiosUtil.request({
						url: gejiaAppStore.states.tableConfig.saveColumnsCacheUrl,
						method: "post",
						data: {
							tableKey: props.tableKey,
							columns: states.orgColumns,
						},
						requestType: "edit",
					});

					// 这里已经存在缓存列了
					states.existCacheColumns = true;

					gejiaAppStore.setTableColumns(props.tableKey, states.orgColumns);

					// 获取搜索列，el 不为空的
					states.searchColumns = states.orgColumns
						.filter((f) => f?.search)
						.sort((a, b) => {
							return a.search?.order - b.search?.order;
						});

					// 获取正常列，prop 不为空
					states.tableColumns = states.orgColumns.filter((f) => f.prop);

					ElMessage.success("保存列配置成功");
				} catch (error) {
					console.error("[gejia-GTable]", error);
					errorHandler(error);
				} finally {
					states.loading = false;
				}
			} else {
				ElMessage.info("列配置未发生变化");
			}
		};

		/**
		 * 清除列缓存
		 */
		const clearColumnsCache = async (): Promise<void> => {
			if (props.columns) return;
			ElMessageBox.confirm("确认重置列缓存配置？此操作无法撤销。", {
				type: "warning",
				async beforeClose(action, instance, done) {
					states.loading = true;
					states.loadingText = "重置列配置中...";
					try {
						await axiosUtil.request({
							url: gejiaAppStore.states.tableConfig.clearColumnsCacheUrl,
							method: "post",
							data: {
								tableKey: props.tableKey,
							},
							requestType: "delete",
						});

						gejiaAppStore.deleteTableColumns(props.tableKey);
						ElMessage.success("重置成功");
						// 重新加载表格列
						// eslint-disable-next-line no-use-before-define
						await loadTableColumns();
					} catch (error) {
						console.error("[gejia-GTable]", error);
						errorHandler(error);
					} finally {
						states.loading = false;
					}
				},
			}).then();
		};

		/**
		 * 处理表格列
		 */
		const loadTableColumns = async (): Promise<void> => {
			let columns: GTableColumnCtx[] = [];
			// 判断是否传入了 columns，只要有值的情况下就用本地的配置，则不能存在列缓存配置
			if (props.columns) {
				columns = props.columns;
			} else {
				const cacheColumns = gejiaAppStore.getTableColumns(props.tableKey);
				if (cacheColumns) {
					columns = cacheColumns;
				} else {
					states.loading = true;
					states.loadingText = "加载列配置中...";
					try {
						const apiRes = await axiosUtil.request<GetTableColumnsOutput>({
							url: gejiaAppStore.states.tableConfig.getColumnsUrl,
							method: "get",
							params: {
								tableKey: props.tableKey,
							},
							requestType: "query",
						});
						// 判断是否存在改变
						if (apiRes.data.change) {
							ElMessage.info(
								`当前列配置于 '${dayjs(apiRes.data.LastUpdateTime).format("YYYY-MM-DD")}' 已发生改变，为确保数据准确性，正在同步缓存配置，请稍后...`
							);
							// 同步
							await syncColumnsCache(false);
							return;
						} else {
							const handleColumnType = (
								localColumns: (GTableColumnCtx &
									{
										otherAdvancedConfig?: { prop: string; type: number }[];
										searchAdvancedConfig?: { prop: string; type: number };
									}[])[]
							): (GTableColumnCtx &
								{
									otherAdvancedConfig?: { prop: string; type: number }[];
									searchAdvancedConfig?: { prop: string; type: number };
								}[])[] => {
								const handleFunctionArgs = (
									functionStr: string
								): {
									args: string[];
									body: string;
								} => {
									// 去掉字符串开头和结尾的多余空白字符，包括换行符
									const trimmedStr = functionStr.trim();

									// 正则表达式用于匹配箭头函数的参数和函数体，包括支持解构参数
									const arrowFunctionMatch = trimmedStr.match(/^\s*\(?([^)]*?)\)?\s*=>\s*{([\s\S]*?)}\s*;?$/);

									if (arrowFunctionMatch) {
										const args = arrowFunctionMatch[1]
											.split(",")
											.map((arg) => arg.trim())
											.filter((arg) => arg);
										const body = arrowFunctionMatch[2].trim();
										return { args, body };
									}

									return { args: [], body: "" };
								};

								for (let i = 0; i < localColumns.length; i++) {
									if (localColumns[i]?.otherAdvancedConfig?.length > 0) {
										localColumns[i]?.otherAdvancedConfig
											.filter((f) => f.type === 4)
											.forEach((advKey: { prop: string; type: number }) => {
												const { args, body } = handleFunctionArgs(localColumns[i][advKey.prop]);
												localColumns[i][advKey.prop] = new Function(...args, body);
											});
										delete localColumns[i].otherAdvancedConfig;
									}
									if (localColumns[i]?.searchAdvancedConfig?.length > 0) {
										localColumns[i]?.searchAdvancedConfig
											.filter((f) => f.type === 4)
											.forEach((advKey: { prop: string; type: number }) => {
												const { args, body } = handleFunctionArgs(localColumns[i].search[advKey.prop]);
												localColumns[i].search[advKey.prop] = new Function(...args, body);
											});
										delete localColumns[i].searchAdvancedConfig;
									}
								}

								return localColumns;
							};

							// 判断是否存在缓存列
							if (apiRes.data.cache) {
								states.existCacheColumns = true;
								columns = handleColumnType(apiRes.data.cacheColumns);
							} else {
								states.existCacheColumns = false;
								columns = handleColumnType(apiRes.data.columns);
							}

							gejiaAppStore.setTableColumns(props.tableKey, columns);
						}
					} catch (error) {
						console.error("[gejia-GTable]", error);
						errorHandler(error);
					} finally {
						states.loading = false;
					}
				}
			}

			// 默认值处理
			columns.forEach((col) => {
				// 处理搜索项的 key 和 label
				if (col?.pureSearch || col?.search) {
					col.search.key ??= col.prop;
					col.search.label ??= col.label;
					// 处理默认值
					if (col.search.defaultValue) {
						states.searchParam[col.search.key] = col.search.defaultValue;
					}
				}
			});

			// 排序
			columns = columns.sort((a, b) => {
				return a?.order - b?.order;
			});

			// 源列
			// TODO:这里的扁平化暂时没用到
			states.orgColumns = tableUtil.flatColumns(columns, enumMap);

			// 获取搜索列，纯搜索字段或el 不为空的
			states.searchColumns = states.orgColumns
				.filter((f) => f?.pureSearch || f?.search)
				.sort((a, b) => {
					return a?.search?.order - b?.search?.order;
				});

			// 获取合并的列
			states.spanColumns = states.orgColumns
				.filter((f) => f?.spanProp)
				.map((col) => {
					return {
						prop: col?.prop,
						spanProp: col?.spanProp,
					};
				});

			// 默认合并处理
			if (props.spanProp) {
				states.spanColumns.push({ prop: "__table-index", spanProp: props.spanProp });
				states.spanColumns.push({ prop: "__table-selection", spanProp: props.spanProp });
				states.spanColumns.push({ prop: "__table-operation", spanProp: props.spanProp });
			}

			// 获取正常列，prop 不为空，并且不是纯搜索字段的
			states.tableColumns = states.orgColumns.filter((f) => f?.prop && !f?.pureSearch);
		};

		/**
		 * 处理表格列自动宽度
		 */
		const handleTableColumnAutoWidth = (): void => {
			states.autoColumnWidth = [];
			const autoWidthColumns = states.tableColumns.filter((f) => f.autoWidth);
			if (slots?.operation) {
				// 操作列自动宽度
				autoWidthColumns.push({
					prop: "__table-operation",
				});
			}
			if (autoWidthColumns?.length > 0) {
				// padding24/16 + border1
				const otherWidth = gejiaAppStore.states.appConfig.size === "default" ? 25 : 17;
				nextTick(() => {
					const tableDom = document.querySelector(`.g-table__${props.tableKey}`);
					if (tableDom) {
						autoWidthColumns.forEach((item) => {
							const headerColumnDom = tableDom.querySelector(`.__g-table__auto-width-column__cell-header__${item?.prop}`);
							const cellColumnDoms = tableDom.querySelectorAll(`.__g-table__auto-width-column__cell__${item?.prop}`);
							let maxWidth = 0;
							if (headerColumnDom) {
								maxWidth = Math.ceil(headerColumnDom.scrollWidth) + otherWidth;
								if (item?.sortable) {
									maxWidth += 24;
								}
							}
							cellColumnDoms.forEach((cellDom) => {
								const curWidth = Math.ceil(cellDom.scrollWidth) + otherWidth;
								if (curWidth > maxWidth) {
									maxWidth = curWidth;
								}
							});
							const findInfo = states.autoColumnWidth.find((f) => f.prop === item?.prop);
							if (findInfo) {
								findInfo.width = Math.max(findInfo.width, maxWidth);
							} else {
								states.autoColumnWidth.push({
									prop: item?.prop,
									width: maxWidth,
								});
							}
						});
					}
				});
			}
		};

		/**
		 * 处理Table数据
		 */
		const handleTableData = (data: any[]): any[] => {
			if (props.treeData) {
				const result: any[] = [];
				data.forEach((row) => {
					const rowList = row[props.treeChildrenName] as any[];
					if (rowList && rowList.length > 0) {
						rowList.forEach((tRow) => {
							result.push({ ...(row as any), ...(tRow as any) });
						});
					} else {
						result.push(row as any);
					}
				});
				return result;
			} else {
				return data;
			}
		};

		/**
		 * 获取请求参数
		 */
		const getRequestParam = (): {
			pageIndex?: number;
			pageSize?: number;
			totalRows?: number;
			searchValue?: string;
			searchTimeList?: Array<Date | string>;
			sortList?: Array<BaseSortInput>;
			isPage?: boolean;
		} => {
			const param = { ...(props.initParam ?? {}), ...states.searchParam, ...(props.pagination ? states.tablePagination : {}) };
			// 删除总条数
			delete param.totalRows;
			return param;
		};

		/**
		 * 加载表格数据
		 */
		const loadData = async (searchParam?: PagedInput<anyObj>): Promise<void> => {
			if (!props.requestApi) {
				throw new Error("如果需要加载GTable数据，那么参数 requestApi 是必须的！");
			}

			states.loading = true;
			states.loadingText = "加载中...";
			const param = searchParam ?? getRequestParam();
			let pageData: anyObj[];
			try {
				const apiRes = await props.requestApi(param);
				states.response = apiRes.response;
				// 数据回调
				props.dataCallback && props.dataCallback(apiRes.data);
				// 解析 API 接口返回的分页数据（如果有分页更新分页信息）
				if (props.pagination) {
					const pagedResult = apiRes.data as PagedResult<anyObj[]>;
					pageData = pagedResult.rows;
					// 更新分页信息
					Object.assign(states.tablePagination, {
						pageIndex: pagedResult.pageIndex,
						pageSize: pagedResult.pageSize,
						totalRows: pagedResult.totalRows,
					});
				} else {
					pageData = apiRes.data as anyObj[];
				}
				states.tableData = handleTableData(pageData);
				handleTableColumnAutoWidth();
				emit("dataChange", param, states.tableData, pageData);
			} catch (error) {
				console.error("[gejia-GTable]", error);
				states.tableData = [];
				errorHandler(error);
			} finally {
				states.loading = false;
			}
		};

		/**本地匹配搜索 */
		const filterByLocal = (): void => {
			let _value = handleTableData(props.data);
			_value = _value.filter((f) => {
				if (!states.searchParam?.searchValue) return true;
				return states.tableColumns.some((col) => {
					return f[col.prop]?.toString()?.toLowerCase().includes(states.searchParam?.searchValue?.toLowerCase());
				});
			});
			if (props.pagination) {
				states.tablePagination.totalRows = _value.length;
				const pageStart = (states.tablePagination.pageIndex - 1) * states.tablePagination.pageSize;
				const pageEnd = pageStart + states.tablePagination.pageSize;
				states.tableData = _value.slice(pageStart, pageEnd);
				handleTableColumnAutoWidth();
			} else {
				states.tableData = _value;
				handleTableColumnAutoWidth();
			}
		};

		/**
		 * 刷新表格
		 */
		const refreshTable = (searchParam?: PagedInput<anyObj>): void => {
			if (!props.requestApi) {
				filterByLocal();
			} else {
				loadData(searchParam);
			}
		};

		/**
		 * 每页条数改变
		 */
		const handleSizeChange = (val: number): void => {
			states.tablePagination.pageIndex = 1;
			states.tablePagination.pageSize = val;
			refreshTable();
			emit("sizeChange", val);
			emit("paginationChange", 1, val);
		};

		/**
		 * 当前页改变
		 */
		const handleCurrentChange = (val: number): void => {
			states.tablePagination.pageIndex = val;
			refreshTable();
			emit("sizeChange", val);
			emit("paginationChange", val, states.tablePagination.pageSize);
		};

		/**
		 * 更新查询参数
		 */
		const updatedTotalParam = (): void => {
			// searchParam.value = {}
			// 处理查询参数，可以给查询参数加自定义前缀操作
			const newSearchParam: anyObj = {};
			// 防止手动清空输入框携带参数（这里可以自定义查询参数前缀）
			for (const key in states.searchParam) {
				// * 某些情况下参数为 false/0 也应该携带参数
				if (states.searchParam[key] || states.searchParam[key] === false || states.searchParam[key] === 0) {
					newSearchParam[key] = states.searchParam[key];
				}
				// 处理某些情况下如果为空字符串，其实是不需要传到后端的
				else if (!states.searchParam[key]) {
					delete states.searchParam[key];
				}
			}
			Object.assign(states.searchParam, newSearchParam);
		};

		/**
		 * 表格数据查询
		 */
		const tableSearch = (): void => {
			// 重置到第一页
			states.tablePagination.pageIndex = 1;
			updatedTotalParam();
			refreshTable();
		};

		const defaultSearchTime = (): void => {
			if (props.hideSearchTime) {
				states.searchParam.searchTimeList = undefined;
			} else {
				const end = new Date();
				const start = new Date();
				switch (gejiaAppStore.states.tableConfig.dataSearchRange) {
					case "Past1D":
						start.setDate(start.getDate() - 1);
						break;
					case "Past3D":
						start.setDate(start.getDate() - 3);
						break;
					case "Past1W":
						start.setDate(start.getDate() - 7);
						break;
					case "Past1M":
						start.setMonth(start.getMonth() - 1);
						break;
					case "Past3M":
						start.setMonth(start.getMonth() - 3);
						break;
					case "Past6M":
						start.setMonth(start.getMonth() - 6);
						break;
					case "Past1Y":
						start.setFullYear(start.getFullYear() - 1);
						break;
				}
				states.searchParam.searchTimeList = [dayjs(start).format("YYYY-MM-DD 00:00:00"), dayjs(end).format("YYYY-MM-DD 23:59:59")];
			}
		};

		/**
		 * 表格数据重置
		 */
		const tableReset = (): void => {
			// 重置到第一页
			states.tablePagination.pageIndex = 1;
			// 清除搜索条件
			states.searchParam = {};
			// 重置搜索表单的时候，如果有默认搜索参数，则重置默认的搜索参数
			Object.keys(props.initParam ?? {}).forEach((key) => {
				states.searchParam[key] = props.initParam[key];
			});
			updatedTotalParam();
			defaultSearchTime();
			emit("tableReset");
			refreshTable();
		};

		let lastRowIndex = 0;

		/**
		 * 自定义索引
		 */
		const indexMethod = (index: number): number => {
			if (index === 0) {
				lastRowIndex = 0;
			}
			if (states.spanColumns?.length > 0 && props.spanProp) {
				const rowspan = Number(states.tableSpanData["__table-index"][index]);
				if (rowspan === 0) {
					return lastRowIndex + (states.tablePagination.pageIndex - 1) * states.tablePagination.pageSize + 1;
				} else {
					lastRowIndex++;
					return lastRowIndex + (states.tablePagination.pageIndex - 1) * states.tablePagination.pageSize;
				}
			}
			return index + (states.tablePagination.pageIndex - 1) * states.tablePagination.pageSize + 1;
		};

		/**
		 * 选中改变
		 * @param selection 多选的数据
		 * @param row 单行数据
		 */
		const handleSelectClick = (selection: any[], row?: any): void => {
			// 判断是否开启了单选
			if (props.single) {
				tableRef.value?.clearSelection();
				if (row && selection.length) {
					tableRef.value?.toggleRowSelection(row, true);
				}
			}
		};

		/**
		 * 多选操作
		 * @param rowArr 当前选择的所有的数据
		 */
		const handleSelectionChange = (rowArr: any): void => {
			rowArr.length === 0 ? (states.selected = false) : (states.selected = true);
			// 判断是否为单选
			if (props.single && rowArr.length > 0) {
				// 这里获取最后一个是因为选中改变的事件会触发多次，会带入旧的数据
				states.selectedList = [rowArr[rowArr.length - 1]];
			} else {
				states.selectedList = rowArr;
			}
			// 如果已经取消选择了，那么部分选择也应该要取消
			states.indeterminateSelectedListIds = states.indeterminateSelectedListIds.filter((f) => states.selectedListIds.some((s) => s === f));
			emit("selectionChange", states.selectedList);
		};

		/**
		 * 用于多选表格，切换某一行的部分选中状态
		 */
		const toggleRowIndeterminateSelection = (row: any): void => {
			const rowKey = row[props.rowKey];
			if (!states.indeterminateSelectedListIds.some((s) => s === rowKey)) {
				states.indeterminateSelectedListIds.push(rowKey);
			}
			// 当前 row 直接从 data 中获取
			tableRef.value.toggleRowSelection(
				states.tableData.find((f) => f[props.rowKey] === rowKey),
				true
			);
		};

		/**
		 * 设置列的排序为我们自定义的排序
		 */
		const handleHeaderClass = ({ column }): void => {
			column.order = column.multiOrder;
		};

		/**
		 * 设置选择器的样式
		 */
		const handleCellClassName = ({
			row,
			column,
			rowIndex,
			columnIndex,
		}: {
			row: any;
			column: TableColumnCtx<any>;
			rowIndex: number;
			columnIndex: number;
		}): string => {
			let localCellClassName = null;
			// 判断是否为选择列
			if (column.type === "selection") {
				// 判断是否在部分选中的集合中
				const rowKey = row[props.rowKey];
				if (states.indeterminateSelectedListIds.some((s) => s === rowKey)) {
					localCellClassName = "g-table__selection-column__indeterminate";
				}
			}
			if (props.cellClassName) {
				let cellClassName = null;
				if (typeUtil.isString(props.cellClassName)) {
					cellClassName = props.cellClassName;
				} else {
					cellClassName = props.cellClassName({ row, column, rowIndex, columnIndex });
				}
				if (!cellClassName) {
					return localCellClassName;
				}
				if (localCellClassName) {
					return `${localCellClassName} ${cellClassName}`;
				} else {
					return cellClassName;
				}
			} else {
				return localCellClassName;
			}
		};

		/**
		 * 当某个表头排序改变时会触发该事件
		 */
		const handleSortChange = ({ column }): void => {
			if (!column.multiOrder) {
				column.multiOrder = "descending";
			} else if (column.multiOrder === "descending") {
				column.multiOrder = "ascending";
			} else {
				column.multiOrder = null;
			}
			// 排序集合非空判断
			states.searchParam.sortList = [...(props.initParam["sortList"] ?? []), ...(states.searchParam?.sortList ?? [])];
			// 去原来的列中查找表格的列数据
			let orgColumn = states.orgColumns.find((f) => f.prop == column.property);
			if (orgColumn == null) {
				orgColumn = column;
			}
			const enField = orgColumn?.sortableField ?? orgColumn?.prop ?? orgColumn?.property;
			const fieldIndex = states.searchParam.sortList.findIndex((f: BaseSortInput) => f.enField === enField);
			if (!column.multiOrder) {
				// 如果是空的，删除排序
				states.searchParam.sortList.splice(fieldIndex, 1);
			} else if (fieldIndex === -1) {
				states.searchParam.sortList.push({
					enField,
					cnField: orgColumn?.label,
					mode: column.multiOrder,
				});
			} else {
				states.searchParam.sortList[fieldIndex].mode = column.multiOrder;
			}
			// 判断最后的排序集合中是否还存在数据，如果不存在，则删除排序集合
			if (states.searchParam.sortList.length === 0) {
				delete states.searchParam.sortList;
			}
			// 判断请求接口的方法是否为空
			if (props.requestApi) {
				// 刷新表格数据
				refreshTable();
			} else {
				const _tableData = handleTableData(props.data).sort(tableUtil.arrayDynamicSort(states.searchParam.sortList ?? []));
				if (props.pagination) {
					states.tablePagination.totalRows = _tableData.length;
					const pageStart = (states.tablePagination.pageIndex - 1) * states.tablePagination.pageSize;
					const pageEnd = pageStart + states.tablePagination.pageSize;
					states.tableData = _tableData.slice(pageStart, pageEnd);
					handleTableColumnAutoWidth();
				} else {
					states.tableData = _tableData;
					handleTableColumnAutoWidth();
				}
			}

			emit("sortChange", { column, prop: enField, order: column.multiOrder });
		};

		/**
		 * 合并行列
		 */
		const handleSpanMethod = ({
			column,
			rowIndex,
		}: {
			row: any;
			column: any;
			rowIndex: number;
			columnIndex: number;
		}): {
			rowspan: number;
			colspan: number;
		} => {
			const pKey = column?.property ?? column?.columnKey;
			if (states.spanColumns.findIndex((f) => f.prop === pKey) !== -1) {
				const rowspan = Number(states.tableSpanData[pKey][rowIndex]);
				if (rowspan > 0) {
					return { rowspan, colspan: 1 };
				}
				return { rowspan: 0, colspan: 0 };
			}
			return { rowspan: 1, colspan: 1 };
		};

		/**
		 * 当拖动表头改变了列的宽度的时候会触发该事件
		 */
		const handleHeaderDragend = (newWidth: number, oldWidth: number, column: GTableColumnCtx): void => {
			states.orgColumns.forEach((f) => {
				if (column.property === f.prop) {
					f.width = newWidth;
					f.smallWidth = newWidth;
				}
			});

			// 获取正常列，prop 不为空
			states.tableColumns = states.orgColumns.filter((f) => f.prop);

			// 保存缓存列
			saveColumnsCache();
		};

		const handleCustomCellClick = (emitName: string, { row, $index }: { row: anyObj; $index: number }): void => {
			emit("customCellClick", emitName, { row, $index });
		};

		const handleImagePreview = (url: string): void => {
			states.previewList = [url];
			states.imagePreview = true;
		};

		onMounted(async () => {
			defaultSearchTime();
			await loadTableColumns();
			if (props.requestApi) {
				await loadData();
			}
		});

		watch(
			() => props.data,
			(newValue) => {
				if (!props.requestApi) {
					if (props.pagination) {
						states.tablePagination.totalRows = newValue.length;
						const pageStart = (states.tablePagination.pageIndex - 1) * states.tablePagination.pageSize;
						const pageEnd = pageStart + states.tablePagination.pageSize;
						states.tableData = handleTableData(newValue).slice(pageStart, pageEnd);
						handleTableColumnAutoWidth();
					} else {
						states.tableData = handleTableData(newValue);
						handleTableColumnAutoWidth();
					}
				}
			},
			{ deep: true, immediate: true }
		);

		watchEffect(() => {
			const element = elementRef.value;
			if (element) {
				const observer = new ResizeObserver((entries) => {
					for (const entry of entries) {
						const { width, height } = entry.contentRect;
						states.tableWidth = width;
						states.tableHeight = height;
					}
					debounce(handleTableColumnAutoWidth, 100);
				});
				observer.observe(element);

				return (): void => {
					observer.disconnect();
				};
			}
		});

		const bindProps = useProps(props, tableProps, ["data", "spanMethod", "headerCellClassName", "cellClassName"], {
			tableLayout: "fixed",
			showOverflowTooltip: true,
		});

		useRender(() => (
			<div
				ref={elementRef}
				class={["g-table", `g-table__${props.tableKey ?? "notFound"}`, states.loading ? "g-click-disabled" : ""]}
				style={{
					"--g-table-width": `${states.tableWidth ? `${states.tableWidth}px` : ""}`,
					"--g-table-height": `${states.tableHeight ? `${states.tableHeight}px` : ""}`,
				}}
			>
				<GTableSearchForm
					vSlots={slots}
					show={props.searchForm && states.showSearch}
					size={props.searchFormSize}
					search={tableSearch}
					reset={tableReset}
				/>
				{slots.topHeader ? <div class="el-card g-table__header">{slots.topHeader(states)}</div> : null}
				<div class="el-card g-table__main">
					{props.headerCard ? (
						<div class="g-table__main-header">
							<div class="g-table__main-header-left">{slots.header && slots.header(states)}</div>
							<div class="g-table__main-header-right">
								{props.toolBtn ? (
									<>
										{props.requestApi && props.hideSearchTime === false ? (
											<ElDatePicker
												class="g-table__main-header-right__data-search"
												popperClass="g-table__main-header-right__data-search__popper"
												disabled={states.loading}
												type="daterange"
												vModel={states.searchParam.searchTimeList}
												defaultTime={tableUtil.getDefaultTime()}
												shortcuts={tableUtil.getShortcuts()}
												valueFormat="YYYY-MM-DD HH:mm:ss"
												disabledDate={tableUtil.getDisabledDate}
												clearable={false}
												teleported={false}
												unlinkPanels
												onChange={() => refreshTable()}
											/>
										) : null}
										<ElInput
											class="g-table__main-header-right__input-search"
											disabled={states.loading}
											prefixIcon={Search}
											placeholder="关键字搜索"
											vModel_trim={states.searchParam.searchValue}
											clearable
											onChange={() => refreshTable()}
										/>
										{slots.toolButton && slots.toolButton(states)}
										{props.refreshBtn ? (
											<ElButton
												loading={states.loading}
												loadingIcon={Eleme}
												title="刷新"
												circle
												icon={Refresh}
												onClick={() => refreshTable()}
											/>
										) : null}
										{props.exportExcelBtn && states.response ? (
											<ElButton
												loading={states.loading}
												loadingIcon={Eleme}
												title="导出Excel"
												circle
												icon={Download}
												onClick={() => {
													exportExcelDialogRef.value.open();
												}}
											/>
										) : null}
										{props.printType ? (
											<GPrintButton
												loading={states.loading}
												loadingIcon={Eleme}
												printType={props.printType}
												orderID={states.selectedListIds}
												disabled={!states.selected}
											/>
										) : null}
										{props.searchBtn && states.searchColumns.length > 0 ? (
											<ElButton
												loading={states.loading}
												loadingIcon={Eleme}
												title={states.showSearch ? "隐藏搜索栏" : "显示搜索栏"}
												circle
												icon={Search}
												onClick={() => (states.showSearch = !states.showSearch)}
											/>
										) : null}
										{states.columnBtn ? (
											<ElDropdown title="列配置" trigger="click">
												{{
													default: () => (
														<ElButton loading={states.loading} loadingIcon={Eleme} circle icon={Operation}></ElButton>
													),
													dropdown: () => (
														<ElDropdownMenu>
															<ElDropdownItem
																title="同步列配置"
																disabled={!states.existCacheColumns}
																onClick={syncColumnsCache}
															>
																同步列配置
															</ElDropdownItem>
															<ElDropdownItem
																title="重置列配置"
																disabled={!states.existCacheColumns}
																onClick={clearColumnsCache}
															>
																重置列配置
															</ElDropdownItem>
															<ElDropdownItem
																title="表格列配置"
																divided
																onClick={() => columnSettingDialogRef.value.open()}
															>
																表格列配置
															</ElDropdownItem>
														</ElDropdownMenu>
													),
												}}
											</ElDropdown>
										) : null}
										{slots.toolButtonAdv ? (
											<ElDropdown title="高级操作" trigger="click">
												{{
													default: () => (
														<ElButton loading={states.loading} loadingIcon={Eleme} circle icon={More}></ElButton>
													),
													dropdown: () => <ElDropdownMenu>{slots.toolButtonAdv(states)}</ElDropdownMenu>,
												}}
											</ElDropdown>
										) : null}
									</>
								) : null}
							</div>
						</div>
					) : null}
					<ElTable
						{...attrs}
						{...bindProps.value}
						ref={tableRef}
						vLoading={states.loading}
						element-loading-text={states.loadingText}
						data={states.tableData}
						spanMethod={handleSpanMethod}
						headerCellClassName={handleHeaderClass}
						cellClassName={handleCellClassName}
						onSelectionChange={handleSelectionChange}
						onSortChange={handleSortChange}
						onSelect={handleSelectClick}
						onSelectAll={handleSelectClick}
						onHeaderDragend={handleHeaderDragend}
					>
						{{
							append: () => slots.append && slots.append(states),
							empty: () => (
								<div class="g-table__empty">
									{slots.empty ? (
										slots.empty(states)
									) : (
										<>
											<GIcon name="g-icon-NotData" />
											<div>暂无数据</div>
										</>
									)}
								</div>
							),
							default: () => (
								<>
									{/* 默认序号列 */}
									<ElTableColumn
										className="g-table__index-column"
										type="index"
										fixed="left"
										width={
											states.tablePagination.pageIndex * states.tablePagination.pageSize >= 100
												? states.tablePagination.pageIndex * states.tablePagination.pageSize >= 1000
													? 50
													: 40
												: 30
										}
										align="center"
										index={indexMethod}
										showOverflowTooltip={false}
										resizable={false}
										columnKey="__table-index"
									/>
									{/* 复选框 */}
									<ElTableColumn
										className="g-table__selection-column"
										type="selection"
										fixed="left"
										width={35}
										align="center"
										reserveSelection
										showOverflowTooltip={false}
										resizable={false}
										columnKey="__table-selection"
									/>
									{/* 操作列 */}
									{slots.operation && (
										// 操作列
										<ElTableColumn
											fixed="right"
											width={states.operationColumnWidth}
											headerAlign="center"
											align="center"
											showOverflowTooltip={false}
											className="g-table__operation-column"
											resizable={false}
											columnKey="__table-operation"
										>
											{{
												header: () => (
													<div class="g-table__auto-width-column__cell-header __g-table__auto-width-column__cell-header____table-operation">
														<span>操作</span>
													</div>
												),
												default: ({ row, column, $index }: { row: any; column: GTableColumnCtx; $index: number }) => (
													<div class="g-table__auto-width-column__cell __g-table__auto-width-column__cell____table-operation">
														{slots.operation({ ...states, row, column, $index })}
													</div>
												),
											}}
										</ElTableColumn>
									)}
									{states.tableColumns?.length === 0
										? slots.default && slots.default(states)
										: states.tableColumns.map((col) =>
												col.show ? (
													col.type === "expand" ? (
														<ElTableColumn {...col} fixed={col.fixed ?? "left"} resizable={states.columnBtn}>
															{{
																default: ({
																	row,
																	column,
																	$index,
																}: {
																	row: any;
																	column: GTableColumnCtx;
																	$index: number;
																}) => (
																	<>
																		<component is={col.render} row={row} column={column} $index={$index} />
																		{col.slot &&
																			slots[col.slot] &&
																			slots[col.slot]({ ...states, row, column, $index })}
																	</>
																),
															}}
														</ElTableColumn>
													) : col.prop ? (
														<GTableColumn
															vSlots={slots}
															column={col}
															hideImage={gejiaAppStore.states.tableConfig.hideImage}
															resizable={states.columnBtn}
															tableSize={states.tableSize}
															onImagePreview={handleImagePreview}
															onCustomCellClick={handleCustomCellClick}
														/>
													) : null
												) : null
											)}
								</>
							),
						}}
					</ElTable>
					<div class="g-table__main-footer">
						<div class="g-table__main-footer__left">{slots.footer && slots.footer(states)}</div>
						{slots.pagination ? (
							slots.pagination(states)
						) : (
							<>
								{props.pagination ? (
									<GTablePagination sizeChange={handleSizeChange} currentChange={handleCurrentChange} />
								) : (
									<ElPagination class="g-table-pagination" size="small" layout="total" total={states.tableData.length} />
								)}
							</>
						)}
					</div>
				</div>
				{states.imagePreview ? (
					<ElImageViewer
						closeOnPressEscape
						hideOnClickModal
						teleported
						onClose={() => (states.imagePreview = false)}
						urlList={states.previewList}
					/>
				) : null}
				{states.columnBtn ? <GTableColumnsSettingDialog ref={columnSettingDialogRef} onColumnSave={saveColumnsCache} /> : null}
				{states.exportExcelBtn ? (
					<GTableExportExcelDialog ref={exportExcelDialogRef} exportExcelFn={props.exportExcelFn} initParam={props.initParam} />
				) : null}
			</div>
		));

		expose({
			states,
			loadTableColumns,
			tableReset,
			getRequestParam,
			refresh: refreshTable,
			toggleRowIndeterminateSelection,
			...useTable(tableRef),
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			loadTableColumns,
			tableReset,
			getRequestParam,
			refresh: refreshTable,
			toggleRowIndeterminateSelection,
			...useTable(tableRef),
		};
	},
});
