import type { CSSProperties } from "vue";
import { computed, defineComponent, h, reactive, resolveComponent } from "vue";
import { ElIcon } from "element-plus";
import { gIconProps } from "./define";
import type { GIconStates } from "./type";
import { typeUtil, useRender } from "@gejia-element-plus/utils";

/**
 * GIcon 组件
 */
export default defineComponent({
	name: "GIcon",
	components: { ElIcon },
	props: gIconProps,
	setup(props, { attrs, expose }) {
		const states: GIconStates = reactive({
			// 使用正则表达式匹配http、https、mailto、tel和ftp开头的链接
			isUrl: computed(() => /^(https?:|mailto:|tel:|ftp:)/.test(props.name)),
			iconStyle: computed((): CSSProperties => {
				const result: any = {};
				if (props.size) {
					if (typeUtil.isNumber(props.size)) {
						result.size = `${props.size}px`;
					} else {
						result.size = props.size;
					}
				}
				if (props.color) {
					result.color = props.color;
				}
				if (states.isUrl) {
					result.mask = `url(${props.name}) no-repeat 50% 50%`;
					result["-webkit-mask"] = `url(${props.name}) no-repeat 50% 50%`;
				}
				return result;
			}),
		});

		useRender(() =>
			props.name.indexOf("el-icon-") === 0 ? (
				<ElIcon {...attrs} class="g-icon icon" color={props.color} size={props.size}>
					{h(resolveComponent(props.name))}
				</ElIcon>
			) : props.name.indexOf("g-icon") === 0 ? (
				h(resolveComponent(props.name), { style: states.iconStyle }, attrs)
			) : states.isUrl ? (
				<div {...attrs} class="el-icon g-icon icon url-icon" style={states.iconStyle} />
			) : (
				<i {...attrs} class={[props.name, "el-icon g-icon icon"]} style={states.iconStyle} />
			)
		);

		expose({
			states,
		});

		return {
			states,
		};
	},
});
