import type { SlotsType, VNode, VNodeArrayChildren } from "vue";
import { computed, defineComponent, nextTick, onActivated, onDeactivated, onMounted, onUnmounted, provide, ref, watch } from "vue";
import { gLayoutGridEmits, gLayoutGridProps, type gLayoutGridSlots } from "./define";
import type { GLayoutGridBreakPoint } from "./type";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GLayoutGrid 组件
 */
export default defineComponent({
	name: "GLayoutGrid",
	props: gLayoutGridProps,
	emits: gLayoutGridEmits,
	slots: Object as SlotsType<typeof gLayoutGridSlots>,
	setup(props, { slots, emit, expose }) {
		const divElRef = ref<HTMLElement>();

		// 注入 gap 间距
		provide("gap", Array.isArray(props.gap) ? props.gap[0] : props.gap);

		// 注入响应式断点
		const breakPoint = ref<GLayoutGridBreakPoint>("xl");
		provide("breakPoint", breakPoint);

		// 注入要开始折叠的 index
		const hiddenIndex = ref(-1);
		provide("shouldHiddenIndex", hiddenIndex);

		// 注入 cols
		const cols = computed(() => {
			if (typeof props.cols === "object") return props.cols[breakPoint.value] ?? props.cols;
			return props.cols;
		});
		provide("cols", cols);

		// 监听屏幕变化
		const resize = (e: ResizeObserverEntry[]): void => {
			// 这里肯定只有一个
			if (e.length !== 1) throw new Error("未知的多个El");
			const curEl = e[0];
			const width = curEl.target.scrollWidth;
			switch (true) {
				case width <= 500:
					breakPoint.value = "xs";
					break;
				case width < 800:
					breakPoint.value = "sm";
					break;
				case width >= 800 && width < 1024:
					breakPoint.value = "md";
					break;
				case width >= 1024 && width < 1360:
					breakPoint.value = "lg";
					break;
				case width >= 1360:
					breakPoint.value = "xl";
					break;
			}
		};

		const findIndex = (): void => {
			const fields: VNodeArrayChildren = [];
			let suffix: VNode = null;
			const slotContent = divElRef.value?.children;
			if (slotContent) {
				for (let i = 0; i < slotContent.length; i++) {
					const slotNode = slotContent[i]["__vueParentComponent"]?.vnode;
					if (typeof slotNode?.type === "object" && slotNode?.type.name === "GLayoutGridItem" && slotNode?.props?.suffix !== undefined)
						suffix = slotNode;
					if (typeof slotNode?.type === "symbol" && Array.isArray(slotNode?.children))
						slotNode?.children.forEach((child: VNode) => fields.push(child));
				}
			}

			// 计算 suffix 所占用的列
			let suffixCols = 0;
			if (suffix) {
				suffixCols =
					(suffix.props[breakPoint.value]?.span ?? suffix.props?.span ?? 1) +
					(suffix.props[breakPoint.value]?.offset ?? suffix.props?.offset ?? 0);
			}
			try {
				let find = false;
				fields.reduce((prev = 0, current, index) => {
					prev +=
						((current as VNode).props[breakPoint.value]?.span ?? (current as VNode).props?.span ?? 1) +
						((current as VNode).props[breakPoint.value]?.offset ?? (current as VNode).props?.offset ?? 0);
					if ((prev as number) > props.collapsedRows * (cols.value as number) - suffixCols) {
						hiddenIndex.value = index;
						find = true;
						// throw "find it";
					}
					return prev;
				}, 0);
				if (!find) hiddenIndex.value = -1;
			} catch (error) {
				console.warn("[gejia-GLayoutGrid]", error);
			}
		};

		let resizeObserver: ResizeObserver = null;

		onMounted(() => {
			nextTick(() => {
				resizeObserver = new ResizeObserver(resize);
				resizeObserver.observe(divElRef.value);
			});
		});

		onActivated(() => {
			nextTick(() => {
				resizeObserver = new ResizeObserver(resize);
				resizeObserver.observe(divElRef.value);
			});
		});

		onUnmounted(() => {
			resizeObserver?.disconnect();
		});

		onDeactivated(() => {
			resizeObserver?.disconnect();
		});

		onMounted(() => {
			// 断点变化时 执行 findIndex
			watch(
				() => breakPoint.value,
				() => {
					if (props.collapsed) {
						emit("breakPointChange", { breakPoint: breakPoint.value });
						findIndex();
					}
				},
				{
					immediate: true,
				}
			);
		});

		// 监听 collapsed
		watch(
			() => props.collapsed,
			(value) => {
				if (value) return findIndex();
				hiddenIndex.value = -1;
			}
		);

		// 设置间距
		const gap = computed(() => {
			if (typeof props.gap === "number") return `${props.gap}px`;
			if (Array.isArray(props.gap)) return `${props.gap[1]}px ${props.gap[0]}px`;
			return "unset";
		});

		// 设置 style
		const style = computed(() => {
			return {
				display: "grid",
				gridGap: gap.value,
				gridTemplateColumns: `repeat(${cols.value}, minmax(0, 1fr))`,
			};
		});

		useRender(() => (
			<div ref={divElRef} style={style.value}>
				{slots.default && slots.default()}
			</div>
		));

		expose({
			props,
			emit,
			slots,
		});

		return {
			props,
			emit,
			slots,
		};
	},
});
