import type { SlotsType } from "vue";
import { computed, defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { Expand, Fold } from "@element-plus/icons-vue";
import { ElIcon, ElInput, ElScrollbar, ElTree } from "element-plus";
import type { gTreeSlots } from "./define";
import { gTreeEmits, gTreeProps, treeProps } from "./define";
import type { GTreeStates } from "./type";
import { pinia, useGejiaApp } from "@gejia-element-plus/stores";
import { errorHandler, typeUtil, useProps, useRender, withTryNumber } from "@gejia-element-plus/utils";

/**
 * GTree 组件
 */
export default defineComponent({
	name: "GTree",
	components: {
		ElTree,
		ElScrollbar,
		ElInput,
		ElIcon,
		Expand,
		Fold,
	},
	props: gTreeProps,
	emits: gTreeEmits,
	slots: Object as SlotsType<typeof gTreeSlots>,
	setup(props, { attrs, slots, emit, expose }) {
		const gejiaAppStore = useGejiaApp(pinia);

		const states: GTreeStates = reactive({
			value: undefined,
			label: undefined,
			loading: false,
			orgTreeData: [],
			treeData: [],
			searchValue: undefined,
			hamburger: false,
			width: computed(() => {
				if (props.hamburger || states.hamburger) {
					return "130px";
				} else {
					if (gejiaAppStore.states.appConfig.size === "small") {
						return typeUtil.isNumber(props.width) ? `calc(${props.width}px * 0.9)` : `calc(${props.width} * 0.9)`;
					} else {
						return typeUtil.isNumber(props.width) ? `${props.width}px` : props.width;
					}
				}
			}),
			fold: computed(() => {
				return states.orgTreeData.filter((f) => f[props.childrenKey]?.length > 0).length === 0;
			}),
		});

		const treeRef = ref<InstanceType<typeof ElTree>>();

		const handleTreeData = (treeData: ElTreeOutput[] | any[]): any[] => {
			return treeData.map((item) => {
				const localValue = withTryNumber(item[props.nodeKey]);
				const result = {
					...item,
					[props.nodeKey]: localValue,
					value: localValue,
					label: item[props.labelKey],
					children: [],
					all: false,
				};
				if (item[props.childrenKey]?.length > 0) {
					result.children = handleTreeData(item[props.childrenKey]);
				}
				return result;
			});
		};

		const setTreeData = (treeData: ElTreeOutput[] | any[]): void => {
			const localTreeData = handleTreeData(treeData);
			if (!props.hideAll) {
				localTreeData.unshift({
					...{ value: props.allValue, label: "全部", children: [], all: false },
					[props.nodeKey]: props.allValue,
					[props.labelKey]: "全部",
				});
			}
			states.treeData = localTreeData;
		};

		const loadData = async (searchParam?: anyObj): Promise<void> => {
			// 记录原本选中的值
			const curSelectedData = treeRef.value.getCurrentKey();
			// 判断是否需要自动请求
			if (props.requestApi) {
				states.loading = true;
				const param = searchParam ?? { ...(props.initParam ?? {}), searchValue: states.searchValue };
				try {
					const apiResult = await props.requestApi(param);
					states.orgTreeData = apiResult.data;
					setTreeData(apiResult.data);
				} catch (error) {
					console.error("[gejia-GTree]", error);
					states.orgTreeData = [];
					errorHandler(error);
				} finally {
					states.loading = false;
				}
			} else {
				states.orgTreeData = props.data;
				setTreeData(props.data);
			}
			if (curSelectedData) {
				nextTick(() => {
					// 设置原本选中的值
					treeRef.value.setCurrentKey(curSelectedData);
				});
			} else {
				if (props.defaultSelected) {
					nextTick(() => {
						treeRef.value.setCurrentKey(props.defaultSelected);
					});
				}
			}
			emit("dataChangeCallBack", states.treeData, states.orgTreeData);
		};

		const handleHamburgerClick = (): void => {
			if (props.hamburger || states.hamburger) {
				setTreeData(states.orgTreeData);
			} else {
				// 折叠只显示一级数据
				const treeData = [];
				states.orgTreeData.forEach((item) => {
					treeData.push({ ...item, children: [] });
				});
				states.treeData = treeData;
			}
			states.hamburger = !states.hamburger;
		};

		const handleFilterNode = (value, data, node): boolean => {
			if (!value) return true;
			let parentNode = node.parent,
				labels = [node.label],
				level = 1;
			while (level < node.level) {
				labels = [...labels, parentNode.label];
				parentNode = parentNode.parent;
				level++;
			}
			return labels.some((label) => label.indexOf(value) !== -1);
		};

		const handleNodeClick = (data: ElTreeOutput | any, node, treeNode, event: MouseEvent): void => {
			states.value = data.value;
			states.label = data.label;
			emit("update:modelValue", states.value);
			emit("update:label", states.label);
			emit("change", data, node, treeNode, event);
			emit("nodeClick", data, node, treeNode, event);
		};

		onMounted(async () => {
			if (props.requestAuto) {
				loadData();
			} else {
				states.orgTreeData = props.data;
				setTreeData(props.data);
			}
		});

		const bindProps = useProps(props, treeProps, ["data", "filterNodeMethod"], {
			defaultExpandAll: true,
			highlightCurrent: true,
			expandOnClickNode: false,
			checkOnClickNode: true,
		});

		useRender(() => (
			<div
				class={["el-card g-tree", (props.hamburger && states.hamburger) || states.fold ? "g-tree__fold" : ""]}
				style={{ width: states.width }}
				vLoading={states.loading}
			>
				{props.title || props.hamburger ? (
					<div class="g-tree__title">
						<h4>{props.title}</h4>
						{props.hamburger ? (
							states.hamburger ? (
								<ElIcon onClick={handleHamburgerClick} title="展开">
									<Expand />
								</ElIcon>
							) : (
								<ElIcon onClick={handleHamburgerClick} title="折叠">
									<Fold />
								</ElIcon>
							)
						) : null}
					</div>
				) : null}
				{props.hideFilter ? null : (
					<ElInput
						class="g-tree__search-input"
						vModel_trim={states.searchValue}
						placeholder={props.hamburger || states.hamburger ? "关键字过滤" : "输入关键字进行过滤"}
						clearable
						onInput={(value) => treeRef.value.filter(value)}
					/>
				)}
				<ElScrollbar class="g-tree__scrollbar">
					<ElTree
						{...attrs}
						{...bindProps.value}
						ref={treeRef}
						data={states.treeData}
						filterNodeMethod={handleFilterNode}
						onNodeClick={handleNodeClick}
					>
						{{
							default: ({ node, data }) => (
								<span class="el-tree-node__label" title={node.label}>
									<span>{slots.label ? slots.label({ ...states, node, data }) : data.label}</span>
									{data.value && data.showNum ? <span>[{data.quantity}]</span> : null}
									{slots.default && <span>{slots.default({ ...states, node, data })}</span>}
								</span>
							),
						}}
					</ElTree>
				</ElScrollbar>
			</div>
		));

		expose({
			states,
			refresh: loadData,
			filter: treeRef?.value?.filter,
			updateKeyChildren: treeRef?.value?.updateKeyChildren,
			getCheckedNodes: treeRef?.value?.getCheckedNodes,
			setCheckedNodes: treeRef?.value?.setCheckedNodes,
			getCheckedKeys: treeRef?.value?.getCheckedKeys,
			setCheckedKeys: treeRef?.value?.setCheckedKeys,
			setChecked: treeRef?.value?.setChecked,
			getHalfCheckedNodes: treeRef?.value?.getHalfCheckedNodes,
			getHalfCheckedKeys: treeRef?.value?.getHalfCheckedKeys,
			getCurrentKey: treeRef?.value?.getCurrentKey,
			getCurrentNode: treeRef?.value?.getCurrentNode,
			setCurrentKey: treeRef?.value?.setCurrentKey,
			setCurrentNode: treeRef?.value?.setCurrentNode,
			getNode: treeRef?.value?.getNode,
			remove: treeRef?.value?.remove,
			append: treeRef?.value?.append,
			insertBefore: treeRef?.value?.insertBefore,
			insertAfter: treeRef?.value?.insertAfter,
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			refresh: loadData,
			filter: treeRef?.value?.filter,
			updateKeyChildren: treeRef?.value?.updateKeyChildren,
			getCheckedNodes: treeRef?.value?.getCheckedNodes,
			setCheckedNodes: treeRef?.value?.setCheckedNodes,
			getCheckedKeys: treeRef?.value?.getCheckedKeys,
			setCheckedKeys: treeRef?.value?.setCheckedKeys,
			setChecked: treeRef?.value?.setChecked,
			getHalfCheckedNodes: treeRef?.value?.getHalfCheckedNodes,
			getHalfCheckedKeys: treeRef?.value?.getHalfCheckedKeys,
			getCurrentKey: treeRef?.value?.getCurrentKey,
			getCurrentNode: treeRef?.value?.getCurrentNode,
			setCurrentKey: treeRef?.value?.setCurrentKey,
			setCurrentNode: treeRef?.value?.setCurrentNode,
			getNode: treeRef?.value?.getNode,
			remove: treeRef?.value?.remove,
			append: treeRef?.value?.append,
			insertBefore: treeRef?.value?.insertBefore,
			insertAfter: treeRef?.value?.insertAfter,
		};
	},
});
