import type { SlotsType, VNode } from "vue";
import { computed, defineComponent, nextTick, reactive, ref, watch } from "vue";
import { Close, Eleme, Refresh } from "@element-plus/icons-vue";
import { ElButton, ElDrawer, ElIcon, ElMessage, ElMessageBox, ElScrollbar, drawerProps } from "element-plus";
import type { gDrawerSlots } from "./define";
import { gDrawerEmits, gDrawerProps } from "./define";
import type { GDrawerStates } from "./type";
import { useDrawer } from "./useDrawer";
import { GIcon } from "@gejia-element-plus/components/icon";
import { GPrintButton } from "@gejia-element-plus/components/printButton";
import { errorHandler, execFunction, typeUtil, useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GDrawer 组件
 */
export default defineComponent({
	name: "GDrawer",
	components: {
		ElDrawer,
		ElIcon,
		ElButton,
		ElScrollbar,
		Close,
		Eleme,
		GIcon,
		GPrintButton,
		Refresh,
	},
	props: gDrawerProps,
	emits: gDrawerEmits,
	slots: Object as SlotsType<typeof gDrawerSlots>,
	setup(props, { attrs, slots, emit, expose }) {
		const states: GDrawerStates = reactive({
			loading: false,
			visible: false,
			fullscreen: props.fullscreen,
			size: typeUtil.isNumber(props.size) ? `${props.size}%` : props.size,
			maxHeight: computed(() => {
				let maxHeight = "calc(var(--g-window-height)";
				if (props.withHeader) {
					maxHeight += " - var(--g-drawer-header-height)";
				}
				if (props.showFooterOperator) {
					maxHeight += " - var(--g-drawer-footer-height)";
				}
				// 16px(内容padding高度) - 2px(线条高度)
				maxHeight += " - 18px)";
				return maxHeight;
			}),
			refreshing: false,
		});

		const drawerRef = ref<InstanceType<typeof ElDrawer>>();

		const cacheData = {
			openFunction: undefined,
		};

		/**
		 * 打开
		 */
		const open = (openFunction?: () => Promise<void>): void => {
			states.visible = true;
			cacheData.openFunction = openFunction;
			nextTick(() => {
				states.loading = true;
				execFunction(props.afterOpen ?? openFunction)
					.then(() => {
						emit("open");
					})
					.catch((error) => {
						console.error("[gejia-GDrawer]", error);
						// 自动关闭
						states.visible = false;
						errorHandler(error);
					})
					.finally(() => {
						states.loading = false;
					});
			});
		};

		/**
		 * 关闭
		 */
		const close = (closeFunction?: () => Promise<void>): void => {
			states.loading = true;
			execFunction(closeFunction)
				.then(() => {
					emit("close");
					states.visible = false;
				})
				.catch((error) => {
					console.error("[gejia-GDrawer]", error);
					errorHandler(error);
				})
				.finally(() => {
					states.loading = false;
				});
		};

		/**
		 * 刷新
		 */
		const refresh = (): void => {
			states.refreshing = true;
			states.loading = true;
			setTimeout(() => {
				states.refreshing = false;
				open(cacheData.openFunction);
				ElMessage.success("刷新成功");
			}, 500);
		};

		const handleBeforeClose = (done: () => void): void => {
			if (states.loading) return;
			// 解决 image 预览摁下 ese 会关闭弹窗的问题
			if (document.querySelector(".el-image-viewer__wrapper")) return;

			const newDone = (): void => {
				emit("close");
				if (props.beforeClose) {
					props.beforeClose() && done();
				} else {
					done();
				}
			};

			if (props.showBeforeClose) {
				ElMessageBox.confirm("确定关闭？", { type: "warning" }).then(() => {
					newDone();
				});
			} else {
				newDone();
			}
		};

		const handleFullscreen = (): void => {
			if (states.loading) return;
			states.fullscreen = !states.fullscreen;
		};

		const handleConfirmClick = (): void => {
			if (states.loading) return;
			emit("confirmClick", states);
		};

		const handleClose = (): void => {
			if (states.loading) return;
			close();
		};

		/**
		 * 处理拖拽
		 */
		const handleDraggableMousedown = (): void => {
			document.onmousemove = (moveEvent: MouseEvent): void => {
				// 获取鼠标距离浏览器右边缘的距离
				let realWidth = document.body.clientWidth - moveEvent.pageX;
				const width20 = document.body.clientWidth * 0.2;
				const width95 = document.body.clientWidth * 0.95;
				// 宽度不能大于浏览器宽度 95%，不能小于宽度的 20%
				realWidth = realWidth > width95 ? width95 : realWidth < width20 ? width20 : realWidth;
				states.size = `${realWidth}px`;
			};
			document.onmouseup = (): void => {
				document.onmousemove = document.onmouseup = null;
			};
		};

		watch(
			() => states.visible,
			(newValue) => {
				emit("update:modelValue", newValue);
			}
		);

		const bindProps = useProps(props, drawerProps, ["modelValue", "size", "beforeClose"], {
			showClose: false,
		});

		useRender(() => (
			<ElDrawer
				{...attrs}
				{...bindProps.value}
				ref={drawerRef}
				class={["g-drawer", states.fullscreen ? "g-drawer__fullscreen" : ""]}
				vModel={states.visible}
				size={states.size}
				beforeClose={handleBeforeClose}
			>
				{{
					header: () => (
						<>
							<div class="g-drawer__header-title">
								{props.title}
								{slots.header && slots.header(states)}
							</div>
							{props.printType && props.orderID ? (
								<GPrintButton
									class={["g-drawer__header-icon", states.loading ? "g-click-disabled g-click-disabled__cursor " : "g-twinkle"]}
									link
									printType={props.printType}
									orderID={props.orderID}
								/>
							) : null}
							{props.showRefresh ? (
								<div
									title="刷新"
									class={["g-drawer__header-icon", states.loading ? "g-click-disabled g-click-disabled__cursor " : "g-twinkle"]}
									onClick={refresh}
								>
									<ElIcon class="icon">
										<Refresh />
									</ElIcon>
								</div>
							) : null}
							{props.showFullscreen ? (
								<div
									title={states.fullscreen ? "关闭全屏显示" : "全屏显示"}
									class={["g-drawer__header-icon", states.loading ? "g-click-disabled g-click-disabled__cursor " : "g-twinkle"]}
									onClick={handleFullscreen}
								>
									<GIcon name={states.fullscreen ? "g-icon-FullScreenExit" : "g-icon-FullScreen"} />
								</div>
							) : null}
							{props.showClose ? (
								<div
									title="关闭"
									class={["g-drawer__header-icon", states.loading ? "g-click-disabled g-click-disabled__cursor " : "g-twinkle"]}
									onClick={handleClose}
								>
									<ElIcon class="icon">
										<Close />
									</ElIcon>
								</div>
							) : null}
						</>
					),
					default: () => (
						<>
							{props.draggable ? <div class="g-drawer__draggable" onmousedown={handleDraggableMousedown} /> : null}
							<ElScrollbar
								class="g-drawer__scrollbar"
								wrapStyle={{
									"--g-drawer-scrollbar__max-height": states.maxHeight,
									maxHeight: "var(--g-drawer-scrollbar__max-height)",
								}}
								vLoading={states.loading}
								element-loading-text="加载中..."
							>
								{states.refreshing ? null : slots.default && slots.default(states)}
							</ElScrollbar>
						</>
					),
					...(props.showFooterOperator && {
						footer: (): VNode[] => (
							<>
								{slots.footer && slots.footer(states)}
								{props.showCloseButton ? (
									<ElButton disabled={states.loading} onClick={handleClose}>
										{props.closeButtonText}
									</ElButton>
								) : null}
								{props.showConfirmButton ? (
									<ElButton
										loading={states.loading}
										loadingIcon={Eleme}
										disabled={props.disabledConfirmButton}
										type="primary"
										onClick={handleConfirmClick}
									>
										{states.loading ? "加载中..." : props.confirmButtonText}
									</ElButton>
								) : null}
							</>
						),
					}),
				}}
			</ElDrawer>
		));

		expose({
			states,
			open,
			close,
			refresh,
			...useDrawer(drawerRef),
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
			open,
			close,
			refresh,
			...useDrawer(drawerRef),
		};
	},
});
