import type { SlotsType } from "vue";
import { computed, defineComponent, h, reactive, resolveComponent } from "vue";
import { ElOption, ElOptionGroup } from "element-plus";
import type { gSelectOptionSlots } from "./define";
import { gSelectOptionProps } from "./define";
import type { GSelectOptionStates } from "./type";
import { useRender } from "@gejia-element-plus/utils";

/**
 * GSelectOption 组件
 */
export default defineComponent({
	name: "GSelectOption",
	components: {},
	props: gSelectOptionProps,
	slots: Object as SlotsType<typeof gSelectOptionSlots>,
	setup(props, { slots, expose }) {
		const states: GSelectOptionStates = reactive({
			optionGroup: computed(() => props.data[props.childrenKey]?.length > 0),
			label: computed(() => props.data[props.labelKey]),
			value: computed(() => props.data[props.valueKey]),
			disabled: computed(() => props.data[props.disabledKey]),
			children: computed(() => props.data[props.childrenKey]),
		});

		useRender(() => (
			<>
				{props.data[props.childrenKey]?.length > 0 ? (
					<ElOptionGroup label={states.label} disabled={states.disabled}>
						{states.children.map((item) =>
							h(resolveComponent("GSelectOption"), {
								...props,
								data: item,
								vSlots: slots,
							})
						)}
					</ElOptionGroup>
				) : (
					<ElOption
						class={{ "g-select-page-dropdown__selector__more-detail": props.moreDetail && slots.default }}
						label={states.label}
						value={states.value}
						disabled={states.disabled}
					>
						{slots.default && slots.default({ ...states, data: props.data })}
					</ElOption>
				)}
			</>
		));

		expose({
			states,
		});

		return {
			slots,
			states,
		};
	},
});
