import type { ExtractPropTypes } from "vue";
import type { gPrintButtonProps } from "./src/define";
import PrintButton from "./src/printButton";
import { withInstall } from "@gejia-element-plus/utils";

export const GPrintButton = withInstall(PrintButton);
export default GPrintButton;

export * from "./src/define";
export * from "./src/type";

export type GPrintButtonInstance = InstanceType<typeof PrintButton>;

export type GPrintButtonProps = ExtractPropTypes<typeof gPrintButtonProps>;
