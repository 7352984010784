import type { Ref } from "vue";
import type { DialogInstance } from "element-plus";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const userDialog = (dialogRef: Ref<DialogInstance>) => {
	const resetPosition = (): void => dialogRef?.value?.resetPosition();

	return {
		resetPosition,
	};
};
