import type { PropType, VNode } from "vue";
import type { UploadUserFile } from "element-plus";
import { uploadProps } from "element-plus";
import type { GUploadImagesStates } from "./type";
import type { StoreFileType } from "@gejia-element-plus/components/upload";

export const gUploadImagesProps = {
	...uploadProps,
	/**
	 * v-model绑定值
	 */
	modelValue: Array as PropType<string[]>,
	/**
	 * 最大上传
	 */
	limit: {
		type: Number,
		default: 9,
	},
	/**
	 * 文件类型
	 */
	fileType: {
		type: Number as PropType<StoreFileType>,
	},
	/**
	 * 大小限制，单位kb
	 */
	maxSize: {
		type: Number,
		default: 2048,
	},
};

export const gUploadImagesEmits = {
	/**
	 * v-model 回调
	 */
	"update:modelValue": (value: string[]): boolean => true,
	/**
	 * v-model:fileList 回调
	 */
	"update:fileList": (value: UploadUserFile[]): boolean => true,
	/**
	 * 改变
	 */
	change: (value: string[]): boolean => true,
};

export const gUploadImagesSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GUploadImagesStates): VNode[] => [],
	/**
	 * 空状态
	 */
	empty: (states: GUploadImagesStates): VNode[] => [],
};
