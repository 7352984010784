import type { ExtractPropTypes } from "vue";
import type { gLayoutScreenFullProps } from "./src/define";
import LayoutScreenFull from "./src/screenFull";
import { withInstall } from "@gejia-element-plus/utils";

export const GLayoutScreenFull = withInstall(LayoutScreenFull);
export default GLayoutScreenFull;

export * from "./src/define";

export type GLayoutScreenFullInstance = InstanceType<typeof LayoutScreenFull>;

export type GLayoutScreenFullProps = ExtractPropTypes<typeof gLayoutScreenFullProps>;
