import type { VNode } from "vue";
import { imageProps } from "element-plus";
import type { GImageStates } from "./type";

export const gImageProps = {
	...imageProps,
	/**
	 * 图片路径
	 */
	src: String,
	/**
	 * 懒加载
	 */
	lazy: {
		type: Boolean,
		default: true,
	},
	/**
	 * 原图
	 */
	original: Boolean,
	/**
	 * 标准
	 */
	normal: Boolean,
	/**
	 * 小图
	 */
	small: Boolean,
	/**
	 * 缩略图
	 */
	thumb: Boolean,
	/**
	 * 是否可以预览图片
	 */
	preview: {
		type: Boolean,
		default: true,
	},
};

export const gImageSlots = {
	/**
	 * 自定义图像加载失败的内容
	 */
	error: (states: GImageStates): VNode[] => [],
};
