import type { ExtractPropTypes } from "vue";
import Button from "./src/button";
import type { gButtonEmits, gButtonProps } from "./src/define";
import { withInstall } from "@gejia-element-plus/utils";

export const GButton = withInstall(Button);
export default Button;

export * from "./src/define";
export * from "./src/type";

export type GButtonInstance = InstanceType<typeof Button>;

export type GButtonProps = ExtractPropTypes<typeof gButtonProps>;

export type GButtonEmits = typeof gButtonEmits;
