import type { ExtractPropTypes } from "vue";
import type { gUploadImagesEmits, gUploadImagesProps } from "./src/define";
import UploadImages from "./src/uploadImages";
import { withInstall } from "@gejia-element-plus/utils";

export const GUploadImages = withInstall(UploadImages);
export default GUploadImages;

export * from "./src/define";
export * from "./src/type";

export type GUploadImagesInstance = InstanceType<typeof UploadImages>;

export type GUploadImagesProps = ExtractPropTypes<typeof gUploadImagesProps>;

export type GUploadImagesEmits = typeof gUploadImagesEmits;
