import { reactive } from "vue";
import { defineStore } from "pinia";
import type { AppConfig, AxiosConfig, AxiosOptions, GejiaApp, PrintConfig, TableConfig, UploadConfig } from "./interface";
import { makeIdentity } from "@gejia-element-plus/build";
import type { GTableColumnCtx, GTableEnumColumnCtx } from "@gejia-element-plus/components/table";
import { Local, Session, axiosUtil } from "@gejia-element-plus/utils";

export const useGejiaApp = defineStore(
	"gejiaApp",
	() => {
		const states: GejiaApp = reactive({
			// 环境
			viteEnv: "development",
			// 字典
			dictionary: new Map<string, GTableEnumColumnCtx[]>(),
			// 表格列
			tableColumns: new Map<string, GTableColumnCtx[]>(),
			// App 配置
			appConfig: {
				warnPageNum: 15,
				minWidth: 1024,
				storageCrypto: false,
				size: "default",
			},
			// Axios 配置
			axiosConfig: {
				baseUrl: undefined,
				timeout: 6000,
				requestCipher: false,
				loading: {
					fullscreen: true,
					lock: true,
					text: "加载中...",
					background: "rgba(0, 0, 0, 0.7)",
				},
				reloadLoginCodes: [401],
				reloadLoginMessage: "登录已失效，请重新登录！",
				reloadLoginButtonText: "重新登录",
				errorCodeMessages: {
					cancelDuplicate: "重复请求，自动取消！",
					offLine: "您断网了！",
					fileDownloadError: "文件下载失败或此文件不存在！",
					302: "接口重定向了！",
					400: "参数不正确！",
					401: "您没有权限操作（令牌、用户名、密码错误）！",
					403: "您的访问是被禁止的！",
					404: "请求的资源不存在！",
					405: "请求的格式不正确！",
					408: "请求超时！",
					409: "系统已存在相同数据！",
					410: "请求的资源被永久删除，且不会再得到的！",
					422: "当创建一个对象时，发生一个验证错误！",
					429: "请求过于频繁，请稍后再试！",
					500: "服务器内部错误！",
					501: "服务未实现！",
					502: "网关错误！",
					503: "服务不可用，服务器暂时过载或维护！",
					504: "服务暂时无法访问，请稍后再试！",
					505: "HTTP版本不受支持！",
					default: "请求错误！",
					ERR_NETWORK: "网关错误，服务不可用，服务器暂时过载或维护！",
				},
				getToken: undefined,
				setToken: undefined,
				logout: undefined,
			},
			// Axios 选项
			axiosOptions: {
				// 是否开启取消重复请求, 默认为 true
				cancelDuplicateRequest: true,
				// 是否开启loading层效果, 默认为 true
				loading: false,
				// 否开启缓存，只有 query 才行，默认false
				cache: false,
				// 忽略错误，默认为 false
				ignoreError: false,
				// Get请求缓存问题处理，默认为 true
				getMethodCacheHandle: true,
				// 是否开启简洁的数据结构响应, 默认为 true
				simpleDataFormat: true,
				// 是否开启接口错误信息展示, 默认为 true
				showErrorMessage: true,
				// 是否开启code信息提示, 默认为 true
				showCodeMessage: true,
				// 是否开启请求成功的信息提示, 默认为 false
				showSuccessMessage: false,
				// 是否开启自动下载文件, 默认为 true
				autoDownloadFile: true,
			},
			// Table 配置
			tableConfig: {
				showSearch: true,
				hideImage: true,
				dataSearchRange: "Past3D",
				getColumnsUrl: undefined,
				saveColumnsCacheUrl: undefined,
				syncColumnsCacheUrl: undefined,
				clearColumnsCacheUrl: undefined,
				exportExcelUrl: undefined,
				exportExcelFn: undefined,
			},
			/**
			 * 打印配置
			 */
			printConfig: {
				printEnvironment: undefined,
				queryPrintTemplate: undefined,
			},
			/**
			 * 上传配置
			 */
			uploadConfig: {
				uploadFileUrl: undefined,
			},
		});

		/**
		 * 设置字典
		 */
		const setDictionary = (dictionary: Map<string, GTableEnumColumnCtx[]>): void => {
			states.dictionary = dictionary;
		};

		/**
		 * 获取字典
		 */
		const getDictionary = (key: string): GTableEnumColumnCtx[] => {
			if (!states.dictionary.has(key)) {
				console.error("[gejia-app]", `字典 [${key}] 不存在`);
			}
			return states.dictionary.get(key);
		};

		/**
		 * 获取表格列
		 */
		const getTableColumns = (tableKey: string): GTableColumnCtx[] | false => {
			if (!states.tableColumns.has(tableKey)) {
				return false;
			}
			return states.tableColumns.get(tableKey);
		};

		/**
		 * 设置或更新表格列
		 */
		const setTableColumns = (tableKey: string, tableColumns: GTableColumnCtx[]): void => {
			if (states.tableColumns.has(tableKey)) {
				states.tableColumns.delete(tableKey);
			}
			states.tableColumns.set(tableKey, tableColumns);
		};

		/**
		 * 删除表格列
		 */
		const deleteTableColumns = (tableKey: string): void => {
			states.tableColumns.delete(tableKey);
		};

		/**
		 * 设置App 配置
		 */
		const setAppConfig = (appConfig: AppConfig): void => {
			states.appConfig = Object.assign(states.appConfig, appConfig);
		};

		/**
		 * 设置Axios 配置
		 */
		const setAxiosConfig = (axiosConfig: AxiosConfig): void => {
			states.axiosConfig = Object.assign(states.axiosConfig, axiosConfig);
		};

		/**
		 * 设置Axios 选项
		 */
		const setAxiosOptions = (axiosOptions: AxiosOptions): void => {
			states.axiosOptions = Object.assign(states.axiosOptions, axiosOptions);
		};

		/**
		 * 设置Table 配置
		 */
		const setTableConfig = (tableConfig: TableConfig): void => {
			states.tableConfig = Object.assign(states.tableConfig, tableConfig);
		};

		/**
		 * 设置打印配置
		 */
		const setPrintConfig = (printConfig: PrintConfig): void => {
			states.printConfig = Object.assign(states.printConfig, printConfig);
		};

		/**
		 * 设置打印配置
		 */
		const setUploadConfig = (uploadConfig: UploadConfig): void => {
			states.uploadConfig = Object.assign(states.uploadConfig, uploadConfig);
		};

		/**
		 * 设置环境
		 */
		const setViteEnv = (viteEnv: IViteEnv): void => {
			states.viteEnv = viteEnv;
		};

		/**
		 * 设置打印环境
		 */
		const setPrintEnvironment = (success: boolean): void => {
			states.printConfig.printEnvironment = success;
		};

		/**
		 * 清除App缓存
		 * @description 慎用，会清除所有缓存，相当于第一次打开系统
		 */
		const clearAppCache = (): void => {
			// 获取设备ID，这里按理来说不应该不存在的
			const deviceID = makeIdentity();
			// 删除HTTP缓存
			axiosUtil.deleteHttpCache();
			// 删除Local缓存
			Local.clear();
			// 删除Session缓存
			Session.clear();
			// 重新设置设备ID
			makeIdentity(deviceID);
		};

		return {
			states,
			setDictionary,
			getDictionary,
			getTableColumns,
			setTableColumns,
			deleteTableColumns,
			setAppConfig,
			setAxiosConfig,
			setAxiosOptions,
			setTableConfig,
			setPrintConfig,
			setUploadConfig,
			setViteEnv,
			setPrintEnvironment,
			clearAppCache,
		};
	}
	// {
	// 	persist: {
	// 		key: "store-gejia-app",
	// 		// 这里不需要配置持久化
	// 		pick: [],
	// 	},
	// }
);

export * from "./interface";
