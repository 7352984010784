import type { SlotsType } from "vue";
import { computed, defineComponent, reactive } from "vue";
import { Picture as ElIconPicture } from "@element-plus/icons-vue";
import { ElAvatar, avatarProps } from "element-plus";
import type { gAvatarSlots } from "./define";
import { gAvatarProps } from "./define";
import type { GAvatarStates } from "./type";
import { useProps, useRender } from "@gejia-element-plus/utils";

/**
 * GAvatar 组件
 */
export default defineComponent({
	name: "GAvatar",
	components: {
		ElAvatar,
		ElIconPicture,
	},
	props: gAvatarProps,
	slots: Object as SlotsType<typeof gAvatarSlots>,
	setup(props, { attrs, slots, expose }) {
		const states: GAvatarStates = reactive({
			src: computed(() => {
				if (props.src) {
					if (props.original) {
						return props.src;
					} else if (props.normal) {
						return `${props.src}@!normal`;
					} else if (props.small) {
						return `${props.src}@!small`;
					} else if (props.thumb) {
						return `${props.src}@!thumb`;
					} else {
						// 默认使用缩略图
						return `${props.src}@!thumb`;
					}
				}
				return null;
			}),
			icon: computed(() => {
				if (!props.src) {
					return props.icon ?? ElIconPicture;
				} else {
					return props.icon;
				}
			}),
		});

		const bindProps = useProps(props, avatarProps, ["icon", "src"]);

		useRender(() => (
			<ElAvatar {...attrs} {...bindProps.value} class="g-avatar" icon={states.icon} src={states.src}>
				{slots.default && slots.default(states)}
			</ElAvatar>
		));

		expose({
			states,
		});

		return {
			attrs,
			bindProps,
			slots,
			states,
		};
	},
});
