import type { ExtractPropTypes } from "vue";
import CarNumber from "./src/carNumber";
import type { gCarNumberEmits, gCarNumberProps } from "./src/define";
import { withInstall } from "@gejia-element-plus/utils";

export const GCarNumber = withInstall(CarNumber);
export default CarNumber;

export * from "./src/define";
export * from "./src/type";

export type GCarNumberInstance = InstanceType<typeof CarNumber>;

export type GCarNumberProps = ExtractPropTypes<typeof gCarNumberProps>;

export type GCarNumberEmits = typeof gCarNumberEmits;
