import type { PropType, VNode } from "vue";
import type { GLayoutClassicStates } from "./type";

export const gLayoutClassicProps = {
	/**
	 * 站点名称
	 */
	siteName: String,
	/**
	 * Logo
	 */
	logo: String,
	/**
	 * 显示页脚
	 */
	footer: Boolean,
	/**
	 * 是否为深色模式
	 */
	isDark: Boolean,
	/**
	 * 显示水印
	 */
	watermark: {
		type: Boolean,
		default: true,
	},
	/**
	 * 水印内容
	 */
	watermarkContent: {
		type: [String, Array] as PropType<string | string[]>,
	},
	/**
	 * 切换动画
	 */
	mainAnimation: {
		type: String as PropType<IAnimationName>,
		default: "slide-right",
	},
};

export const gLayoutClassicSlots = {
	/**
	 * 默认内容插槽
	 */
	default: (states: GLayoutClassicStates): VNode[] => [],
	/**
	 * 头部插槽
	 */
	header: (states: GLayoutClassicStates): VNode[] => [],
	/**
	 * 导航菜单插槽
	 */
	navMenu: (states: GLayoutClassicStates): VNode[] => [],
	/**
	 * 导航栏选项卡插槽
	 */
	navBarTab: (states: GLayoutClassicStates): VNode[] => [],
	/**
	 * 页脚插槽
	 */
	footer: (states: GLayoutClassicStates): VNode[] => [],
};
